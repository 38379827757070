import React, { useEffect,useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import profile from '../assets/images/profile.png';
import { Col, Row, Form, Input, FormFeedback, Label, CardText } from 'reactstrap';
import { getAllUpdate} from '../features/allNews/allNewsSlice';

//galery
  import { FileUploader } from "react-drag-drop-files"
  import { deleteObject, ref } from "firebase/storage"
  import { db, storage } from "../utils/firebase"
  import {  uploadBytes, getDownloadURL } from "firebase/storage"

import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

export default function ModalEdit({ setShow,show,data,title,name,action }) {
  const handleClose = () => {
    setShow(false);
  };

  const fileTypes = ["JPG", "PNG", "GIF"]
  const [featuredImage, setFeaturedImage] = useState(null)
  const [featuredImagePath, setFeaturedImagePath] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([])
  const deleteThumbnail = () => {
    if (featuredImage.name) {
      setFeaturedImage(null)
      setFeaturedImagePath("")
    } else {
      deleteObject(ref(storage, featuredImagePath)).then(() => {
        setFeaturedImage(null)
        setFeaturedImagePath("")
      })
    }
  }
  function randomName() {
    var text = ""
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }
  const uploadThumbnailHandler = file => {
    setFeaturedImage(file)
    const random = randomName()
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`)
  }
  const uploadThumbnailProcess = () => {
    return new Promise(resolve => {
      if (featuredImage.name) {
        const reference = ref(storage, featuredImagePath)
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then(url => {
            resolve({ url: url, reference: featuredImagePath })
          })
        })
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath })
      }
    })
  }
  const deleteGalleryFile = index => {
    if (galleryImages[index].name) {
      const fileArray = [...galleryImages]
      const pathArray = [...galleryImagesPaths]
      fileArray.splice(index, 1)
      pathArray.splice(index, 1)
      setGalleryImages(fileArray)
      setGalleryImagesPaths(pathArray)
    } else {
      deleteObject(ref(storage, galleryImagesPaths[index])).then(() => {
        const fileArray = [...galleryImages]
        const pathArray = [...galleryImagesPaths]
        fileArray.splice(index, 1)
        pathArray.splice(index, 1)
        setGalleryImages(fileArray)
        setGalleryImagesPaths(pathArray)
      })
    }
  }
  const uploadGalleryHandler = files => {
    let processed = 0
    if (files.length + galleryImages.length > 5) {
      alert("You can only insert 5 images")
      return
    }
    const oldFiles = [...galleryImages]
    const oldPaths = [...galleryImagesPaths]
    const newFiles = [...files]

    newFiles.forEach(file => {
      oldFiles.push(file)
      const random = randomName()
      oldPaths.push(`images/property/gallery/${random}${file.name}`)
      processed++
      if (processed === files.length) {
        setGalleryImages(oldFiles)
        setGalleryImagesPaths(oldPaths)
      }
    })
  }
  const uploadGalleryProcess = async () => {
    return new Promise(resolve => {
      const gallery = [...galleryImages]
      let processed = 0
      const arrayToBeUploaded = []
      if (gallery.length === 0) {
        resolve([])
      }
      gallery.forEach((image, index) => {
        if (image.name) {
          const reference = ref(storage, galleryImagesPaths[index])
          uploadBytes(reference, image).then(() => {
            getDownloadURL(reference).then(url => {
              arrayToBeUploaded.push({
                url: url,
                reference: galleryImagesPaths[index],
              })
              processed++
              if (processed === gallery.length) {
                resolve(arrayToBeUploaded)
              }
            })
          })
        } else {
          arrayToBeUploaded.push({
            url: galleryImages[index],
            reference: galleryImagesPaths[index],
          })
          processed++
          if (processed === gallery.length) {
            resolve(arrayToBeUploaded)
          }
        }
      })
    })
  }

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize: true,

    initialValues: {
      topic: (data && data.topic) || '',
      topic_title:(data && data.topic_title) || '',
      title: (data && data.title) || '',
     overview:(data && data.overview) || '',
     description:(data && data.description) || '',
    },

    validationSchema: Yup.object({
        title: Yup.string().required('The Tile Name is required'),
        topic_title: Yup.string().required('The Topic Title is required'),
        topic: Yup.string().required('The Topic  is required'),
        overview: Yup.string().required('The overview is required'),
        description:Yup.string().required('The overview is required'),
    }),


    onSubmit: (values) => {
      let thumbnailUpload, galleryUpload
      uploadThumbnailProcess().then(value => {
        thumbnailUpload = value
        uploadGalleryProcess().then(value => {
          galleryUpload = value
      const News = {
         id: data ? data.id : 0,
         title: values.title,
         topic_title: values.topic_title,
         topic: values.topic,
         overview:values.overview,
         created_at:new Date(Date.now()),
         created_by:'',
         description:values.description,
         featured_image:thumbnailUpload.url,
         gallery:galleryUpload,
      };

      if(name==='add'){
        dispatch(action(News));
      }
      else{
        dispatch(action(News));
      }
    })
  })
  setShow(false)
    },
  });

  useEffect(() => {
  },[show]);

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return true
            }}
            
          >
            <Row   form="true">
              <Col className='col-12'>
                <div className='profile mb-4'>
                <img src={profile} alt='user-profile' className='profile' />
                </div>
                <Row   form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Title</Label>
                      <Input
                        name='title'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title}
                        invalid={
                          validation.touched.title &&
                          validation.errors.title
                            ? true
                            : false
                        }
                      />
                  
                      {
                      validation.touched.title &&
                      validation.errors.title ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null
                      
                      }
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'> Topic</Label>
                      <Input
                        name='topic'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.topic}
                        invalid={
                          validation.touched.topic &&
                          validation.errors.topic
                            ? true
                            : false
                        }
                      />
                      {validation.touched.topic &&
                      validation.errors.topic ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.topic}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Topic Title</Label>
                      <Input
                        name='topic_title'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.topic_title}
                        invalid={
                          validation.touched.topic_title &&
                          validation.errors.topic_title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.topic_title &&
                      validation.errors.topic_title ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.topic_title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>description</Label>
                      <Input
                        name='description'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description&&
                      validation.errors.description ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
         
                </Row>
               
          
                
              </Col>
              <Row>
                <Col col="12">
                <div className='mb-3'>
                      <Label className='form-label'>overview</Label>
                      <textarea
                       
                        name='overview'
                        type='text'
                      
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.overview}
                    
                      />
                      {validation.touched.overview && validation.errors.overview ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.overview}
                        </FormFeedback>
                      ) : null}
                    </div>
                </Col>
              </Row>
              <Row>
                  <Col>
                    <div className='bg-blue mb-4'>
                      <label className='form-label'>Featured Images</label>
                      <div>

                    <FileUploader
                      classes="dropzone"
                      handleChange={uploadThumbnailHandler}
                      name="file"
                      types={fileTypes}
                    />
                        
                    {featuredImage && (
                      <div
                        className="thumbnail-container"
                        onClick={deleteThumbnail}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={
                            featuredImage.name
                              ? URL.createObjectURL(featuredImage)
                              : featuredImage
                          }
                        />
                      </div>
                    )}
                  
                      </div>

                    </div>
                  </Col>
                </Row>
                <Row>
              <Col>
              
            <FileUploader
                multiple={true}
                classes="dropzone"
                handleChange={uploadGalleryHandler}
                name="file"
                types={fileTypes}
              />
              {
                <div galleryImages={galleryImages}>
                  {galleryImages.map((image, index) => {
                    return (
                      <div
                        className="thumbnail-container"
                        key={index}
                        onClick={() => {
                          deleteGalleryFile(index)
                        }}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={image.name ? URL.createObjectURL(image) : image}
                        />
                      </div>
                    )
                  })}
                </div>
              }
                </Col>
              </Row>
            </Row>
            <Row>
                                        <Col>
                                          <div className="text-end pb-4">
                                            <button
                                              type="submit"
                                              className="btn btn-success  btn-save"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
          </Form>
        </Modal.Body>
     
      </Wrapper>
    </Modal>
  );
}
