import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import allUsersSlice from './features/allUsers/allUsersSlice';
import allPropertiesSlice from './features/allProperties/allPropertiesSlice';
import PropertySettingsSlice from './features/PropertySettings/PropertySettingsSlice';
import allNewsSlice from './features/allNews/allNewsSlice';
import allEventsSlice from './features/allEvents/allEventsSlice';
import allWidgetsSlice from './features/allWidgets/allWidgetsSlice';
import allNavSlice from './features/allNav/allNavSlice';
import allPagesSlice from './features/allPages/allPagesSlice';
import allPropertyTypeSlice from './features/allPropertyType/allPropertyTypeSlice';


export const store = configureStore({
  reducer: {
    user: userSlice,
    allUsers: allUsersSlice,
    allNews: allNewsSlice,
    allEvents: allEventsSlice,
    allProperties: allPropertiesSlice,
    propertySettings: PropertySettingsSlice,
    allWidgets: allWidgetsSlice,
    allNav: allNavSlice,
    allPages: allPagesSlice,
    allPropertyType: allPropertyTypeSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
