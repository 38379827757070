import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllPagesThunk, getAllUpdateThunk, addPagesThunk, getCatPropertyThunk, getTypePropertyThunk, deletePagesThunk, uploadFilesThunk, RemoveFileThunk, removeFromArrayThunk } from './allPagesThunk';
// import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  isViewPropertyModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  properties: [],
  propertyTypes: [],
  subcategories: [],
  type: [],
  cat: [],
  uploads: [],
  deletedImages: [],
  uploadEditImages: [],
  currentProperty: null,
  reload: "",
};
export const getAllPages = createAsyncThunk(
  'allProperties/getProperties',
  getAllPagesThunk
);
// export const getTypeProperty = createAsyncThunk(
//   'allProperties/getTypeProperty',
//   getTypePropertyThunk
// );
// export const getCatProperty = createAsyncThunk(
//   'allProperties/getCatProperty',
//   getCatPropertyThunk
// );
export const getAllUpdate = createAsyncThunk(
  'allProperties/getAllUpdate',
  getAllUpdateThunk
);
export const createPages = createAsyncThunk(
  'allProperties/createProperty',
  addPagesThunk
);
export const uploadFiles = createAsyncThunk(
  'allProperties/uploadFiles',
  uploadFilesThunk
);
export const deletePages = createAsyncThunk(
  'allProperties/deleteProperty',
  deletePagesThunk
);
// export const deleteStorageFile = createAsyncThunk(
//   'allProperties/deleteStorageFile',
//   RemoveFileThunk
// );

// export const removeFromArray = createAsyncThunk(
//   'allProperties/removeFromArray',
//   removeFromArrayThunk
// );

const allPagesSlice = createSlice({
  name: 'allProperties',
  initialState,
  reducers: {
    handleAddPages: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentProperty = '';
    },
    handleViewPages: (state, { payload }) => {
      state.isViewPropertyModalOpen = !state.isViewPropertyModalOpen;

      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleEditPages: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      console.log('test teste test',state.isEditModalOpen);
      if (payload) {
        state.currentProperty = payload;
        // state.uploads = [payload.featured_image, ...payload.gallery]
      }
    },
    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleEdit: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      if (payload) {
        state.currentProperty = payload;
        state.uploads = [payload.featured_image, ...payload.gallery]
      }
    },
    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleUpload: (state, { payload }) => {
      if (payload) {
        if (state.isAddModalOpen) {
          state.uploads = payload;

        }

        if (state.isEditModalOpen) {

          state.uploadEditImages = payload;

        }
      }


    },
    handleRemoveImg: (state, { payload }) => {
      if (payload) {

        const index = state.uploads.findIndex(object => {
          return object.url === payload.url;
        });

        state.uploads.splice(index, 1);


        state.deletedImages = [...state.deletedImages, payload]

      }

    },
  },

  extraReducers: {
    [getAllPages.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPages.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.properties = payload;
    },
    [getAllPages.rejected]: (state, { payload }) => {
      state.isLoading = false;

    },
    // [getTypeProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getTypeProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.propertyTypes = payload;
    // },
    // [getTypeProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    // [getCatProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getCatProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.subcategories = payload;
    // },
    // [getCatProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    [getAllUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
      state.uploads = []
      state.deletedImages = [];
      state.uploadEditImages = [];
    },
    [getAllUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createPages.pending]: (state) => {
      state.isLoading = true;
    },
    [createPages.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
    },
    [createPages.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [uploadFiles.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadFiles.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
    },
    [uploadFiles.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deletePages.pending]: (state) => {
      state.isLoading = true;
    },
    [deletePages.fulfilled]: (state, { payload }) => {

      state.isLoading = false;
    },
    [deletePages.rejected]: (state, { payload }) => {

      state.isLoading = false;
    },
    // [deleteStorageFile.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [deleteStorageFile.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [deleteStorageFile.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [removeFromArray.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
  },
});

export const { clearFilters, handleChange, handleEditPages, handleViewPages, handleViewThumbnail, handleDelete, handleUpload, handleAddPages, handleRemoveImg } = allPagesSlice.actions;
export default allPagesSlice.reducer;