import Spinner from 'react-bootstrap/Spinner';
const Loading = ({ center }) => {
  return (
    <div className='text-center'>
      <Spinner
        animation='grow'
        style={{ color: 'rgb(125,204,45)', margin: '2px' }}
        role='status'
        size='sm'
      >
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
      <Spinner
        animation='grow'
        style={{ color: 'rgb(125,204,45)', margin: '2px' }}
        role='status'
        size='sm'
      >
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
      <Spinner
        animation='grow'
        style={{ color: 'rgb(125,204,45)', margin: '2px' }}
        role='status'
        size='sm'
      >
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  );
};
export default Loading;
