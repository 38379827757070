import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import profile from '../assets/images/profile.png';
import { Col, Row, Form, Input, FormFeedback, Label } from 'reactstrap';

import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db } from '../utils/firebase'
import { v4 as uuidv4 } from 'uuid'

export default function EditPropertyType({ show, setShow, data, title }) {
  const handleClose = () => {
    setShow(false);
  };

  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(null);

  // store image in firebase
  const storeImage = async (image) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = `${image.name}-${uuidv4()}`

      const storageRef = ref(Storage, 'images/property' + fileName)
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on('state_changed',
        (snapshot) => {

          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          reject(error)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    })
  }


  const validation = useFormik({

    initialValues: {
      title: (data && data.title) || '',
      description: (data && data.description) || '',
      value: (data && data.value) || '',
      link: (data && data.link) || '',
      subcategories: (data && data.subcategories.value),
    },

    validationSchema: Yup.object({
      title: Yup.string().required('The Title is required'),
      description: Yup.string().required('The description is required'),
      value: Yup.string().required('The value is required'),
      link: Yup.string().required('The link is required'),
      subcategories: Yup.string().required('The subcategory is required'),
    }),

    onSubmit: (values) => {
      const Users = {
        id: data ? data.id : 0, //why 0?

      };
      //  Need to add Dispatch for Edit
    },
  });
  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return true
            }}

          >
            <Row form="true">
              <Col className='col-12'>
                <div className='profile mb-4'>


                  <input type="file" className="input" />
                  <button type="submit">Upload</button>
                  <img src={profile} alt='user-profile' className='profile' />
                </div>
                <Row form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Title</Label>
                      <Input
                        name='title'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title}
                        invalid={
                          validation.touched.title &&
                            validation.errors.title
                            ? true
                            : false
                        }
                      />

                      {
                        validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null

                      }
                    </div>
                  </Col>
                  <Col className='col-6'>

                  </Col>
                </Row>
                <Row form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>value</Label>
                      <Input
                        name='value'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.value}
                        invalid={
                          validation.touched.value &&
                            validation.errors.value
                            ? true
                            : false
                        }
                      />
                      {validation.touched.value &&
                        validation.errors.value ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.value}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>link</Label>
                      <Input
                        name='link'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link}
                        invalid={
                          validation.touched.link && validation.errors.link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link && validation.errors.link ? (
                        <FormFeedback type='invalid'>
                          {' '}
                          {validation.errors.link}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='col-lg-12'>
                    {
                      data && <div className='mb-3'>
                        <Label className='form-label'>Subcategories</Label>
                        <Select
                          defaultValue={data.subcategories}
                          isMulti
                          name="subcategories"
                          options={data.subcategories}
                          className="basic-multi-select"
                        />

                      </div>
                    }

                  </Col>
                </Row>


                <Row form="true">
                  <Col className='col-12'>
                    <div className='mb-3'>
                      <Label className='form-label'>Description</Label>
                      <textarea
                        name="description"
                        className='form-control text-area-user'
                        id='exampleFormControlTextarea1'
                        onChange={validation.handleChange}
                        rows='3'

                        // onBlur={validation.handleBlur}
                        value={validation.values.description}

                      />
                      {validation.errors.description ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>




              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end pb-4">
                  <button
                    type="submit"
                    className="btn btn-success  btn-save">
                    Save
                    </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Wrapper>
    </Modal>
  );
}
