import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getPropertyTypesThunk,
  getAmenitiesThunk,
  getPaymentMethodsThunk,
} from './PropertySettingsThunk';
import countries from '../../utils/countries.json';

const initialState = {
  isLoading: true,
  isViewPropertyModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  currentPropertyType: null,
  isViewAmentyModalOpen: false,
  isEditAmentyModalOpen: false,
  isDeleteAmentyModalOpen: false,
  isViewPaymentModalOpen: false,
  isEditPaymentModalOpen: false,
  isDeletePaymentModalOpen: false,
  isViewRentModalOpen: false,
  isEditRentModalOpen: false,
  isDeleteRentModalOpen: false,
  countries: countries,
  property_types: [],
  amenities: [],
  payment_methods: [],
  rent_or_sale: [],

};
export const getPropertyTypes = createAsyncThunk(
  'PropertySettings/getPropertyTypes',
  getPropertyTypesThunk
);
export const getAmenities = createAsyncThunk(
  'PropertySettings/getAmenities',
  getAmenitiesThunk
);
export const getPaymentMethods = createAsyncThunk(
  'PropertySettings/getPaymentMethods',
  getPaymentMethodsThunk
);
const PropertySettingsSlice = createSlice({
  name: 'PropertySettings',
  initialState,
  reducers: {
    handleViewProperty: (state, { payload }) => {
      state.isViewPropertyModalOpen = !state.isViewPropertyModalOpen;
      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleViewPropertyThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleAddProperty: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentPropertyType = '';
    },
    handleEditProperty: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleDeleteProperty: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
    },
    handleViewAmenty: (state, { payload }) => {
      state.isViewAmentyModalOpen = !state.isViewAmentyModalOpen;

      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleEditAmenty: (state, { payload }) => {
      state.isEditAmentyModalOpen = !state.isEditAmentyModalOpen;
      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleDeleteAmenty: (state, { payload }) => {
      state.isDeleteAmentyModalOpen = !state.isDeleteAmentyModalOpen;
    },
    handleViewRent: (state, { payload }) => {
      state.isViewRentModalOpen = !state.isViewRentModalOpen;

      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleEditRent: (state, { payload }) => {
      state.isEditRentModalOpen = !state.isEditRentModalOpen;
      if (payload) {
        state.currentPropertyType = payload;
      }
    },
    handleDeleteRent: (state, { payload }) => {
      state.isDeleteRentModalOpen = !state.isDeleteRentModalOpen;
    },
  },
  extraReducers: {
    [getPropertyTypes.pending]: (state) => {
      state.isLoading = true;
    },
    [getPropertyTypes.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.property_types = payload;
    },
    [getPropertyTypes.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getAmenities.pending]: (state) => {
      state.isLoading = true;
    },
    [getAmenities.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.amenities = payload;
    },
    [getAmenities.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getPaymentMethods.pending]: (state) => {
      state.isLoading = true;
    },
    [getPaymentMethods.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.payment_methods = payload[0].fields;
      state.rent_or_sale = payload[1].fields;
    },
    [getPaymentMethods.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { handleAddProperty, handleDeleteProperty, handleEditProperty, handleViewProperty, handleViewPropertyThumbnail, handleDeleteAmenty, handleEditAmenty, handleViewAmenty, handleDeleteRent, handleEditRent, handleViewRent } = PropertySettingsSlice.actions;
export default PropertySettingsSlice.reducer;
