import styled from 'styled-components';

const Wrapper = styled.main`
  .modalHeader {
    border: none !important;
  }
  .modalFooter {
    border: none !important;
    padding-block-start: 0 !important;
  }
  .btn-save {
    color: var(--white) !important;
    background: var(--primary-500) !important;
    border: transparent !important;
    text-transform: capitalize !important;
    width: 6rem !important;
  }
  .btn-save:hover {
    background: var(--primary-700)!important;
    box-shadow: var(--shadow-3)!important;
  }
  .profile {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: auto;
  }

`;

export default Wrapper;
