import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Wrapper from '../assets/wrappers/ViewUserModal';
import profile from '../assets/images/profile.jpg';
import { MdOutlineEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';
const ViewMoreModal = ({ show, setShow, data }) => {
  const { first_name, last_name, email, phone_number, bio, profile_image } =
    data;
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='lg'
      dialogClassName='View More Modal'
      // aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Wrapper>
        <Modal.Header className='modalHeader' closeButton>
          {profile_image.url ? (
            <img className='profile' src={profile_image.url} alt='profile' />
          ) : (
            <img className='profile' src={profile} alt='profile' />
          )}
        </Modal.Header>
        <Modal.Body>
          <div className='info'>
            <h5 className='name'>
              {first_name} &nbsp; {last_name}
            </h5>
            <div>
              <AiFillPhone className='me-2' />
              <span> {phone_number}</span>
            </div>
            <div>
              <MdOutlineEmail className='me-2' />
              <span>
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </div>

            <p className='bio'>{bio}</p>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default ViewMoreModal;
