import Accordion from 'react-bootstrap/Accordion';
import {
  PropertyType,
  Amenities,
  PaymentMethods,
  RentSale,
} from '../../components';
const PropertySettings = () => {
  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <h4>Property Types</h4>
        </Accordion.Header>
        <Accordion.Body>
          <PropertyType />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header>
          <h4> Amenities</h4>
        </Accordion.Header>
        <Accordion.Body>
          <Amenities />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='2'>
        <Accordion.Header>
          <h4>Payment Methods</h4>
        </Accordion.Header>
        <Accordion.Body>
          <PaymentMethods />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='3'>
        <Accordion.Header>
          <h4> Rent or Sale</h4>
        </Accordion.Header>
        <Accordion.Body>
          <RentSale />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>



  );
};

export default PropertySettings;
