import { clearAllUsersState } from '../allUsers/allUsersSlice';
// import { clearValues } from '../job/jobSlice';
import { logoutUser } from './userSlice';
import {
  createUserWithEmailAndPassword,
  auth,
  updateProfile,
  signInWithEmailAndPassword,
} from '../../utils/firebase';

export const registerUserThunk = (url, user, thunkAPI) => {
  const { email, password, name } = user;
  try {
    const resp = createUserWithEmailAndPassword(auth, email, password).then(
      (cred) => {
        updateProfile(cred.user, {
          displayName: name,
        });
        // const data = cred.user.providerData[0];
        // return data;
        return cred.user;
      }
    );

    return resp;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};

export const loginUserThunk = async (url, user, thunkAPI) => {
  const { email, password, name } = user;
  try {
    const resp = signInWithEmailAndPassword(auth, email, password).then(
      (cred) => {
        return cred.user;
      }
    );
    return resp;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};

export const clearStoreThunk = async (message, thunkAPI) => {
  try {
    thunkAPI.dispatch(logoutUser(message));
    thunkAPI.dispatch(clearAllUsersState());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
};
