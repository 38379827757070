import React from "react";
export const InputRowWidget = ({
  index,
  item,
  handleChange,
  handleRemove,
  handleAdd,
  values,
  inputFields,
}) => {
  return (
    <>
      <div
        spacing={2}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "35px",
        }}
      >
        <label>description</label>
        <input
          name="description"
          fullWidth
          label="description"
          onChange={(event) => handleChange(event, index)}
          value={item.description}
        />
        <label>description_ar</label>
        <input
          name="description_ar"
          fullWidth
          label="description_ar"
          onChange={(event) => handleChange(event, index)}
          value={item.description_ar}
        />
        <label>link</label>
        <input
          name="link"
          fullWidth
          label="link"
          onChange={(event) => handleChange(event, index)}
          value={item.link}
        />
      </div>
      <div
        spacing={2}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "35px",
        }}
      >
        <label>title</label>
        <input
          name="title"
          fullWidth
          label="title"
          onChange={(event) => handleChange(event, index)}
          value={item.title}
        />
        <label>title ar</label>
        <input
          name="title_ar"
          fullWidth
          label="title_ar"
          onChange={(event) => handleChange(event, index)}
          value={item.title_ar}
        />
      </div>
      <div
        spacing={2}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "35px",
        }}
      >
        <div>
          <button
            onClick={handleRemove}
            style={{
              color: "white",
              backgroundColor: "red",
              border: "none",
              borderRadius: "7px",
              marginRight: "4px",
            }}
          >
            remove
          </button>
          <button
            onClick={handleAdd}
            style={{
              color: "white",
              backgroundColor: "green",
              border: "none",
              borderRadius: "7px",
              marginRight: "4px",
            }}
          >
            add
          </button>
        </div>
      </div>
    </>
  );
};
