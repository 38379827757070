import {
    db, collection, getDocs, onSnapshot, updateDoc, arrayUnion, arrayRemove, doc, addDoc, deleteDoc, deleteObject, ref, storage, uploadBytesResumable
    , getDownloadURL
  } from '../../utils/firebase';
  import { v4 as uuidv4 } from 'uuid'
  
  export const getAllPagesThunk = async (_, thunkAPI) => {
    try {
      const colRef = collection(db, 'pages');
      const data = [];
  
      const querySnapshot = await getDocs(colRef);
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
  
      // reshape createdAt  because they are non-serializable
      data.forEach((user) => {
        user.createdAt = new Date(
          user.createdAt?.seconds * 1000
        ).toLocaleDateString('en-US');
      });
  
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  };
  
//   export const getAllPropertyTypesThunk = async (_, thunkAPI) => {
//     try {
//       const colRef = collection(db, 'property_type');
//       const data = [];
  
//       const querySnapshot = await getDocs(colRef);
//       querySnapshot.forEach((doc) => {
//         data.push({ title: doc.data().title, value: doc.data().value });
//       });
//       return data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   };
  
//   export const getAllSubcategoriesThunk = async (_, thunkAPI) => {
//     try {
//       const colRef = collection(db, 'property_type');
//       const data = [];
  
//       const querySnapshot = await getDocs(colRef);
//       querySnapshot.forEach((doc) => {
//         data.push({ title: doc.data().title, value: doc.data().subcategories });
//       });
//       return data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   };
  
//   export const getTypePropertyThunk = async (thunkAPI) => {
//     try {
//       const colRef = collection(db, 'property_type');
//       let type = [];
  
//       const querySnapshot = await getDocs(colRef);
//       querySnapshot.forEach((doc) => {
//         let key = doc.data().title;
//         type.push({ label: key, value: key });
//       });
//       return type;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   };
  
//   export const getCatPropertyThunk = async (thunkAPI) => {
//     try {
//       const colRef = collection(db, 'property_type');
//       let cat = [];
  
//       const querySnapshot = await getDocs(colRef);
//       querySnapshot.forEach((doc) => {
//         let key = doc.data().title;
//         let obj = {};
//         obj[key] = doc.data().subcategories;
//         cat.push(obj);
//       });
//       return cat;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   };
  
  
export const getAllUpdateThunk = async (users, thunkAPI) => {
  try {
    const docRef = doc(db, 'pages', users.id);
    const data = [];

    const querySnapshot = await updateDoc(docRef, users);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });


    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
  
  export const addPagesThunk = async (property, thunkAPI) => {
    try {
      const colRef = collection(db, 'pages');
  
      await addDoc(colRef, property)
        .then(() => {
          console.log("success")
        })
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  };
  
  export const RemoveFileThunk = async (thumbnail, thunkAPI) => {
    try {
      if (thumbnail) {
        const desertRef = ref(storage, thumbnail.reference);
        // Delete the file
        deleteObject(desertRef).then(() => {
          // File deleted successfully
  
        }).catch((err) => {
          return thunkAPI.rejectWithValue(err);
        });
      }
      return
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  };
  
//   export const removeFromArrayThunk = async ({ arrayField, removeable, collection, docId }, thunkAPI) => {
//     try {
//       if (arrayField) {
//         const propRef = doc(db, collection, docId);
//         await updateDoc(propRef, {
//           arrayField: arrayRemove(removeable)
//         });
//       }
//       return
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   };
  
  
  // store image in firebase
  export const uploadFilesThunk = async ({ image, urlStorage }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      const fileName = `${image.name}-${uuidv4()}`
  
      const storageRef = ref(storage, urlStorage + fileName)
      const reference = urlStorage + fileName
      const uploadTask = uploadBytesResumable(storageRef, image);
  
      uploadTask.on('state_changed',
        (snapshot) => {
  
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          return thunkAPI.rejectWithValue(error);
          // reject(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve({ reference: reference, url: downloadURL });
          });
  
  
        }
      );
    })
  }
  
  
  
  

  export const deletePagesThunk = async ({ docId }, thunkAPI) => {
    try {

      const docRef = doc(db, 'pages', docId);
      deleteDoc(docRef)
        .then(() => {
          // Delete the file
          
            // File deleted successfully
            console.log('file deleted successfully');
          
  
        })
  
  
  
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  };
  