import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Wrapper from "../assets/wrappers/EditUserModal";
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap";

//galery
import { FileUploader } from "react-drag-drop-files";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../utils/firebase";
import { uploadBytes, getDownloadURL } from "firebase/storage";

import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllUpdate,
  createProperty,
  uploadFiles,
  deleteStorageFile,
  handleRemoveImg,
  handleUpload,
} from "../features/allProperties/allPropertiesSlice";
import WorldMap from "../utils/world_js";
import { AllWorldMap } from "../utils/constData";
import Select from "react-select";
import PhotoWidget from "../components/shared/PhotoWidget";
import { Loading } from "../components";
import Editor from "../components/Editor";
import { InputRowWidget } from "../components/InputRowWidget";

export default function WidgetsModal({
  show,
  setShow,
  data,
  title,
  action,
  name,
}) {
  //galery
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [featuredImage, setFeaturedImage] = useState(null);
  const [featuredImagePath, setFeaturedImagePath] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([]);
  const deleteThumbnail = () => {
    if (featuredImage.name) {
      setFeaturedImage(null);
      setFeaturedImagePath("");
    } else {
      deleteObject(ref(storage, featuredImagePath)).then(() => {
        setFeaturedImage(null);
        setFeaturedImagePath("");
      });
    }
  };
  function randomName() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  const uploadThumbnailHandler = (file) => {
    setFeaturedImage(file);
    const random = randomName();
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`);
  };
  const uploadThumbnailProcess = () => {
    return new Promise((resolve) => {
      if (featuredImage && featuredImage.name) {
        const reference = ref(storage, featuredImagePath);
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then((url) => {
            resolve({ url: url, reference: featuredImagePath });
          });
        });
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath });
      }
    });
  };
  const deleteGalleryFile = (index) => {
    if (galleryImages[index].name) {
      const fileArray = [...galleryImages];
      const pathArray = [...galleryImagesPaths];
      fileArray.splice(index, 1);
      pathArray.splice(index, 1);
      setGalleryImages(fileArray);
      setGalleryImagesPaths(pathArray);
    } else {
      deleteObject(ref(storage, galleryImagesPaths[index])).then(() => {
        const fileArray = [...galleryImages];
        const pathArray = [...galleryImagesPaths];
        fileArray.splice(index, 1);
        pathArray.splice(index, 1);
        setGalleryImages(fileArray);
        setGalleryImagesPaths(pathArray);
      });
    }
  };
  const uploadGalleryHandler = (files) => {
    let processed = 0;
    if (files.length + galleryImages.length > 5) {
      alert("You can only insert 5 images");
      return;
    }
    const oldFiles = [...galleryImages];
    const oldPaths = [...galleryImagesPaths];
    const newFiles = [...files];

    newFiles.forEach((file) => {
      oldFiles.push(file);
      const random = randomName();
      oldPaths.push(`images/property/gallery/${random}${file.name}`);
      processed++;
      if (processed === files.length) {
        setGalleryImages(oldFiles);
        setGalleryImagesPaths(oldPaths);
      }
    });
  };
  const uploadGalleryProcess = async () => {
    return new Promise((resolve) => {
      const gallery = [...galleryImages];
      let processed = 0;
      const arrayToBeUploaded = [];
      if (gallery.length === 0) {
        resolve([]);
      }
      gallery.forEach((image, index) => {
        if (image.name) {
          const reference = ref(storage, galleryImagesPaths[index]);
          uploadBytes(reference, image).then(() => {
            getDownloadURL(reference).then((url) => {
              arrayToBeUploaded.push({
                url: url,
                reference: galleryImagesPaths[index],
              });
              processed++;
              if (processed === gallery.length) {
                resolve(arrayToBeUploaded);
              }
            });
          });
        } else {
          arrayToBeUploaded.push({
            url: galleryImages[index],
            reference: galleryImagesPaths[index],
          });
          processed++;
          if (processed === gallery.length) {
            resolve(arrayToBeUploaded);
          }
        }
      });
    });
  };

  const { user } = useSelector((store) => store.user);
  const {
    isAddModalOpen,
    isEditModalOpen,
    propertyTypes,
    subcategories,
    uploads,
    uploadEditImages,
    deletedImages,
    isLoading,
  } = useSelector((store) => store.allProperties);
  const { amenities } = useSelector((store) => store.propertySettings);
  const [propertyTypesSelected, setPropertyTypesSelected] = useState([]);
  const [propertyCatSelected, setPropertyCatSelected] = useState(
    data.type_subcategory
  );
  const [story, setStory] = useState({});

  const handleClose = () => {
    setShow(false);
  };
  const [categories, setCategories] = useState([{ label: "", value: "" }]);
  const [country, setCountry] = useState(
    data ? data.country : { label: "", value: "" }
  );
  const [city, setCity] = useState(data.caza);
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const [amentiesSelected, setAmentiesSelected] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [datas, setDatas] = useState("");
  const [datass, setDatass] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      description: "",
      description_ar: "",
      image: "",
      isexternal: false,
      link: "",
      svg: "",
      title: "",
      title_ar: "",
    },
  ]);

  useEffect(() => {
    if (data.details[0]) {
      console.log("test1");
      setInputFields(data.details);
    }
  }, []);

  const handleChange = (event, index) => {
    let values = JSON.parse(JSON.stringify(inputFields));
    console.log("test2", event, index);
    values[index][event.target.name] = event.target.value;

    setInputFields(values);
  };

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        description: "",
        description_ar: "",
        image: "",
        isexternal: false,
        link: "",
        svg: "",
        title: "",
        title_ar: "",
      },
    ]);
  };
  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  const dispatch = useDispatch();

  const optionsType = [
    { value: "top", label: "Top" },
    { value: "ourservices", label: "our services" },
    { value: "browse", label: "browse" },
    { value: "explore", label: "explore" },
    { value: "followus", label: "followus" },
    { value: "topcategories", label: "top categories" },
  ];
  const optionslevel = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];

  const optionsPosition = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
  ];

  const fixCategories = (val) => {
    let allKeys = [];
    subcategories.forEach((tp) => {
      let key = Object.keys(tp)[0];
      allKeys.push(key);
    });

    allKeys.forEach((key, index) => {
      if (key === val) {
        setCategories(subcategories[index][val]);
      }
    });
  };
  if (isLoading) {
    return <Loading />;
  }

  const validation = useFormik({
    initialValues: {
      title: (data && data.title) || "",
      titleAr: (data && data.title_ar) || "",
      link: (data && data.link) || "",
      value: (data && data.value) || "",
      description: (data && data.description) || "",
      description_ar: (data && data.description_ar) || "",
      level: (data && data.level) || "",
      link: (data && data.link) || "",
      position: (data && data.position) || "",
      type: (data && data.type) || "",
      name: (data && data.name) || "",
      linkText: (data && data.link_text) || "",
      linkTextAr: (data && data.link_text_ar) || "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("The title is required"),
      titleAr: Yup.string().required("The title is required"),
      link: Yup.string().required("The link is required"),
      type: Yup.string().required("The description is required"),
      name: Yup.string().required("The description is required"),
      linkText: Yup.string().required("The description is required"),
      linkTextAr: Yup.string().required("The description is required"),
    }),

    onSubmit: async (values) => {
      if (isAddModalOpen) {
        const imgUrls = await Promise.all(
          [...uploads].map((image, index) => {
            if (index === 0) {
              return dispatch(
                uploadFiles({
                  image: uploads[0],
                  urlStorage: "images/property/thumbnail/",
                })
              );
            } else
              return dispatch(
                uploadFiles({
                  image: image,
                  urlStorage: "images/property/gallery/",
                })
              );
          })
        ).catch(() => {
          console.log("error image");
          return;
        });
        var result = imgUrls.map(({ payload }) => payload);
      }

      if (isEditModalOpen && deletedImages) {
        if (uploads.length >= 2) {
          deletedImages.map((image) => {
            dispatch(deleteStorageFile(image));
          });
          result = uploads;
        }
      }

      if (isEditModalOpen && uploadEditImages) {
        const imgUrls = await Promise.all(
          [...uploadEditImages].map((image, index) => {
            return dispatch(
              uploadFiles({
                image: image,
                urlStorage: "images/property/gallery/",
              })
            );
          })
        ).catch(() => {
          console.log("error image");
          return;
        });
        result = imgUrls.map(({ payload }) => payload);
        result = [...uploads, ...result];
      }
      let thumbnailUpload, galleryUpload;

      uploadThumbnailProcess().then((value) => {
        thumbnailUpload = value;
        const nav = {
          title: values.title,
          title_ar: values.titleAr,
          type: values.type,
          link: values.link,
          description: datas.replace(/(<([^>]+)>)/gi, ""),
          description_ar: datass.replace(/(<([^>]+)>)/gi, ""),

          image: name == "edit" ? data.image : thumbnailUpload,
          name: values.name,
          link_text: values.linkText,
          link_text_ar: values.linkTextAr,
          isexternal: false,
          id: data ? data.id : 0,
          details: inputFields,
        };
        console.log("values", values);
        console.log("prop", nav);
        console.log("label props", inputFields);

        if (name === "add") {
          dispatch(action(nav));
        } else {
          console.log("nav", nav);
          dispatch(action(nav));
        }
      });

      setShow(false);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Wrapper>
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title>{title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row form="true">
              <Col className="col-12">
                <Row form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">title</Label>
                      <Input
                        name="title"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">link</Label>
                      <Input
                        name="link"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link}
                        invalid={
                          validation.touched.link && validation.errors.link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link && validation.errors.link ? (
                        <FormFeedback type="invalid">
                          {validation.errors.link}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">type</Label>
                      <Input
                        readOnly={name === "edit" ? true : false}
                        name="type"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      />
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">title ar</Label>
                      <Input
                        className="ar"
                        name="titleAr"
                        type="titleAr"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.titleAr}
                        invalid={
                          validation.touched.titleAr &&
                          validation.errors.titleAr
                            ? true
                            : false
                        }
                      />
                      {validation.touched.titleAr &&
                      validation.errors.titleAr ? (
                        <FormFeedback type="invalid">
                          {validation.errors.titleAr}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="" form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">name</Label>
                      <Input
                        readOnly={name === "edit" ? true : false}
                        name="name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">linkText</Label>
                      <Input
                        name="linkText"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.linkText || ""}
                        invalid={
                          validation.touched.linkText &&
                          validation.errors.linkText
                            ? true
                            : false
                        }
                      />

                      {validation.touched.linkText &&
                      validation.errors.linkText ? (
                        <FormFeedback type="invalid">
                          {validation.errors.linkText}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="" form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">link text ar</Label>
                      <Input
                        name="linkTextAr"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.linkTextAr}
                        invalid={
                          validation.touched.linkTextAr &&
                          validation.errors.linkTextAr
                            ? true
                            : false
                        }
                      />
                      {validation.touched.linkTextAr &&
                      validation.errors.linkTextAr ? (
                        <FormFeedback type="invalid">
                          {validation.errors.linkTextAr}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6 d-none">
                    <div className="mb-3">
                      <Label className="form-label">linkText</Label>
                      <Input
                        name="linkText"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.linkText || ""}
                        invalid={
                          validation.touched.linkText &&
                          validation.errors.linkText
                            ? true
                            : false
                        }
                      />

                      {validation.touched.linkText &&
                      validation.errors.linkText ? (
                        <FormFeedback type="invalid">
                          {validation.errors.linkText}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="font d-none">
                  <Col className="col-3">
                    <div className="mb-3"></div>
                  </Col>

                  <Col className="col-3 invisible">
                    <div className="mb-3">
                      <Input
                        name="num_bathrooms"
                        type="number"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bathrooms || ""}
                        invalid={
                          validation.touched.num_bathrooms &&
                          validation.errors.num_bathrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.after_repair_value &&
                      validation.errors.after_repair_value ? (
                        <FormFeedback type="invalid">
                          {validation.errors.after_repair_value}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-3 invisible">
                    <div className="mb-3">
                      <Label className="form-label">Bedrooms</Label>
                      <Input
                        name="num_bedrooms"
                        type="number"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bedrooms || ""}
                        invalid={
                          validation.touched.num_bedrooms &&
                          validation.errors.num_bedrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_bedrooms &&
                      validation.errors.num_bedrooms ? (
                        <FormFeedback type="invalid">
                          {validation.errors.num_bedrooms}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-3 invisible">
                    <div className="mb-3">
                      <Label className="form-label">Floors</Label>
                      <Input
                        name="num_floors"
                        type="number"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_floors}
                        invalid={
                          validation.touched.num_floors &&
                          validation.errors.num_floors
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_floors &&
                      validation.errors.num_floors ? (
                        <FormFeedback type="invalid">
                          {validation.errors.num_floors}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="font d-none">
                  <Col className="col-3">
                    <div className="mb-3">
                      <Label className="form-label">Dining Rooms</Label>
                      <Input
                        name="num_dining_rooms"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_dining_rooms}
                        invalid={
                          validation.touched.num_dining_rooms &&
                          validation.errors.num_dining_rooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_dining_rooms &&
                      validation.errors.num_dining_rooms ? (
                        <FormFeedback type="invalid">
                          {validation.errors.num_dining_rooms}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-3">
                    <div className="mb-3">
                      <Label className="form-label">Living Room</Label>
                      <Input
                        name="num_living_room"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_living_room}
                        invalid={
                          validation.touched.num_living_room &&
                          validation.errors.num_living_room
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_living_room &&
                      validation.errors.num_living_room ? (
                        <FormFeedback type="invalid">
                          {validation.errors.num_dining_rooms}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-3">
                    <div className="mb-3">
                      <Label className="form-label">Salons</Label>

                      <Input
                        name="num_salons"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_salons}
                        invalid={
                          validation.touched.num_salons &&
                          validation.errors.num_salons
                            ? true
                            : false
                        }
                      />
                      {validation.touched.num_salons &&
                      validation.errors.num_salons ? (
                        <FormFeedback type="invalid">
                          {validation.errors.num_salons}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/* <Col>
                    <div className='bg-blue mb-4'>
                      <label className='form-label'>Featured and Gallery Images</label>
                      <div>
                        <PhotoWidget
                          id='featured_image'
                          value={validation.values.featured_image}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validation={validation}
                          validationValue={validation.values.featured_image}
                          handleRemoveImg={handleRemoveImg}
                          handleUpload={handleUpload}
                          uploads={uploads}
                        />
                      </div>

                    </div>
                  </Col> */}
                  {inputFields.map((item, index) => (
                    <div key={index}>
                      <InputRowWidget
                        inputFields={inputFields}
                        index={index}
                        item={item}
                        handleChange={handleChange}
                        handleRemove={handleRemove}
                        handleAdd={handleAdd}
                      />
                    </div>
                  ))}
                </Row>
                <Row>
                  <Label className="form-label">description</Label>
                  <Editor
                    name="description"
                    onChange={(datas) => {
                      setDatas(datas);
                    }}
                    value={name == "edit" ? data.description : ""}
                    editorLoaded={editorLoaded}
                  />
                </Row>
                <Row>
                  <Label className="form-label">description_ar</Label>
                  <Editor
                    name="description_ar"
                    onChange={(datass) => {
                      setDatass(datass);
                    }}
                    value={name == "edit" ? data.description_ar : ""}
                    editorLoaded={editorLoaded}
                  />
                </Row>
              </Col>
              <div className="bg-blue mb-4">
                <label className="form-label">
                  Featured and Gallery Images
                </label>
                <div>
                  <FileUploader
                    classes="dropzone"
                    handleChange={uploadThumbnailHandler}
                    name="file"
                    types={fileTypes}
                  />

                  {featuredImage && (
                    <div
                      className="thumbnail-container"
                      onClick={deleteThumbnail}
                    >
                      <div className="thumbnail-overlay">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.531"
                          height="7.619"
                          viewBox="0 0 6.531 7.619"
                        >
                          <path
                            id="Path_185"
                            data-name="Path 185"
                            d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                            transform="translate(-0.003 -31.981)"
                          />
                        </svg>
                      </div>
                      <img
                        src={
                          featuredImage.name
                            ? URL.createObjectURL(featuredImage)
                            : featuredImage
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </Row>
            <Row>
              <Col>
                <div className="text-end pb-4">
                  <button type="submit" className="btn btn-success  btn-save">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
