import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, MySearch, MyExportCSV } from '../../components';
import ConfirmModalNav from '../../modals/ConfirmModalNav';
import PropertiesModalEdit from '../../modals/PropertiesModalEdit';
import { MdEdit } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import icon from '../../assets/images/edit-big.svg';
import ViewPropertyModal from '../../modals/ViewPropertyModal';
import PreviewThumbnailModal from '../../modals/PreviewThumbnailModal';
import logo from '../../assets/images/logo.png';
import Wrapper from '../../assets/wrappers/AllProperties';
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from '../../utils/Table';
import {createProperty,getAllUpdate, deleteProperty, getAllProperties, getCatProperty, getTypeProperty, handleAddProperty, handleDelete, handleEditProperties, handleViewProperty, handleViewThumbnail } from '../../features/allProperties/allPropertiesSlice';
import { getAmenities } from '../../features/PropertySettings/PropertySettingsSlice';

const AllProperties = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    properties,
    isViewPropertyModalOpen,
    isDeleteModalOpen,
    isEditModalOpen,
    isAddModalOpen,
    isViewThumbnailModalOpen,
    currentProperty } = useSelector((store) => store.allProperties);


  useEffect(() => {
    const timeout = setTimeout(() => {
      // setCount(1);
    }, 3000);

    dispatch(getAllProperties());
  }, [isEditModalOpen, isAddModalOpen, isDeleteModalOpen]);

  useEffect(() => {
    dispatch(getTypeProperty());
    dispatch(getCatProperty());
    dispatch(getAmenities());
  }, []);

  const columns = [
    {
      dataField: 'featured_image.url',
      text: ' ',
      formatter: (cellContent, event) => (
        <div className='thumbnail-container'>
          <img
            className='thumbnail'
            src={cellContent ? cellContent : logo}
            onClick={() => dispatch(handleViewThumbnail(cellContent))}
            alt='home'
          />
        </div>
      ),
    },

    {
      dataField: 'added_by',
      text: 'Added By',
    },
    {
      dataField: 'type.value',
      text: 'Type',
    },
    {
      dataField: 'type_subcategory.value',
      text: 'Subcategory',
    },
    {
      dataField: 'insurance.value',
      text: 'Insurance',
    },
    {
      dataField: 'country.value',
      text: 'Country',
    },
    {
      dataField: 'sale_price',
      text: 'Sale Price',
    },

    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      formatter: (cellContent, row) => (
        <div className='d-flex gap-3'>
          <MdEdit onClick={() => dispatch(handleEditProperties(row))} />
          <AiFillEye onClick={() => dispatch(handleViewProperty(row))} />
          <BsFillTrashFill onClick={() => dispatch(handleDelete(row))} />
        </div>
      ),
    },
  ];


  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: properties.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: 'added_by',
      order: 'asc',
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (properties.length === 0) {
    return <h2>No Properties to display...</h2>;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='id'
            data={properties}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className='d-flex justify-content-between'>
                  <h5>
                    {properties.length} PROPERT
                    {properties.length > 1 ? 'IES' : 'Y'} FOUND
                  </h5>
                  <div className='d-flex'>
                    <MySearch {...props.searchProps} />

                    <button className='add-btn ms-2 me-2'
                      onClick={() => dispatch(handleAddProperty())}
                    >Add Property</button>
                    <MyExportCSV {...props.csvProps} />
                  </div>
                </div>
                <BootstrapTable
                  key='id'
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className='row'>
                  <div className='col pagination pagination-rounded justify-content-end '>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {isEditModalOpen && (
        <PropertiesModalEdit
          setShow={() => dispatch(handleEditProperties())}
          show={isEditModalOpen}
          data={currentProperty}
          title="Edit Property"
          name='edit'
          action={getAllUpdate}
        />
      )}
      {isAddModalOpen && (
        <PropertiesModalEdit
          setShow={() => dispatch(handleAddProperty())}
          show={isAddModalOpen}
          data={currentProperty}
          title="Add Property"
          name='add'
          action={createProperty}
        />
      )}
      {isDeleteModalOpen && (
        

        <ConfirmModalNav
        setShow={() => dispatch(handleDelete())}
        show={isDeleteModalOpen}
        title='Delete Property'
        bodyText='Are you sure you want to delete this Property?'
        icon={icon}
        color='danger'
        handleAction={deleteProperty}
        id={currentProperty.id}


      />
      )}

      {isViewPropertyModalOpen && (
        <ViewPropertyModal
          setShow={() => dispatch(handleViewProperty())}
          show={isViewPropertyModalOpen}
          data={{ ...currentProperty, isLoading }}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={currentProperty}
        />
      )}
    </Wrapper>
  );
};

export default AllProperties;