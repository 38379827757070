import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading, MySearch, MyExportCSV } from "../../components";
import ModalEdit from "../../modals/ModalEdit";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import Wrapper from "../../assets/wrappers/AllUsers";
import ViewMoreModal from "../../modals/ViewMoreModal";
import ConfirmModal from "../../modals/ConfirmModal";
import icon from "../../assets/images/edit-big.svg";
import PreviewThumbnailModal from "../../modals/PreviewThumbnailModal";
import logo from "../../assets/images/logo.png";
import {
  deleteUser,
  getAllUsers,
  handleViewUser,
  handleEdit,
  handleDelete,
  handleViewThumbnail,
} from "../../features/allUsers/allUsersSlice";

import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from "../../utils/Table";

const AllUser = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    users,
    isViewUserModalOpen,
    isDeleteModalOpen,
    isEditModalUserOpen,
    isViewThumbnailModalOpen,
    currentProperty,
    currentUser,
    EdiUserClicked,
    deleteuser,
    Thumbnail,
  } = useSelector((store) => store.allUsers);

  useEffect(() => {
    dispatch(getAllUsers(users));
  }, [isEditModalUserOpen]);

  const columns = [
    {
      dataField: "profile_image.url",
      text: " ",
      formatter: (cellContent, event) => (
        <div className="thumbnail-container">
          <img
            className="thumbnail"
            src={cellContent || logo}
            onClick={() => dispatch(handleViewThumbnail(cellContent))}
            alt="home"
          />
        </div>
      ),
    },

    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => cellContent + " " + row.last_name,
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
    },
    {
      dataField: "email",
      text: "Email",
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, event) => (
        <>
          <div className="d-flex gap-3">
            {/* <MdEdit onClick={() => dispatch(handleEdit(event))} /> */}
            {event.flag && event.flag === true ? (
              <p>deleted</p>
            ) : (
              <>
                <AiFillEye onClick={() => dispatch(handleViewUser(event))} />
                <BsFillTrashFill
                  onClick={() => dispatch(handleDelete(event))}
                />
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: users.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: "first_name",
      order: "asc",
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (users.length === 0) {
    return <h2>No Users to display...</h2>;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" data={users} columns={columns} search>
            {(props) => (
              <>
                <div className="d-flex justify-content-between">
                  <h5>
                    {users.length} USER{users.length > 1 && "S"} FOUND
                  </h5>
                  <div className="d-flex">
                    <MyExportCSV {...props.csvProps} />
                    <MySearch {...props.searchProps} />
                  </div>
                </div>
                <BootstrapTable
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className="row">
                  <div className="col pagination pagination-rounded justify-content-end ">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {isEditModalUserOpen && (
        <ModalEdit
          setShow={() => dispatch(handleEdit())}
          show={isEditModalUserOpen}
          data={{ ...EdiUserClicked, isLoading }}
        />
      )}

      {isDeleteModalOpen && (
        <ConfirmModal
          // setShow={() => dispatch(handleDelete())}
          // show={isDeleteModalOpen}
          // title='Delete User'
          // bodyText='Are you sure you want to delete this user account?'
          // icon={icon}
          // color='danger'
          // data={{ ...deleteuser, isLoading }}
          setShow={() => dispatch(handleDelete())}
          show={isDeleteModalOpen}
          title="Delete user"
          bodyText="Are you sure you want to delete this user?"
          icon={icon}
          color="danger"
          handleAction={deleteUser}
          id={deleteuser}
          user={true}
        />
      )}
      {isViewUserModalOpen && (
        <ViewMoreModal
          setShow={() => dispatch(handleViewUser())}
          show={isViewUserModalOpen}
          data={{ ...currentUser, isLoading }}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={{ Thumbnail, isLoading }}
        />
      )}
    </Wrapper>
  );
};
export default AllUser;
