
import countriesStates from '../utils/countriesStates.json';

const toArrayOfObject = arr => {
  return arr.map(a => ({ value: a.replace(/\s/g, ''), label: a }));
};

let countries = [];
countriesStates.map(country => {
  countries.push({
    label: country.name,
    value: country.name.replace(/\s/g, ''),
    iso3: country.iso3,
    iso2: country.iso2,
    latitude: country.latitude,
    longitude: country.longitude,
  });
});




const WorldMap = {
  countries: countries,
};
export default WorldMap;
