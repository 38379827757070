import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import { Col, Row, Form, Input, FormFeedback, Label } from 'reactstrap';

import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
    
  getAllUpdate, createProperty, uploadFiles, deleteStorageFile, handleRemoveImg,
  handleUpload,
} from '../features/allProperties/allPropertiesSlice';
import WorldMap from '../utils/world_js';
import { AllWorldMap } from '../utils/constData';
import Select from 'react-select'
import PhotoWidget from '../components/shared/PhotoWidget';
import { Loading } from '../components';
import Editor from "../components/Editor";
import { InputRow } from "../components/InputRow"

export default function PropertyTypeModal({ show, setShow, data, title, action,name }) {
  const { user } = useSelector((store) => store.user);
  const { isAddModalOpen, isEditModalOpen, propertyTypes, subcategories, uploads, uploadEditImages, deletedImages, isLoading } = useSelector((store) => store.allProperties);
  const { amenities } = useSelector((store) => store.propertySettings);
  const [propertyTypesSelected, setPropertyTypesSelected] = useState([])
  const [propertyCatSelected, setPropertyCatSelected] = useState(data.type_subcategory)
  const [story, setStory] = useState({})

  const handleClose = () => {
    setShow(false);
  };
  const [categories, setCategories] = useState([{ label: '', value: '' }]);
  const [country, setCountry] = useState(data ? data.country : { label: '', value: '' });
  const [city, setCity] = useState(data.caza)
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [amentiesSelected, setAmentiesSelected] = useState([])
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [datas, setDatas] = useState("");
  const [inputFields, setInputFields] = useState([
    {
      label: "",
      value:""
    }
  ])

  const handleChange = (event, index) => {
    const values = [...inputFields]
    console.log("momo", values)
    values[index][event.target.name] = event.target.value

    setInputFields(values)
  }
  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        label: "",
        value:""
      }
    ])
  }
  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields]
      values.splice(index, 1)
      setInputFields(values)
    }
  }
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  const dispatch = useDispatch();

  const optionsType = [
    { value: 'top', label: 'Top' },
    { value: 'ourservices', label: 'our services' },
    {value:'browse', label:'browse'},
    {value:'explore', label:'explore'},
    {value:'followus', label:'followus'},
    {value:'topcategories', label:'top categories'},

  ]
  const optionslevel = [
    { value: 0, label: '0' },
    { value:1 , label: '1' },
    {value:2, label:'2'},
    {value:3, label:'3'},
  ]

  const optionsPosition = [
    { value: 0, label: '0' },
    { value:1 , label: '1' },
    {value:2, label:'2'},
    {value:3, label:'3'},
    { value: 4, label: '4' },
    { value:5 , label: '5' },
    {value:6, label:'6'},
    {value:7, label:'7'},
    { value: 8, label: '8' },
    { value:9 , label: '9' },
    {value:10, label:'10'},
    {value:11, label:'11'},
    { value: 12, label: '12' },
    { value:13 , label: '13' },
    {value:14, label:'14'},
    {value:15, label:'15'},
  ]

  const fixCategories = val => {
    let allKeys = [];
    subcategories.forEach(tp => {
      let key = Object.keys(tp)[0];
      allKeys.push(key);
    });

    allKeys.forEach((key, index) => {
      if (key === val) {
        setCategories(subcategories[index][val]);
      }
    });
  };
  if (isLoading) {
    return <Loading />;
  }


  const validation = useFormik({

    initialValues: {
      title: (data && data.title) || '',
      link: (data && data.link) || '',
      value: (data && data.value) || '',
      description: (data && data.description) || '',
      level: (data && data.level) || '',
      link: (data && data.link) || '',
      position: (data && data.position) || '',
      type: (data && data.type) || '',

    },

    validationSchema: Yup.object({
      title: Yup.string().required('The title is required'),
      link: Yup.string().required('The link is required'),
      value: Yup.string().required('The value is required'),


        // description: Yup.string().required('The description is required'),
          
    }),

    onSubmit: async (values) => {
      if (isAddModalOpen) {
        const imgUrls = await Promise.all(

          [...uploads].map((image, index) => {
            if (index === 0) {
              return dispatch(uploadFiles({ image: uploads[0], urlStorage: 'images/property/thumbnail/' }));
            } else
              return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        var result = imgUrls.map(({ payload }) => payload)
      }


      if (isEditModalOpen && deletedImages) {
        if (uploads.length >= 2) {
          deletedImages.map((image) => {
            dispatch(deleteStorageFile(image))
          })
          result = uploads
        }

      }

      if (isEditModalOpen && uploadEditImages) {
        const imgUrls = await Promise.all(

          [...uploadEditImages].map((image, index) => {

            return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        result = imgUrls.map(({ payload }) => payload)
        result = [...uploads, ...result]

      }
      
      const nav = {
        title: values.title,
        link: values.link,
        value: values.value,

        description: JSON.stringify(datas),
        id: data ? data.id : 0,
        image: '',
        subcategories: inputFields
      };
      console.log("values", values)
      console.log("prop", nav)
      console.log('label props',inputFields)

      if(name==='add'){
        dispatch(action(nav));
      }
      else{
        dispatch(action(nav));
      }

      setShow(false);
    },
  });




  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>{title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row form='true'>
              <Col className='col-12'>


                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>title</Label>
                      <Input
                        name='title'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title}
                        invalid={
                          validation.touched.title &&
                            validation.errors.title
                            ? true
                            : false
                        }
                        />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>link</Label>
                      <Input
                        name='link'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link}
                        invalid={
                          validation.touched.link &&
                            validation.errors.link
                            ? true
                            : false
                        }
                        />
                      {validation.touched.link && validation.errors.link ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.link}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>value</Label>
                      <Input
                        name='value'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.value}
                        invalid={
                          validation.touched.value &&
                          validation.errors.value
                            ? true
                            : false
                        }
                        />
                      {validation.touched.value && validation.errors.value ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.value}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>

                      <Input
                      className='d-none'
                        name='description'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description }
                        invalid={
                          validation.touched.description &&
                            validation.errors.description
                            ? true
                            : false
                        }
                        />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className='d-none' form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>link</Label>
                      <Input
                        name='link'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link}
                        invalid={
                          validation.touched.link &&
                            validation.errors.link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link &&
                        validation.errors.link ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.link}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>position</Label>
                      <Input
                        name='position'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.position || ""}
                        invalid={
                          validation.touched.position &&
                            validation.errors.position
                            ? true
                            : false
                        }
                      />

                      {validation.touched.position &&
                        validation.errors.position ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.position}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className='font d-none'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      

                    </div>
                  </Col>

                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Input
                        name='num_bathrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bathrooms || ""}
                        invalid={
                          validation.touched.num_bathrooms &&
                            validation.errors.num_bathrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.after_repair_value &&
                        validation.errors.after_repair_value ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.after_repair_value}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Label className='form-label'>Bedrooms</Label>
                      <Input
                        name='num_bedrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bedrooms || ""}
                        invalid={
                          validation.touched.num_bedrooms &&
                            validation.errors.num_bedrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_bedrooms &&
                        validation.errors.num_bedrooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_bedrooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Label className='form-label'>Floors</Label>
                      <Input
                        name='num_floors'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_floors}
                        invalid={
                          validation.touched.num_floors &&
                            validation.errors.num_floors
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_floors &&
                        validation.errors.num_floors ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_floors}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>

                </Row>

                <Row className='font d-none'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Dining Rooms</Label>
                      <Input
                        name='num_dining_rooms'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_dining_rooms}
                        invalid={
                          validation.touched.num_dining_rooms &&
                            validation.errors.num_dining_rooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_dining_rooms &&
                        validation.errors.num_dining_rooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Living Room</Label>
                      <Input
                        name='num_living_room'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_living_room}
                        invalid={
                          validation.touched.num_living_room &&
                            validation.errors.num_living_room
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_living_room &&
                        validation.errors.num_living_room ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Salons</Label>

                      <Input
                        name='num_salons'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_salons}
                        invalid={
                          validation.touched.num_salons &&
                            validation.errors.num_salons
                            ? true
                            : false
                        }
                      />
                      {validation.touched.num_salons &&
                        validation.errors.num_salons ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_salons}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/* <Col>
                    <div className='bg-blue mb-4'>
                      <label className='form-label'>Featured and Gallery Images</label>
                      <div>
                        <PhotoWidget
                          id='featured_image'
                          value={validation.values.featured_image}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validation={validation}
                          validationValue={validation.values.featured_image}
                          handleRemoveImg={handleRemoveImg}
                          handleUpload={handleUpload}
                          uploads={uploads}
                        />
                      </div>

                    </div>
                  </Col> */}
                  {inputFields.map((item, index) => (
              <div key={index}>
                <InputRow
                  inputFields={inputFields}
                  index={index}
                  item={item}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                  handleAdd={handleAdd}
                />
              </div>
            ))}
                  
                </Row>
                <Row>
                <Label className='form-label'>description</Label>
                    <Editor
                        name="description"
                        onChange={(datas) => {
                        setDatas(datas);
                        }}
                        editorLoaded={editorLoaded}
                    />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-end pb-4'>
                  <button type='submit' className='btn btn-success  btn-save'>
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
