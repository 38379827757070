import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Wrapper from '../assets/wrappers/ViewUserModal';
import profile from '../assets/images/profile.jpg';

const ViewEventsModal = ({ show, setShow, data }) => {
  const { type, label, level, position, link } =
    data;
  const handleClose = () => {
    setShow(false);
  };
  // const adjustedDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='lg'
      dialogClassName='View More Modal'
      // aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Wrapper>
        <Modal.Header className='modalHeader' closeButton>

        </Modal.Header>
        <Modal.Body>
          <div className=''>

            <div className='mb-3'>
              <h4 className='mb-0'>type</h4>
              <span>{type}</span>
            </div>
            <div className='mb-3'>
              <h4 className='mb-0'>label</h4>
              <span>{label}</span>
            </div>
            <div className='mb-3'>
              <span>
                <h4 className='mb-0'>level</h4>
                <span>{level}</span>
              </span>
            </div>
            <div className='mb-3'>
              <h4 className='mb-0'>link</h4>
              <span>{link}</span>
            </div>
            <div className='mb-3'>
              <h4 className='mb-0'>position</h4>
              <span>{position}</span>
            </div>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default ViewEventsModal;
