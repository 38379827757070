import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Wrapper from '../assets/wrappers/modal';
import { useDispatch } from 'react-redux';
const ConfirmModalNav = ({ show, setShow, title, bodyText, icon, color, handleAction, id, removeImage, removeGallery }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
  };
  const handleDelete = () => {

    dispatch(handleAction({ docId: id}))


    setShow(false)
  };
  console.log('id',id);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='md'
      dialogClassName='ConfirmModal'
      centered
    >
      <Wrapper>
        <Modal.Header className='modalHeader' closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex '>
            <img className='icon' src={icon} alt='icon' />
            <p className='text'>{bodyText}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className='modalFooter'>
          <Button
            variant='secondary'
            className='modalbtn'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button className={`${color} modalbtn`} onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModalNav;
