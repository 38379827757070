export const unityType = [
  {id: 0, label: 'Residential', value: 'residential'},
  {id: 1, label: 'Commercial', value: 'commercial'},
  {id: 2, label: 'Vacant Land', value: 'vacantLand'},
];
export const salePricePostfixText = [
  {id: 0, label: '$', value: 'dollar'},
  {id: 1, label: 'L.L.', value: 'lira'},
  {id: 2, label: '€', value: 'euro'},
];
export const rentPricePostfixText = [
  {id: 0, label: '$', value: 'dollar'},
  {id: 1, label: 'L.L.', value: 'lira'},
  {id: 2, label: '€', value: 'euro'},
];
export const areaPostfixText = [
  {id: 0, label: 'Feet', value: 'feet'},
  {id: 1, label: 'Meter', value: 'meter'},
];

export const paymentMethod = [
  {id: 0, label: 'Cash', value: 'cash'},
  {id: 1, label: 'Credit', value: 'credit'},
];
export const countries = [
  {id: 0, label: 'Lebanon', value: 'Lebanon'},
  {id: 1, label: 'Dubai', value: 'Dubai'},
  {id: 2, label: 'France', value: 'France'},
];
export const subcategories = {
  residential: [
    {id: 0, label: 'Apartment', value: 'apartment'},
    {id: 1, label: 'Building', value: 'building'},
    {id: 2, label: 'Villa', value: 'villa'},
    {id: 3, label: 'Mansion', value: 'mansion'},
    {id: 4, label: 'Chalet', value: 'chalet'},
    {id: 5, label: 'House', value: 'house'},
    {id: 5, label: 'Student Housing', value: 'studentHousing'},
  ],
  commercial: [],
  vacantLand: [],
};

// export const subcategories = [
//   {id: 0, label: 'Residential', value: 'residential'},
//   {id: 1, label: 'Commercial', value: 'commercial'},
//   {id: 2, label: 'Vacant Land', value: 'vacantLand'},
// ];
export const AllWorldMap = {
  Cyprus: {
    name: 'Cyprus',
    iso3: 'CYP',
    iso2: 'CY',
    numeric_code: '196',
    phone_code: '357',
    capital: 'Nicosia',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.cy',
    native: 'Κύπρος',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Asia/Famagusta',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Asia/Nicosia',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      kr: '키프로스',
      br: 'Chipre',
      pt: 'Chipre',
      nl: 'Cyprus',
      hr: 'Cipar',
      fa: 'قبرس',
      de: 'Zypern',
      es: 'Chipre',
      fr: 'Chypre',
      ja: 'キプロス',
      it: 'Cipro',
      cn: '塞浦路斯',
    },
    latitude: '35.00000000',
    longitude: '33.00000000',
    emoji: '🇨🇾',
    emojiU: 'U+1F1E8 U+1F1FE',
    states: [
      {
        id: 749,
        name: 'Famagusta District (Mağusa)',
        state_code: '04',
        latitude: '35.28570230',
        longitude: '33.84112880',
        type: 'district',
      },
      {
        id: 744,
        name: 'Kyrenia District (Keryneia)',
        state_code: '06',
        latitude: '35.29919400',
        longitude: '33.23632460',
        type: 'district',
      },
      {
        id: 747,
        name: 'Larnaca District (Larnaka)',
        state_code: '03',
        latitude: '34.85072060',
        longitude: '33.48319060',
        type: 'district',
      },
      {
        id: 748,
        name: 'Limassol District (Leymasun)',
        state_code: '02',
        latitude: '34.70713010',
        longitude: '33.02261740',
        type: 'district',
      },
      {
        id: 745,
        name: 'Nicosia District (Lefkoşa)',
        state_code: '01',
        latitude: '35.18556590',
        longitude: '33.38227640',
        type: 'district',
      },
      {
        id: 746,
        name: 'Paphos District (Pafos)',
        state_code: '05',
        latitude: '34.91645940',
        longitude: '32.49200880',
        type: 'district',
      },
    ],
  },
  France: {
    name: 'France',
    iso3: 'FRA',
    iso2: 'FR',
    numeric_code: '250',
    phone_code: '33',
    capital: 'Paris',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.fr',
    native: 'France',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Paris',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      kr: '프랑스',
      br: 'França',
      pt: 'França',
      nl: 'Frankrijk',
      hr: 'Francuska',
      fa: 'فرانسه',
      de: 'Frankreich',
      es: 'Francia',
      fr: 'France',
      ja: 'フランス',
      it: 'Francia',
      cn: '法国',
    },
    latitude: '46.00000000',
    longitude: '2.00000000',
    emoji: '🇫🇷',
    emojiU: 'U+1F1EB U+1F1F7',
    states: [
      {
        id: 4967,
        name: 'Ain',
        state_code: '01',
        latitude: '46.06508600',
        longitude: '4.88861500',
        type: 'metropolitan department',
      },
      {
        id: 4968,
        name: 'Aisne',
        state_code: '02',
        latitude: '49.45289210',
        longitude: '3.04651110',
        type: 'metropolitan department',
      },
      {
        id: 4969,
        name: 'Allier',
        state_code: '03',
        latitude: '46.36708630',
        longitude: '2.58082770',
        type: 'metropolitan department',
      },
      {
        id: 4970,
        name: 'Alpes-de-Haute-Provence',
        state_code: '04',
        latitude: '44.16377520',
        longitude: '5.67247800',
        type: 'metropolitan department',
      },
      {
        id: 4972,
        name: 'Alpes-Maritimes',
        state_code: '06',
        latitude: '43.92041700',
        longitude: '6.61678220',
        type: 'metropolitan department',
      },
      {
        id: 4811,
        name: 'Alsace',
        state_code: '6AE',
        latitude: '48.31817950',
        longitude: '7.44162410',
        type: 'European collectivity',
      },
      {
        id: 4973,
        name: 'Ardèche',
        state_code: '07',
        latitude: '44.81486950',
        longitude: '3.81334830',
        type: 'metropolitan department',
      },
      {
        id: 4974,
        name: 'Ardennes',
        state_code: '08',
        latitude: '49.69759510',
        longitude: '4.14895760',
        type: 'metropolitan department',
      },
      {
        id: 4975,
        name: 'Ariège',
        state_code: '09',
        latitude: '42.94347830',
        longitude: '0.94048640',
        type: 'metropolitan department',
      },
      {
        id: 4976,
        name: 'Aube',
        state_code: '10',
        latitude: '48.31975470',
        longitude: '3.56371040',
        type: 'metropolitan department',
      },
      {
        id: 4977,
        name: 'Aude',
        state_code: '11',
        latitude: '43.05411400',
        longitude: '1.90384760',
        type: 'metropolitan department',
      },
      {
        id: 4798,
        name: 'Auvergne-Rhône-Alpes',
        state_code: 'ARA',
        latitude: '45.44714310',
        longitude: '4.38525070',
        type: 'metropolitan region',
      },
      {
        id: 4978,
        name: 'Aveyron',
        state_code: '12',
        latitude: '44.31563620',
        longitude: '2.08523790',
        type: 'metropolitan department',
      },
      {
        id: 5035,
        name: 'Bas-Rhin',
        state_code: '67',
        latitude: '48.59864440',
        longitude: '7.02666760',
        type: 'metropolitan department',
      },
      {
        id: 4979,
        name: 'Bouches-du-Rhône',
        state_code: '13',
        latitude: '43.54038650',
        longitude: '4.46138290',
        type: 'metropolitan department',
      },
      {
        id: 4825,
        name: 'Bourgogne-Franche-Comté',
        state_code: 'BFC',
        latitude: '47.28051270',
        longitude: '4.99943720',
        type: 'metropolitan region',
      },
      {
        id: 4807,
        name: 'Bretagne',
        state_code: 'BRE',
        latitude: '48.20204710',
        longitude: '-2.93264350',
        type: 'metropolitan region',
      },
      {
        id: 4981,
        name: 'Calvados',
        state_code: '14',
        latitude: '49.09035140',
        longitude: '-0.91706480',
        type: 'metropolitan department',
      },
      {
        id: 4982,
        name: 'Cantal',
        state_code: '15',
        latitude: '45.04921770',
        longitude: '2.15672720',
        type: 'metropolitan department',
      },
      {
        id: 4818,
        name: 'Centre-Val de Loire',
        state_code: 'CVL',
        latitude: '47.75156860',
        longitude: '1.67506310',
        type: 'metropolitan region',
      },
      {
        id: 4983,
        name: 'Charente',
        state_code: '16',
        latitude: '45.66584790',
        longitude: '-0.31845770',
        type: 'metropolitan department',
      },
      {
        id: 4984,
        name: 'Charente-Maritime',
        state_code: '17',
        latitude: '45.72968280',
        longitude: '-1.33881160',
        type: 'metropolitan department',
      },
      {
        id: 4985,
        name: 'Cher',
        state_code: '18',
        latitude: '47.02436280',
        longitude: '1.86627320',
        type: 'metropolitan department',
      },
      {
        id: 5064,
        name: 'Clipperton',
        state_code: 'CP',
        latitude: '10.28335410',
        longitude: '-109.22542150',
        type: 'dependency',
      },
      {
        id: 4986,
        name: 'Corrèze',
        state_code: '19',
        latitude: '45.34237070',
        longitude: '1.31717330',
        type: 'metropolitan department',
      },
      {
        id: 4806,
        name: 'Corse',
        state_code: '20R',
        latitude: '42.03960420',
        longitude: '9.01289260',
        type: 'metropolitan collectivity with special status',
      },
      {
        id: 4996,
        name: 'Corse-du-Sud',
        state_code: '2A',
        latitude: '41.85720550',
        longitude: '8.41091830',
        type: 'metropolitan department',
      },
      {
        id: 4987,
        name: "Côte-d'Or",
        state_code: '21',
        latitude: '47.46513020',
        longitude: '4.23154950',
        type: 'metropolitan department',
      },
      {
        id: 4988,
        name: "Côtes-d'Armor",
        state_code: '22',
        latitude: '48.46633360',
        longitude: '-3.34789610',
        type: 'metropolitan department',
      },
      {
        id: 4989,
        name: 'Creuse',
        state_code: '23',
        latitude: '46.05903940',
        longitude: '1.43150500',
        type: 'metropolitan department',
      },
      {
        id: 5047,
        name: 'Deux-Sèvres',
        state_code: '79',
        latitude: '46.53868170',
        longitude: '-0.90199480',
        type: 'metropolitan department',
      },
      {
        id: 4990,
        name: 'Dordogne',
        state_code: '24',
        latitude: '45.14234160',
        longitude: '0.14274080',
        type: 'metropolitan department',
      },
      {
        id: 4991,
        name: 'Doubs',
        state_code: '25',
        latitude: '46.93217740',
        longitude: '6.34762140',
        type: 'metropolitan department',
      },
      {
        id: 4992,
        name: 'Drôme',
        state_code: '26',
        latitude: '44.72933570',
        longitude: '4.67821580',
        type: 'metropolitan department',
      },
      {
        id: 5059,
        name: 'Essonne',
        state_code: '91',
        latitude: '48.53046150',
        longitude: '1.96990560',
        type: 'metropolitan department',
      },
      {
        id: 4993,
        name: 'Eure',
        state_code: '27',
        latitude: '49.07540350',
        longitude: '0.48937320',
        type: 'metropolitan department',
      },
      {
        id: 4994,
        name: 'Eure-et-Loir',
        state_code: '28',
        latitude: '48.44697840',
        longitude: '0.81470250',
        type: 'metropolitan department',
      },
      {
        id: 4995,
        name: 'Finistère',
        state_code: '29',
        latitude: '48.22696100',
        longitude: '-4.82437330',
        type: 'metropolitan department',
      },
      {
        id: 4822,
        name: 'French Guiana',
        state_code: '973',
        latitude: '3.93388900',
        longitude: '-53.12578200',
        type: 'overseas region',
      },
      {
        id: 4824,
        name: 'French Polynesia',
        state_code: 'PF',
        latitude: '-17.67974200',
        longitude: '-149.40684300',
        type: 'overseas collectivity',
      },
      {
        id: 5065,
        name: 'French Southern and Antarctic Lands',
        state_code: 'TF',
        latitude: '-47.54466040',
        longitude: '51.28375420',
        type: 'overseas territory',
      },
      {
        id: 4998,
        name: 'Gard',
        state_code: '30',
        latitude: '43.95952760',
        longitude: '3.49356810',
        type: 'metropolitan department',
      },
      {
        id: 5000,
        name: 'Gers',
        state_code: '32',
        latitude: '43.69505340',
        longitude: '-0.09997280',
        type: 'metropolitan department',
      },
      {
        id: 5001,
        name: 'Gironde',
        state_code: '33',
        latitude: '44.89584690',
        longitude: '-1.59405320',
        type: 'metropolitan department',
      },
      {
        id: 4820,
        name: 'Grand-Est',
        state_code: 'GES',
        latitude: '48.69980300',
        longitude: '6.18780740',
        type: 'metropolitan region',
      },
      {
        id: 4829,
        name: 'Guadeloupe',
        state_code: '971',
        latitude: '16.26500000',
        longitude: '-61.55100000',
        type: 'overseas region',
      },
      {
        id: 5036,
        name: 'Haut-Rhin',
        state_code: '68',
        latitude: '47.86537740',
        longitude: '6.67113810',
        type: 'metropolitan department',
      },
      {
        id: 4997,
        name: 'Haute-Corse',
        state_code: '2B',
        latitude: '42.42958660',
        longitude: '8.50625610',
        type: 'metropolitan department',
      },
      {
        id: 4999,
        name: 'Haute-Garonne',
        state_code: '31',
        latitude: '43.30505550',
        longitude: '0.68455150',
        type: 'metropolitan department',
      },
      {
        id: 5011,
        name: 'Haute-Loire',
        state_code: '43',
        latitude: '45.08538060',
        longitude: '3.22607070',
        type: 'metropolitan department',
      },
      {
        id: 5020,
        name: 'Haute-Marne',
        state_code: '52',
        latitude: '48.13248210',
        longitude: '4.69834990',
        type: 'metropolitan department',
      },
      {
        id: 5039,
        name: 'Haute-Saône',
        state_code: '70',
        latitude: '47.63789960',
        longitude: '5.53550550',
        type: 'metropolitan department',
      },
      {
        id: 5043,
        name: 'Haute-Savoie',
        state_code: '74',
        latitude: '46.04452770',
        longitude: '5.86413800',
        type: 'metropolitan department',
      },
      {
        id: 5055,
        name: 'Haute-Vienne',
        state_code: '87',
        latitude: '45.91868780',
        longitude: '0.70972060',
        type: 'metropolitan department',
      },
      {
        id: 4971,
        name: 'Hautes-Alpes',
        state_code: '05',
        latitude: '44.65626820',
        longitude: '5.68732110',
        type: 'metropolitan department',
      },
      {
        id: 5033,
        name: 'Hautes-Pyrénées',
        state_code: '65',
        latitude: '43.14294620',
        longitude: '-0.40097360',
        type: 'metropolitan department',
      },
      {
        id: 4828,
        name: 'Hauts-de-France',
        state_code: 'HDF',
        latitude: '50.48011530',
        longitude: '2.79372650',
        type: 'metropolitan region',
      },
      {
        id: 5060,
        name: 'Hauts-de-Seine',
        state_code: '92',
        latitude: '48.84030080',
        longitude: '2.10125590',
        type: 'metropolitan department',
      },
      {
        id: 5002,
        name: 'Hérault',
        state_code: '34',
        latitude: '43.59111200',
        longitude: '2.80661080',
        type: 'metropolitan department',
      },
      {
        id: 4796,
        name: 'Île-de-France',
        state_code: 'IDF',
        latitude: '48.84991980',
        longitude: '2.63704110',
        type: 'metropolitan region',
      },
      {
        id: 5003,
        name: 'Ille-et-Vilaine',
        state_code: '35',
        latitude: '48.17624840',
        longitude: '-2.21304010',
        type: 'metropolitan department',
      },
      {
        id: 5004,
        name: 'Indre',
        state_code: '36',
        latitude: '46.81175500',
        longitude: '0.97555230',
        type: 'metropolitan department',
      },
      {
        id: 5005,
        name: 'Indre-et-Loire',
        state_code: '37',
        latitude: '47.22285820',
        longitude: '0.14896190',
        type: 'metropolitan department',
      },
      {
        id: 5006,
        name: 'Isère',
        state_code: '38',
        latitude: '45.28922710',
        longitude: '4.99023550',
        type: 'metropolitan department',
      },
      {
        id: 5007,
        name: 'Jura',
        state_code: '39',
        latitude: '46.78287410',
        longitude: '5.16918440',
        type: 'metropolitan department',
      },
      {
        id: 4823,
        name: 'La Réunion',
        state_code: '974',
        latitude: '-21.11514100',
        longitude: '55.53638400',
        type: 'overseas region',
      },
      {
        id: 5008,
        name: 'Landes',
        state_code: '40',
        latitude: '44.00950800',
        longitude: '-1.25385790',
        type: 'metropolitan department',
      },
      {
        id: 5009,
        name: 'Loir-et-Cher',
        state_code: '41',
        latitude: '47.65937600',
        longitude: '0.85376310',
        type: 'metropolitan department',
      },
      {
        id: 5010,
        name: 'Loire',
        state_code: '42',
        latitude: '46.35228120',
        longitude: '-1.17563390',
        type: 'metropolitan department',
      },
      {
        id: 5012,
        name: 'Loire-Atlantique',
        state_code: '44',
        latitude: '47.34757210',
        longitude: '-2.34663120',
        type: 'metropolitan department',
      },
      {
        id: 5013,
        name: 'Loiret',
        state_code: '45',
        latitude: '47.91354310',
        longitude: '1.76009900',
        type: 'metropolitan department',
      },
      {
        id: 5014,
        name: 'Lot',
        state_code: '46',
        latitude: '44.62460700',
        longitude: '1.03576310',
        type: 'metropolitan department',
      },
      {
        id: 5015,
        name: 'Lot-et-Garonne',
        state_code: '47',
        latitude: '44.36873140',
        longitude: '-0.09161690',
        type: 'metropolitan department',
      },
      {
        id: 5016,
        name: 'Lozère',
        state_code: '48',
        latitude: '44.54227790',
        longitude: '2.92934590',
        type: 'metropolitan department',
      },
      {
        id: 5017,
        name: 'Maine-et-Loire',
        state_code: '49',
        latitude: '47.38900340',
        longitude: '-1.12025270',
        type: 'metropolitan department',
      },
      {
        id: 5018,
        name: 'Manche',
        state_code: '50',
        latitude: '49.08817340',
        longitude: '-2.46272090',
        type: 'metropolitan department',
      },
      {
        id: 5019,
        name: 'Marne',
        state_code: '51',
        latitude: '48.96107450',
        longitude: '3.65737670',
        type: 'metropolitan department',
      },
      {
        id: 4827,
        name: 'Martinique',
        state_code: '972',
        latitude: '14.64152800',
        longitude: '-61.02417400',
        type: 'overseas region',
      },
      {
        id: 5021,
        name: 'Mayenne',
        state_code: '53',
        latitude: '48.30668420',
        longitude: '-0.64901820',
        type: 'metropolitan department',
      },
      {
        id: 4797,
        name: 'Mayotte',
        state_code: '976',
        latitude: '-12.82750000',
        longitude: '45.16624400',
        type: 'overseas region',
      },
      {
        id: 5038,
        name: 'Métropole de Lyon',
        state_code: '69M',
        latitude: '45.74826290',
        longitude: '4.59584040',
        type: 'metropolitan department',
      },
      {
        id: 5022,
        name: 'Meurthe-et-Moselle',
        state_code: '54',
        latitude: '48.95566150',
        longitude: '5.71423500',
        type: 'metropolitan department',
      },
      {
        id: 5023,
        name: 'Meuse',
        state_code: '55',
        latitude: '49.01246200',
        longitude: '4.81087340',
        type: 'metropolitan department',
      },
      {
        id: 5024,
        name: 'Morbihan',
        state_code: '56',
        latitude: '47.74395180',
        longitude: '-3.44555240',
        type: 'metropolitan department',
      },
      {
        id: 5025,
        name: 'Moselle',
        state_code: '57',
        latitude: '49.02045660',
        longitude: '6.20553220',
        type: 'metropolitan department',
      },
      {
        id: 5026,
        name: 'Nièvre',
        state_code: '58',
        latitude: '47.11921640',
        longitude: '2.97797130',
        type: 'metropolitan department',
      },
      {
        id: 5027,
        name: 'Nord',
        state_code: '59',
        latitude: '50.52854770',
        longitude: '2.60007760',
        type: 'metropolitan department',
      },
      {
        id: 4804,
        name: 'Normandie',
        state_code: 'NOR',
        latitude: '48.87987040',
        longitude: '0.17125290',
        type: 'metropolitan region',
      },
      {
        id: 4795,
        name: 'Nouvelle-Aquitaine',
        state_code: 'NAQ',
        latitude: '45.70871820',
        longitude: '0.62689100',
        type: 'metropolitan region',
      },
      {
        id: 4799,
        name: 'Occitanie',
        state_code: 'OCC',
        latitude: '43.89272320',
        longitude: '3.28276250',
        type: 'metropolitan region',
      },
      {
        id: 5028,
        name: 'Oise',
        state_code: '60',
        latitude: '49.41173350',
        longitude: '1.86688250',
        type: 'metropolitan department',
      },
      {
        id: 5029,
        name: 'Orne',
        state_code: '61',
        latitude: '48.57576440',
        longitude: '-0.50242950',
        type: 'metropolitan department',
      },
      {
        id: 4816,
        name: 'Paris',
        state_code: '75C',
        latitude: '48.85661400',
        longitude: '2.35222190',
        type: 'metropolitan collectivity with special status',
      },
      {
        id: 5030,
        name: 'Pas-de-Calais',
        state_code: '62',
        latitude: '50.51446990',
        longitude: '1.81149800',
        type: 'metropolitan department',
      },
      {
        id: 4802,
        name: 'Pays-de-la-Loire',
        state_code: 'PDL',
        latitude: '47.76328360',
        longitude: '-0.32996870',
        type: 'metropolitan region',
      },
      {
        id: 4812,
        name: 'Provence-Alpes-Côte-d’Azur',
        state_code: 'PAC',
        latitude: '43.93516910',
        longitude: '6.06791940',
        type: 'metropolitan region',
      },
      {
        id: 5031,
        name: 'Puy-de-Dôme',
        state_code: '63',
        latitude: '45.77141850',
        longitude: '2.62626760',
        type: 'metropolitan department',
      },
      {
        id: 5032,
        name: 'Pyrénées-Atlantiques',
        state_code: '64',
        latitude: '43.18681700',
        longitude: '-1.44170710',
        type: 'metropolitan department',
      },
      {
        id: 5034,
        name: 'Pyrénées-Orientales',
        state_code: '66',
        latitude: '42.62541790',
        longitude: '1.88929580',
        type: 'metropolitan department',
      },
      {
        id: 5037,
        name: 'Rhône',
        state_code: '69',
        latitude: '44.93433000',
        longitude: '4.24093290',
        type: 'metropolitan department',
      },
      {
        id: 4821,
        name: 'Saint Pierre and Miquelon',
        state_code: 'PM',
        latitude: '46.88520000',
        longitude: '-56.31590000',
        type: 'overseas collectivity',
      },
      {
        id: 4794,
        name: 'Saint-Barthélemy',
        state_code: 'BL',
        latitude: '17.90051340',
        longitude: '-62.82058710',
        type: 'overseas collectivity',
      },
      {
        id: 4809,
        name: 'Saint-Martin',
        state_code: 'MF',
        latitude: '18.07082980',
        longitude: '-63.05008090',
        type: 'overseas collectivity',
      },
      {
        id: 5040,
        name: 'Saône-et-Loire',
        state_code: '71',
        latitude: '46.65548830',
        longitude: '3.98350500',
        type: 'metropolitan department',
      },
      {
        id: 5041,
        name: 'Sarthe',
        state_code: '72',
        latitude: '48.02627330',
        longitude: '-0.32613170',
        type: 'metropolitan department',
      },
      {
        id: 5042,
        name: 'Savoie',
        state_code: '73',
        latitude: '45.49469900',
        longitude: '5.84329840',
        type: 'metropolitan department',
      },
      {
        id: 5045,
        name: 'Seine-et-Marne',
        state_code: '77',
        latitude: '48.61853940',
        longitude: '2.41525610',
        type: 'metropolitan department',
      },
      {
        id: 5044,
        name: 'Seine-Maritime',
        state_code: '76',
        latitude: '49.66096810',
        longitude: '0.36775610',
        type: 'metropolitan department',
      },
      {
        id: 5061,
        name: 'Seine-Saint-Denis',
        state_code: '93',
        latitude: '48.90993180',
        longitude: '2.30573790',
        type: 'metropolitan department',
      },
      {
        id: 5048,
        name: 'Somme',
        state_code: '80',
        latitude: '49.96859220',
        longitude: '1.73106960',
        type: 'metropolitan department',
      },
      {
        id: 5049,
        name: 'Tarn',
        state_code: '81',
        latitude: '43.79149770',
        longitude: '1.67588930',
        type: 'metropolitan department',
      },
      {
        id: 5050,
        name: 'Tarn-et-Garonne',
        state_code: '82',
        latitude: '44.08089500',
        longitude: '1.08916570',
        type: 'metropolitan department',
      },
      {
        id: 5058,
        name: 'Territoire de Belfort',
        state_code: '90',
        latitude: '47.62930720',
        longitude: '6.66962000',
        type: 'metropolitan department',
      },
      {
        id: 5063,
        name: "Val-d'Oise",
        state_code: '95',
        latitude: '49.07518180',
        longitude: '1.82169140',
        type: 'metropolitan department',
      },
      {
        id: 5062,
        name: 'Val-de-Marne',
        state_code: '94',
        latitude: '48.77470040',
        longitude: '2.32210390',
        type: 'metropolitan department',
      },
      {
        id: 5051,
        name: 'Var',
        state_code: '83',
        latitude: '43.39507300',
        longitude: '5.73424170',
        type: 'metropolitan department',
      },
      {
        id: 5052,
        name: 'Vaucluse',
        state_code: '84',
        latitude: '44.04475000',
        longitude: '4.64277180',
        type: 'metropolitan department',
      },
      {
        id: 5053,
        name: 'Vendée',
        state_code: '85',
        latitude: '46.67541030',
        longitude: '-2.02983920',
        type: 'metropolitan department',
      },
      {
        id: 5054,
        name: 'Vienne',
        state_code: '86',
        latitude: '45.52213140',
        longitude: '4.84531360',
        type: 'metropolitan department',
      },
      {
        id: 5056,
        name: 'Vosges',
        state_code: '88',
        latitude: '48.16301730',
        longitude: '5.73556000',
        type: 'metropolitan department',
      },
      {
        id: 4810,
        name: 'Wallis and Futuna',
        state_code: 'WF',
        latitude: '-14.29380000',
        longitude: '-178.11650000',
        type: 'overseas collectivity',
      },
      {
        id: 5057,
        name: 'Yonne',
        state_code: '89',
        latitude: '47.85476140',
        longitude: '3.03394040',
        type: 'metropolitan department',
      },
      {
        id: 5046,
        name: 'Yvelines',
        state_code: '78',
        latitude: '48.76153010',
        longitude: '1.27729490',
        type: 'metropolitan department',
      },
    ],
  },
  Greece: {
    name: 'Greece',
    iso3: 'GRC',
    iso2: 'GR',
    numeric_code: '300',
    phone_code: '30',
    capital: 'Athens',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.gr',
    native: 'Ελλάδα',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Athens',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      kr: '그리스',
      br: 'Grécia',
      pt: 'Grécia',
      nl: 'Griekenland',
      hr: 'Grčka',
      fa: 'یونان',
      de: 'Griechenland',
      es: 'Grecia',
      fr: 'Grèce',
      ja: 'ギリシャ',
      it: 'Grecia',
      cn: '希腊',
    },
    latitude: '39.00000000',
    longitude: '22.00000000',
    emoji: '🇬🇷',
    emojiU: 'U+1F1EC U+1F1F7',
    states: [
      {
        id: 2116,
        name: 'Achaea Regional Unit',
        state_code: '13',
        latitude: '38.11587290',
        longitude: '21.95224910',
        type: null,
      },
      {
        id: 2123,
        name: 'Aetolia-Acarnania Regional Unit',
        state_code: '01',
        latitude: '38.70843860',
        longitude: '21.37989280',
        type: null,
      },
      {
        id: 2098,
        name: 'Arcadia Prefecture',
        state_code: '12',
        latitude: '37.55578250',
        longitude: '22.33377690',
        type: null,
      },
      {
        id: 2105,
        name: 'Argolis Regional Unit',
        state_code: '11',
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2122,
        name: 'Attica Region',
        state_code: 'I',
        latitude: '38.04575680',
        longitude: '23.85847370',
        type: null,
      },
      {
        id: 2126,
        name: 'Boeotia Regional Unit',
        state_code: '03',
        latitude: '38.36636640',
        longitude: '23.09650640',
        type: null,
      },
      {
        id: 2128,
        name: 'Central Greece Region',
        state_code: 'H',
        latitude: '38.60439840',
        longitude: '22.71521310',
        type: null,
      },
      {
        id: 2125,
        name: 'Central Macedonia',
        state_code: 'B',
        latitude: '40.62117300',
        longitude: '23.19180210',
        type: null,
      },
      {
        id: 2115,
        name: 'Chania Regional Unit',
        state_code: '94',
        latitude: '35.51382980',
        longitude: '24.01803670',
        type: null,
      },
      {
        id: 2124,
        name: 'Corfu Prefecture',
        state_code: '22',
        latitude: '39.62498380',
        longitude: '19.92234610',
        type: null,
      },
      {
        id: 2129,
        name: 'Corinthia Regional Unit',
        state_code: '15',
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2109,
        name: 'Crete Region',
        state_code: 'M',
        latitude: '35.24011700',
        longitude: '24.80926910',
        type: null,
      },
      {
        id: 2130,
        name: 'Drama Regional Unit',
        state_code: '52',
        latitude: '41.23400230',
        longitude: '24.23904980',
        type: null,
      },
      {
        id: 2120,
        name: 'East Attica Regional Unit',
        state_code: 'A2',
        latitude: '38.20540930',
        longitude: '23.85847370',
        type: null,
      },
      {
        id: 2117,
        name: 'East Macedonia and Thrace',
        state_code: 'A',
        latitude: '41.12951260',
        longitude: '24.88771910',
        type: null,
      },
      {
        id: 2110,
        name: 'Epirus Region',
        state_code: 'D',
        latitude: '39.57064130',
        longitude: '20.76428430',
        type: null,
      },
      {
        id: 2101,
        name: 'Euboea',
        state_code: '04',
        latitude: '38.52360360',
        longitude: '23.85847370',
        type: null,
      },
      {
        id: 2102,
        name: 'Grevena Prefecture',
        state_code: '51',
        latitude: '40.08376260',
        longitude: '21.42732990',
        type: null,
      },
      {
        id: 2099,
        name: 'Imathia Regional Unit',
        state_code: '53',
        latitude: '40.60600670',
        longitude: '22.14302150',
        type: null,
      },
      {
        id: 2113,
        name: 'Ioannina Regional Unit',
        state_code: '33',
        latitude: '39.66502880',
        longitude: '20.85374660',
        type: null,
      },
      {
        id: 2131,
        name: 'Ionian Islands Region',
        state_code: 'F',
        latitude: '37.96948980',
        longitude: '21.38023720',
        type: null,
      },
      {
        id: 2095,
        name: 'Karditsa Regional Unit',
        state_code: '41',
        latitude: '39.36402580',
        longitude: '21.92140490',
        type: null,
      },
      {
        id: 2100,
        name: 'Kastoria Regional Unit',
        state_code: '56',
        latitude: '40.51926910',
        longitude: '21.26871710',
        type: null,
      },
      {
        id: 2127,
        name: 'Kefalonia Prefecture',
        state_code: '23',
        latitude: '38.17536750',
        longitude: '20.56921790',
        type: null,
      },
      {
        id: 2111,
        name: 'Kilkis Regional Unit',
        state_code: '57',
        latitude: '40.99370710',
        longitude: '22.87536740',
        type: null,
      },
      {
        id: 2112,
        name: 'Kozani Prefecture',
        state_code: '58',
        latitude: '40.30055860',
        longitude: '21.78877370',
        type: null,
      },
      {
        id: 2106,
        name: 'Laconia',
        state_code: '16',
        latitude: '43.52785460',
        longitude: '-71.47035090',
        type: null,
      },
      {
        id: 2132,
        name: 'Larissa Prefecture',
        state_code: '42',
        latitude: '39.63902240',
        longitude: '22.41912540',
        type: null,
      },
      {
        id: 2104,
        name: 'Lefkada Regional Unit',
        state_code: '24',
        latitude: '38.83336630',
        longitude: '20.70691080',
        type: null,
      },
      {
        id: 2107,
        name: 'Pella Regional Unit',
        state_code: '59',
        latitude: '40.91480390',
        longitude: '22.14302150',
        type: null,
      },
      {
        id: 2119,
        name: 'Peloponnese Region',
        state_code: 'J',
        latitude: '37.50794720',
        longitude: '22.37349000',
        type: null,
      },
      {
        id: 2114,
        name: 'Phthiotis Prefecture',
        state_code: '06',
        latitude: '38.99978500',
        longitude: '22.33377690',
        type: null,
      },
      {
        id: 2103,
        name: 'Preveza Prefecture',
        state_code: '34',
        latitude: '38.95926490',
        longitude: '20.75171550',
        type: null,
      },
      {
        id: 2121,
        name: 'Serres Prefecture',
        state_code: '62',
        latitude: '41.08638540',
        longitude: '23.54838190',
        type: null,
      },
      {
        id: 2118,
        name: 'South Aegean',
        state_code: 'L',
        latitude: '37.08553020',
        longitude: '25.14892150',
        type: null,
      },
      {
        id: 2097,
        name: 'Thessaloniki Regional Unit',
        state_code: '54',
        latitude: '40.64006290',
        longitude: '22.94441910',
        type: null,
      },
      {
        id: 2096,
        name: 'West Greece Region',
        state_code: 'G',
        latitude: '38.51154960',
        longitude: '21.57067860',
        type: null,
      },
      {
        id: 2108,
        name: 'West Macedonia Region',
        state_code: 'C',
        latitude: '40.30040580',
        longitude: '21.79035590',
        type: null,
      },
    ],
  },
  Italy: {
    name: 'Italy',
    iso3: 'ITA',
    iso2: 'IT',
    numeric_code: '380',
    phone_code: '39',
    capital: 'Rome',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.it',
    native: 'Italia',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Rome',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      kr: '이탈리아',
      br: 'Itália',
      pt: 'Itália',
      nl: 'Italië',
      hr: 'Italija',
      fa: 'ایتالیا',
      de: 'Italien',
      es: 'Italia',
      fr: 'Italie',
      ja: 'イタリア',
      it: 'Italia',
      cn: '意大利',
    },
    latitude: '42.83333333',
    longitude: '12.83333333',
    emoji: '🇮🇹',
    emojiU: 'U+1F1EE U+1F1F9',
    states: [
      {
        id: 1679,
        name: 'Abruzzo',
        state_code: '65',
        latitude: '42.19201190',
        longitude: '13.72891670',
        type: 'region',
      },
      {
        id: 1716,
        name: 'Aosta Valley',
        state_code: '23',
        latitude: '45.73888780',
        longitude: '7.42618660',
        type: 'autonomous region',
      },
      {
        id: 1688,
        name: 'Apulia',
        state_code: '75',
        latitude: '40.79283930',
        longitude: '17.10119310',
        type: 'region',
      },
      {
        id: 1706,
        name: 'Basilicata',
        state_code: '77',
        latitude: '40.64307660',
        longitude: '15.96998780',
        type: 'region',
      },
      {
        id: 1701,
        name: 'Benevento Province',
        state_code: 'BN',
        latitude: '41.20350930',
        longitude: '14.75209390',
        type: 'province',
      },
      {
        id: 1703,
        name: 'Calabria',
        state_code: '78',
        latitude: '39.30877140',
        longitude: '16.34637910',
        type: 'region',
      },
      {
        id: 1669,
        name: 'Campania',
        state_code: '72',
        latitude: '40.66708870',
        longitude: '15.10681130',
        type: 'region',
      },
      {
        id: 1773,
        name: 'Emilia-Romagna',
        state_code: '45',
        latitude: '44.59676070',
        longitude: '11.21863960',
        type: 'region',
      },
      {
        id: 1756,
        name: 'Friuli–Venezia Giulia',
        state_code: '36',
        latitude: '46.22591770',
        longitude: '13.10336460',
        type: 'autonomous region',
      },
      {
        id: 1678,
        name: 'Lazio',
        state_code: '62',
        latitude: '45.69916670',
        longitude: '-73.65583330',
        type: 'region',
      },
      {
        id: 1727,
        name: 'Libero consorzio comunale di Agrigento',
        state_code: 'AG',
        latitude: '37.31052020',
        longitude: '13.58579780',
        type: 'free municipal consortium',
      },
      {
        id: 1718,
        name: 'Libero consorzio comunale di Caltanissetta',
        state_code: 'CL',
        latitude: '37.48601300',
        longitude: '14.06149820',
        type: 'free municipal consortium',
      },
      {
        id: 1723,
        name: 'Libero consorzio comunale di Enna',
        state_code: 'EN',
        latitude: '37.56762160',
        longitude: '14.27953490',
        type: 'free municipal consortium',
      },
      {
        id: 1729,
        name: 'Libero consorzio comunale di Ragusa',
        state_code: 'RG',
        latitude: '36.92692730',
        longitude: '14.72551290',
        type: 'free municipal consortium',
      },
      {
        id: 1667,
        name: 'Libero consorzio comunale di Siracusa',
        state_code: 'SR',
        latitude: '37.06569240',
        longitude: '15.28571090',
        type: 'free municipal consortium',
      },
      {
        id: 1733,
        name: 'Libero consorzio comunale di Trapani',
        state_code: 'TP',
        latitude: '38.01831160',
        longitude: '12.51482650',
        type: 'free municipal consortium',
      },
      {
        id: 1768,
        name: 'Liguria',
        state_code: '42',
        latitude: '44.31679170',
        longitude: '8.39649380',
        type: 'region',
      },
      {
        id: 1705,
        name: 'Lombardy',
        state_code: '25',
        latitude: '45.47906710',
        longitude: '9.84524330',
        type: 'region',
      },
      {
        id: 1670,
        name: 'Marche',
        state_code: '57',
        latitude: '30.55670700',
        longitude: '-81.44930300',
        type: 'region',
      },
      {
        id: 1772,
        name: 'Metropolitan City of Bari',
        state_code: 'BA',
        latitude: '41.11714320',
        longitude: '16.87187150',
        type: 'metropolitan city',
      },
      {
        id: 1684,
        name: 'Metropolitan City of Bologna',
        state_code: 'BO',
        latitude: '44.49488700',
        longitude: '11.34261620',
        type: 'metropolitan city',
      },
      {
        id: 1682,
        name: 'Metropolitan City of Cagliari',
        state_code: 'CA',
        latitude: '39.22384110',
        longitude: '9.12166130',
        type: 'metropolitan city',
      },
      {
        id: 1766,
        name: 'Metropolitan City of Catania',
        state_code: 'CT',
        latitude: '37.45154380',
        longitude: '15.05574150',
        type: 'metropolitan city',
      },
      {
        id: 1680,
        name: 'Metropolitan City of Florence',
        state_code: 'FI',
        latitude: '43.76791780',
        longitude: '11.25237920',
        type: 'metropolitan city',
      },
      {
        id: 1699,
        name: 'Metropolitan City of Genoa',
        state_code: 'GE',
        latitude: '44.40564930',
        longitude: '8.94625640',
        type: 'metropolitan city',
      },
      {
        id: 1770,
        name: 'Metropolitan City of Messina',
        state_code: 'ME',
        latitude: '38.19373350',
        longitude: '15.55420570',
        type: 'metropolitan city',
      },
      {
        id: 1698,
        name: 'Metropolitan City of Milan',
        state_code: 'MI',
        latitude: '45.45862600',
        longitude: '9.18187300',
        type: 'metropolitan city',
      },
      {
        id: 1724,
        name: 'Metropolitan City of Naples',
        state_code: 'NA',
        latitude: '40.90197500',
        longitude: '14.33264400',
        type: 'metropolitan city',
      },
      {
        id: 1668,
        name: 'Metropolitan City of Palermo',
        state_code: 'PA',
        latitude: '38.11569000',
        longitude: '13.36148680',
        type: 'province',
      },
      {
        id: 1671,
        name: 'Metropolitan City of Reggio Calabria',
        state_code: 'RC',
        latitude: '38.10843960',
        longitude: '15.64370480',
        type: 'metropolitan city',
      },
      {
        id: 1711,
        name: 'Metropolitan City of Rome',
        state_code: 'RM',
        latitude: '41.90270080',
        longitude: '12.49623520',
        type: 'metropolitan city',
      },
      {
        id: 1710,
        name: 'Metropolitan City of Turin',
        state_code: 'TO',
        latitude: '45.06329900',
        longitude: '7.66928900',
        type: 'metropolitan city',
      },
      {
        id: 1673,
        name: 'Metropolitan City of Venice',
        state_code: 'VE',
        latitude: '45.44146850',
        longitude: '12.31526720',
        type: 'metropolitan city',
      },
      {
        id: 1695,
        name: 'Molise',
        state_code: '67',
        latitude: '41.67388650',
        longitude: '14.75209390',
        type: 'region',
      },
      {
        id: 1693,
        name: 'Pesaro and Urbino Province',
        state_code: 'PU',
        latitude: '43.61301180',
        longitude: '12.71351210',
        type: 'province',
      },
      {
        id: 1702,
        name: 'Piedmont',
        state_code: '21',
        latitude: '45.05223660',
        longitude: '7.51538850',
        type: 'region',
      },
      {
        id: 1783,
        name: 'Province of Alessandria',
        state_code: 'AL',
        latitude: '44.81755870',
        longitude: '8.70466270',
        type: 'province',
      },
      {
        id: 1672,
        name: 'Province of Ancona',
        state_code: 'AN',
        latitude: '43.54932450',
        longitude: '13.26634790',
        type: 'province',
      },
      {
        id: 1681,
        name: 'Province of Ascoli Piceno',
        state_code: 'AP',
        latitude: '42.86389330',
        longitude: '13.58997330',
        type: 'province',
      },
      {
        id: 1780,
        name: 'Province of Asti',
        state_code: 'AT',
        latitude: '44.90076520',
        longitude: '8.20643150',
        type: 'province',
      },
      {
        id: 1692,
        name: 'Province of Avellino',
        state_code: 'AV',
        latitude: '40.99645100',
        longitude: '15.12589550',
        type: 'province',
      },
      {
        id: 1686,
        name: 'Province of Barletta-Andria-Trani',
        state_code: 'BT',
        latitude: '41.20045430',
        longitude: '16.20514840',
        type: 'province',
      },
      {
        id: 1689,
        name: 'Province of Belluno',
        state_code: 'BL',
        latitude: '46.24976590',
        longitude: '12.19695650',
        type: 'province',
      },
      {
        id: 1704,
        name: 'Province of Bergamo',
        state_code: 'BG',
        latitude: '45.69826420',
        longitude: '9.67726980',
        type: 'province',
      },
      {
        id: 1778,
        name: 'Province of Biella',
        state_code: 'BI',
        latitude: '45.56281760',
        longitude: '8.05827170',
        type: 'province',
      },
      {
        id: 1717,
        name: 'Province of Brescia',
        state_code: 'BS',
        latitude: '45.54155260',
        longitude: '10.21180190',
        type: 'province',
      },
      {
        id: 1714,
        name: 'Province of Brindisi',
        state_code: 'BR',
        latitude: '40.61126630',
        longitude: '17.76362100',
        type: 'province',
      },
      {
        id: 1721,
        name: 'Province of Campobasso',
        state_code: 'CB',
        latitude: '41.67388650',
        longitude: '14.75209390',
        type: 'province',
      },
      {
        id: 1730,
        name: 'Province of Carbonia-Iglesias',
        state_code: 'CI',
        latitude: '39.25346590',
        longitude: '8.57210160',
        type: 'province',
      },
      {
        id: 1731,
        name: 'Province of Caserta',
        state_code: 'CE',
        latitude: '41.20783540',
        longitude: '14.10013260',
        type: 'province',
      },
      {
        id: 1728,
        name: 'Province of Catanzaro',
        state_code: 'CZ',
        latitude: '38.88963480',
        longitude: '16.44058720',
        type: 'province',
      },
      {
        id: 1739,
        name: 'Province of Chieti',
        state_code: 'CH',
        latitude: '42.03344280',
        longitude: '14.37919120',
        type: 'province',
      },
      {
        id: 1740,
        name: 'Province of Como',
        state_code: 'CO',
        latitude: '45.80804160',
        longitude: '9.08517930',
        type: 'province',
      },
      {
        id: 1742,
        name: 'Province of Cosenza',
        state_code: 'CS',
        latitude: '39.56441050',
        longitude: '16.25221430',
        type: 'province',
      },
      {
        id: 1751,
        name: 'Province of Cremona',
        state_code: 'CR',
        latitude: '45.20143750',
        longitude: '9.98365820',
        type: 'province',
      },
      {
        id: 1754,
        name: 'Province of Crotone',
        state_code: 'KR',
        latitude: '39.13098560',
        longitude: '17.00670310',
        type: 'province',
      },
      {
        id: 1775,
        name: 'Province of Cuneo',
        state_code: 'CN',
        latitude: '44.59703140',
        longitude: '7.61142170',
        type: 'province',
      },
      {
        id: 1744,
        name: 'Province of Fermo',
        state_code: 'FM',
        latitude: '43.09313670',
        longitude: '13.58997330',
        type: 'province',
      },
      {
        id: 1746,
        name: 'Province of Ferrara',
        state_code: 'FE',
        latitude: '44.76636800',
        longitude: '11.76440680',
        type: 'province',
      },
      {
        id: 1771,
        name: 'Province of Foggia',
        state_code: 'FG',
        latitude: '41.63844800',
        longitude: '15.59433880',
        type: 'province',
      },
      {
        id: 1779,
        name: 'Province of Forlì-Cesena',
        state_code: 'FC',
        latitude: '43.99476810',
        longitude: '11.98046130',
        type: 'province',
      },
      {
        id: 1776,
        name: 'Province of Frosinone',
        state_code: 'FR',
        latitude: '41.65765280',
        longitude: '13.63627150',
        type: 'province',
      },
      {
        id: 1777,
        name: 'Province of Gorizia',
        state_code: 'GO',
        latitude: '45.90538990',
        longitude: '13.51637250',
        type: 'decentralized regional entity',
      },
      {
        id: 1787,
        name: 'Province of Grosseto',
        state_code: 'GR',
        latitude: '42.85180070',
        longitude: '11.25237920',
        type: 'province',
      },
      {
        id: 1788,
        name: 'Province of Imperia',
        state_code: 'IM',
        latitude: '43.94186600',
        longitude: '7.82863680',
        type: 'province',
      },
      {
        id: 1789,
        name: 'Province of Isernia',
        state_code: 'IS',
        latitude: '41.58915550',
        longitude: '14.19309180',
        type: 'province',
      },
      {
        id: 1781,
        name: "Province of L'Aquila",
        state_code: 'AQ',
        latitude: '42.12563170',
        longitude: '13.63627150',
        type: 'province',
      },
      {
        id: 1791,
        name: 'Province of La Spezia',
        state_code: 'SP',
        latitude: '44.24479130',
        longitude: '9.76786870',
        type: 'province',
      },
      {
        id: 1674,
        name: 'Province of Latina',
        state_code: 'LT',
        latitude: '41.40874760',
        longitude: '13.08179030',
        type: 'province',
      },
      {
        id: 1675,
        name: 'Province of Lecce',
        state_code: 'LE',
        latitude: '40.23473930',
        longitude: '18.14286690',
        type: 'province',
      },
      {
        id: 1677,
        name: 'Province of Lecco',
        state_code: 'LC',
        latitude: '45.93829410',
        longitude: '9.38572900',
        type: 'province',
      },
      {
        id: 1745,
        name: 'Province of Livorno',
        state_code: 'LI',
        latitude: '43.02398480',
        longitude: '10.66471010',
        type: 'province',
      },
      {
        id: 1747,
        name: 'Province of Lodi',
        state_code: 'LO',
        latitude: '45.24050360',
        longitude: '9.52925120',
        type: 'province',
      },
      {
        id: 1749,
        name: 'Province of Lucca',
        state_code: 'LU',
        latitude: '43.83767360',
        longitude: '10.49505300',
        type: 'province',
      },
      {
        id: 1750,
        name: 'Province of Macerata',
        state_code: 'MC',
        latitude: '43.24593220',
        longitude: '13.26634790',
        type: 'province',
      },
      {
        id: 1758,
        name: 'Province of Mantua',
        state_code: 'MN',
        latitude: '45.16677280',
        longitude: '10.77536130',
        type: 'province',
      },
      {
        id: 1759,
        name: 'Province of Massa and Carrara',
        state_code: 'MS',
        latitude: '44.22139980',
        longitude: '10.03596610',
        type: 'province',
      },
      {
        id: 1760,
        name: 'Province of Matera',
        state_code: 'MT',
        latitude: '40.66634960',
        longitude: '16.60436360',
        type: 'province',
      },
      {
        id: 1761,
        name: 'Province of Medio Campidano',
        state_code: 'VS',
        latitude: '39.53173890',
        longitude: '8.70407500',
        type: 'province',
      },
      {
        id: 1757,
        name: 'Province of Modena',
        state_code: 'MO',
        latitude: '44.55137990',
        longitude: '10.91804800',
        type: 'province',
      },
      {
        id: 1769,
        name: 'Province of Monza and Brianza',
        state_code: 'MB',
        latitude: '45.62359900',
        longitude: '9.25880150',
        type: 'province',
      },
      {
        id: 1774,
        name: 'Province of Novara',
        state_code: 'NO',
        latitude: '45.54851330',
        longitude: '8.51507930',
        type: 'province',
      },
      {
        id: 1790,
        name: 'Province of Nuoro',
        state_code: 'NU',
        latitude: '40.32869040',
        longitude: '9.45615500',
        type: 'province',
      },
      {
        id: 1782,
        name: 'Province of Ogliastra',
        state_code: 'OG',
        latitude: '39.84105360',
        longitude: '9.45615500',
        type: 'province',
      },
      {
        id: 1784,
        name: 'Province of Olbia-Tempio',
        state_code: 'OT',
        latitude: '40.82683830',
        longitude: '9.27855830',
        type: 'metropolitan city',
      },
      {
        id: 1786,
        name: 'Province of Oristano',
        state_code: 'OR',
        latitude: '40.05990680',
        longitude: '8.74811670',
        type: 'province',
      },
      {
        id: 1665,
        name: 'Province of Padua',
        state_code: 'PD',
        latitude: '45.36618640',
        longitude: '11.82091390',
        type: 'province',
      },
      {
        id: 1666,
        name: 'Province of Parma',
        state_code: 'PR',
        latitude: '44.80153220',
        longitude: '10.32793540',
        type: 'province',
      },
      {
        id: 1676,
        name: 'Province of Pavia',
        state_code: 'PV',
        latitude: '45.32181660',
        longitude: '8.84662360',
        type: 'province',
      },
      {
        id: 1691,
        name: 'Province of Perugia',
        state_code: 'PG',
        latitude: '42.93800400',
        longitude: '12.62162110',
        type: 'province',
      },
      {
        id: 1694,
        name: 'Province of Pescara',
        state_code: 'PE',
        latitude: '42.35706550',
        longitude: '13.96080910',
        type: 'province',
      },
      {
        id: 1696,
        name: 'Province of Piacenza',
        state_code: 'PC',
        latitude: '44.82631120',
        longitude: '9.52914470',
        type: 'province',
      },
      {
        id: 1685,
        name: 'Province of Pisa',
        state_code: 'PI',
        latitude: '43.72283150',
        longitude: '10.40171940',
        type: 'province',
      },
      {
        id: 1687,
        name: 'Province of Pistoia',
        state_code: 'PT',
        latitude: '43.95437330',
        longitude: '10.89030990',
        type: 'province',
      },
      {
        id: 1690,
        name: 'Province of Pordenone',
        state_code: 'PN',
        latitude: '46.03788620',
        longitude: '12.71083500',
        type: 'decentralized regional entity',
      },
      {
        id: 1697,
        name: 'Province of Potenza',
        state_code: 'PZ',
        latitude: '40.41821940',
        longitude: '15.87600400',
        type: 'province',
      },
      {
        id: 1700,
        name: 'Province of Prato',
        state_code: 'PO',
        latitude: '44.04539000',
        longitude: '11.11644520',
        type: 'province',
      },
      {
        id: 1707,
        name: 'Province of Ravenna',
        state_code: 'RA',
        latitude: '44.41844430',
        longitude: '12.20359980',
        type: 'province',
      },
      {
        id: 1708,
        name: 'Province of Reggio Emilia',
        state_code: 'RE',
        latitude: '44.58565800',
        longitude: '10.55647360',
        type: 'province',
      },
      {
        id: 1712,
        name: 'Province of Rieti',
        state_code: 'RI',
        latitude: '42.36744050',
        longitude: '12.89750980',
        type: 'province',
      },
      {
        id: 1713,
        name: 'Province of Rimini',
        state_code: 'RN',
        latitude: '44.06782880',
        longitude: '12.56951580',
        type: 'province',
      },
      {
        id: 1719,
        name: 'Province of Rovigo',
        state_code: 'RO',
        latitude: '45.02418180',
        longitude: '11.82381620',
        type: 'province',
      },
      {
        id: 1720,
        name: 'Province of Salerno',
        state_code: 'SA',
        latitude: '40.42878320',
        longitude: '15.21948080',
        type: 'province',
      },
      {
        id: 1722,
        name: 'Province of Sassari',
        state_code: 'SS',
        latitude: '40.79679070',
        longitude: '8.57504070',
        type: 'province',
      },
      {
        id: 1732,
        name: 'Province of Savona',
        state_code: 'SV',
        latitude: '44.28879950',
        longitude: '8.26505800',
        type: 'province',
      },
      {
        id: 1734,
        name: 'Province of Siena',
        state_code: 'SI',
        latitude: '43.29377320',
        longitude: '11.43391480',
        type: 'province',
      },
      {
        id: 1741,
        name: 'Province of Sondrio',
        state_code: 'SO',
        latitude: '46.17276360',
        longitude: '9.79949170',
        type: 'province',
      },
      {
        id: 1743,
        name: 'Province of Taranto',
        state_code: 'TA',
        latitude: '40.57409010',
        longitude: '17.24299760',
        type: 'province',
      },
      {
        id: 1752,
        name: 'Province of Teramo',
        state_code: 'TE',
        latitude: '42.58956080',
        longitude: '13.63627150',
        type: 'province',
      },
      {
        id: 1755,
        name: 'Province of Terni',
        state_code: 'TR',
        latitude: '42.56345340',
        longitude: '12.52980280',
        type: 'province',
      },
      {
        id: 1762,
        name: 'Province of Treviso',
        state_code: 'TV',
        latitude: '45.66685170',
        longitude: '12.24306170',
        type: 'province',
      },
      {
        id: 1763,
        name: 'Province of Trieste',
        state_code: 'TS',
        latitude: '45.68948230',
        longitude: '13.78330720',
        type: 'decentralized regional entity',
      },
      {
        id: 1764,
        name: 'Province of Udine',
        state_code: 'UD',
        latitude: '46.14079720',
        longitude: '13.16628960',
        type: 'decentralized regional entity',
      },
      {
        id: 1765,
        name: 'Province of Varese',
        state_code: 'VA',
        latitude: '45.79902600',
        longitude: '8.73009450',
        type: 'province',
      },
      {
        id: 1726,
        name: 'Province of Verbano-Cusio-Ossola',
        state_code: 'VB',
        latitude: '46.13996880',
        longitude: '8.27246490',
        type: 'province',
      },
      {
        id: 1785,
        name: 'Province of Vercelli',
        state_code: 'VC',
        latitude: '45.32022040',
        longitude: '8.41850800',
        type: 'province',
      },
      {
        id: 1736,
        name: 'Province of Verona',
        state_code: 'VR',
        latitude: '45.44184980',
        longitude: '11.07353160',
        type: 'province',
      },
      {
        id: 1737,
        name: 'Province of Vibo Valentia',
        state_code: 'VV',
        latitude: '38.63785650',
        longitude: '16.20514840',
        type: 'province',
      },
      {
        id: 1738,
        name: 'Province of Vicenza',
        state_code: 'VI',
        latitude: '45.69839950',
        longitude: '11.56614650',
        type: 'province',
      },
      {
        id: 1735,
        name: 'Province of Viterbo',
        state_code: 'VT',
        latitude: '42.40024200',
        longitude: '11.88917210',
        type: 'province',
      },
      {
        id: 1715,
        name: 'Sardinia',
        state_code: '88',
        latitude: '40.12087520',
        longitude: '9.01289260',
        type: 'autonomous region',
      },
      {
        id: 1709,
        name: 'Sicily',
        state_code: '82',
        latitude: '37.59999380',
        longitude: '14.01535570',
        type: 'autonomous region',
      },
      {
        id: 1767,
        name: 'South Tyrol',
        state_code: 'BZ',
        latitude: '46.49494500',
        longitude: '11.34026570',
        type: 'autonomous province',
      },
      {
        id: 1748,
        name: 'Trentino',
        state_code: 'TN',
        latitude: '46.05120040',
        longitude: '11.11753920',
        type: 'autonomous province',
      },
      {
        id: 1725,
        name: 'Trentino-South Tyrol',
        state_code: '32',
        latitude: '46.43366620',
        longitude: '11.16932960',
        type: 'autonomous region',
      },
      {
        id: 1664,
        name: 'Tuscany',
        state_code: '52',
        latitude: '43.77105130',
        longitude: '11.24862080',
        type: 'region',
      },
      {
        id: 1683,
        name: 'Umbria',
        state_code: '55',
        latitude: '42.93800400',
        longitude: '12.62162110',
        type: 'region',
      },
      {
        id: 1753,
        name: 'Veneto',
        state_code: '34',
        latitude: '45.44146620',
        longitude: '12.31525950',
        type: 'region',
      },
    ],
  },
  Lebanon: {
    name: 'Lebanon',
    iso3: 'LBN',
    iso2: 'LB',
    numeric_code: '422',
    phone_code: '961',
    capital: 'Beirut',
    currency: 'LBP',
    currency_name: 'Lebanese pound',
    currency_symbol: '£',
    tld: '.lb',
    native: 'لبنان',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Beirut',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      kr: '레바논',
      br: 'Líbano',
      pt: 'Líbano',
      nl: 'Libanon',
      hr: 'Libanon',
      fa: 'لبنان',
      de: 'Libanon',
      es: 'Líbano',
      fr: 'Liban',
      ja: 'レバノン',
      it: 'Libano',
      cn: '黎巴嫩',
    },
    latitude: '33.83333333',
    longitude: '35.83333333',
    emoji: '🇱🇧',
    emojiU: 'U+1F1F1 U+1F1E7',
    states: [
      {
        id: 2285,
        name: 'Akkar Governorate',
        state_code: 'AK',
        latitude: '34.53287630',
        longitude: '36.13281320',
        type: null,
      },
      {
        id: 2283,
        name: 'Baalbek-Hermel Governorate',
        state_code: 'BH',
        latitude: '34.26585560',
        longitude: '36.34980970',
        type: null,
      },
      {
        id: 2286,
        name: 'Beirut Governorate',
        state_code: 'BA',
        latitude: '33.88861060',
        longitude: '35.49547720',
        type: null,
      },
      {
        id: 2287,
        name: 'Beqaa Governorate',
        state_code: 'BI',
        latitude: '33.84626620',
        longitude: '35.90194890',
        type: null,
      },
      {
        id: 2282,
        name: 'Mount Lebanon Governorate',
        state_code: 'JL',
        latitude: '33.81008580',
        longitude: '35.59731390',
        type: null,
      },
      {
        id: 2288,
        name: 'Nabatieh Governorate',
        state_code: 'NA',
        latitude: '33.37716930',
        longitude: '35.48382930',
        type: null,
      },
      {
        id: 2284,
        name: 'North Governorate',
        state_code: 'AS',
        latitude: '34.43806250',
        longitude: '35.83082330',
        type: null,
      },
      {
        id: 2281,
        name: 'South Governorate',
        state_code: 'JA',
        latitude: '33.27214790',
        longitude: '35.20327780',
        type: null,
      },
    ],
  },
  Portugal: {
    name: 'Portugal',
    iso3: 'PRT',
    iso2: 'PT',
    numeric_code: '620',
    phone_code: '351',
    capital: 'Lisbon',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.pt',
    native: 'Portugal',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Atlantic/Azores',
        gmtOffset: -3600,
        gmtOffsetName: 'UTC-01:00',
        abbreviation: 'AZOT',
        tzName: 'Azores Standard Time',
      },
      {
        zoneName: 'Atlantic/Madeira',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
      {
        zoneName: 'Europe/Lisbon',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
    ],
    translations: {
      kr: '포르투갈',
      br: 'Portugal',
      pt: 'Portugal',
      nl: 'Portugal',
      hr: 'Portugal',
      fa: 'پرتغال',
      de: 'Portugal',
      es: 'Portugal',
      fr: 'Portugal',
      ja: 'ポルトガル',
      it: 'Portogallo',
      cn: '葡萄牙',
    },
    latitude: '39.50000000',
    longitude: '-8.00000000',
    emoji: '🇵🇹',
    emojiU: 'U+1F1F5 U+1F1F9',
    states: [
      {
        id: 2233,
        name: 'Açores',
        state_code: '20',
        latitude: '37.74124880',
        longitude: '-25.67559440',
        type: null,
      },
      {
        id: 2235,
        name: 'Aveiro',
        state_code: '01',
        latitude: '40.72090230',
        longitude: '-8.57210160',
        type: null,
      },
      {
        id: 2230,
        name: 'Beja',
        state_code: '02',
        latitude: '37.96877860',
        longitude: '-7.87216000',
        type: null,
      },
      {
        id: 2244,
        name: 'Braga',
        state_code: '03',
        latitude: '41.55038800',
        longitude: '-8.42613010',
        type: null,
      },
      {
        id: 2229,
        name: 'Bragança',
        state_code: '04',
        latitude: '41.80616520',
        longitude: '-6.75674270',
        type: null,
      },
      {
        id: 2241,
        name: 'Castelo Branco',
        state_code: '05',
        latitude: '39.86313230',
        longitude: '-7.48141630',
        type: null,
      },
      {
        id: 2246,
        name: 'Coimbra',
        state_code: '06',
        latitude: '40.20579940',
        longitude: '-8.41369000',
        type: null,
      },
      {
        id: 2236,
        name: 'Évora',
        state_code: '07',
        latitude: '38.57444680',
        longitude: '-7.90765530',
        type: null,
      },
      {
        id: 2239,
        name: 'Faro',
        state_code: '08',
        latitude: '37.01935480',
        longitude: '-7.93043970',
        type: null,
      },
      {
        id: 4859,
        name: 'Guarda',
        state_code: '09',
        latitude: '40.53859720',
        longitude: '-7.26757720',
        type: null,
      },
      {
        id: 2240,
        name: 'Leiria',
        state_code: '10',
        latitude: '39.77095320',
        longitude: '-8.79218360',
        type: null,
      },
      {
        id: 2228,
        name: 'Lisbon',
        state_code: '11',
        latitude: '38.72232630',
        longitude: '-9.13927140',
        type: null,
      },
      {
        id: 2231,
        name: 'Madeira',
        state_code: '30',
        latitude: '32.76070740',
        longitude: '-16.95947230',
        type: null,
      },
      {
        id: 2232,
        name: 'Portalegre',
        state_code: '12',
        latitude: '39.29670860',
        longitude: '-7.42847550',
        type: null,
      },
      {
        id: 2243,
        name: 'Porto',
        state_code: '13',
        latitude: '41.14766290',
        longitude: '-8.60789730',
        type: null,
      },
      {
        id: 2238,
        name: 'Santarém',
        state_code: '14',
        latitude: '39.23666870',
        longitude: '-8.68599440',
        type: null,
      },
      {
        id: 2242,
        name: 'Setúbal',
        state_code: '15',
        latitude: '38.52409330',
        longitude: '-8.89258760',
        type: null,
      },
      {
        id: 2245,
        name: 'Viana do Castelo',
        state_code: '16',
        latitude: '41.69180460',
        longitude: '-8.83445100',
        type: null,
      },
      {
        id: 2234,
        name: 'Vila Real',
        state_code: '17',
        latitude: '41.30035270',
        longitude: '-7.74572740',
        type: null,
      },
      {
        id: 2237,
        name: 'Viseu',
        state_code: '18',
        latitude: '40.65884240',
        longitude: '-7.91475600',
        type: null,
      },
    ],
  },
  Spain: {
    name: 'Spain',
    iso3: 'ESP',
    iso2: 'ES',
    numeric_code: '724',
    phone_code: '34',
    capital: 'Madrid',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    tld: '.es',
    native: 'España',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Africa/Ceuta',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
      {
        zoneName: 'Atlantic/Canary',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
      {
        zoneName: 'Europe/Madrid',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      kr: '스페인',
      br: 'Espanha',
      pt: 'Espanha',
      nl: 'Spanje',
      hr: 'Španjolska',
      fa: 'اسپانیا',
      de: 'Spanien',
      es: 'España',
      fr: 'Espagne',
      ja: 'スペイン',
      it: 'Spagna',
      cn: '西班牙',
    },
    latitude: '40.00000000',
    longitude: '-4.00000000',
    emoji: '🇪🇸',
    emojiU: 'U+1F1EA U+1F1F8',
    states: [
      {
        id: 1193,
        name: 'Andalusia',
        state_code: 'AN',
        latitude: '37.54427060',
        longitude: '-4.72775280',
        type: null,
      },
      {
        id: 1177,
        name: 'Aragon',
        state_code: 'AR',
        latitude: '41.59762750',
        longitude: '-0.90566230',
        type: null,
      },
      {
        id: 1160,
        name: 'Asturias',
        state_code: 'AS',
        latitude: '43.36139530',
        longitude: '-5.85932670',
        type: null,
      },
      {
        id: 1189,
        name: 'Ávila',
        state_code: 'AV',
        latitude: '40.69345110',
        longitude: '-4.89356270',
        type: null,
      },
      {
        id: 1174,
        name: 'Balearic Islands',
        state_code: 'PM',
        latitude: '39.35877590',
        longitude: '2.73563280',
        type: null,
      },
      {
        id: 1191,
        name: 'Basque Country',
        state_code: 'PV',
        latitude: '42.98962480',
        longitude: '-2.61892730',
        type: null,
      },
      {
        id: 1146,
        name: 'Burgos Province',
        state_code: 'BU',
        latitude: '42.33807580',
        longitude: '-3.58126920',
        type: null,
      },
      {
        id: 1185,
        name: 'Canary Islands',
        state_code: 'CN',
        latitude: '28.29156370',
        longitude: '-16.62913040',
        type: null,
      },
      {
        id: 1170,
        name: 'Cantabria',
        state_code: 'CB',
        latitude: '43.18283960',
        longitude: '-3.98784270',
        type: null,
      },
      {
        id: 1184,
        name: 'Castile and León',
        state_code: 'CL',
        latitude: '41.83568210',
        longitude: '-4.39763570',
        type: null,
      },
      {
        id: 1205,
        name: 'Castilla La Mancha',
        state_code: 'CM',
        latitude: '39.27956070',
        longitude: '-3.09770200',
        type: null,
      },
      {
        id: 1203,
        name: 'Catalonia',
        state_code: 'CT',
        latitude: '41.59115890',
        longitude: '1.52086240',
        type: null,
      },
      {
        id: 1206,
        name: 'Ceuta',
        state_code: 'CE',
        latitude: '35.88938740',
        longitude: '-5.32134550',
        type: null,
      },
      {
        id: 1190,
        name: 'Extremadura',
        state_code: 'EX',
        latitude: '39.49373920',
        longitude: '-6.06791940',
        type: null,
      },
      {
        id: 1167,
        name: 'Galicia',
        state_code: 'GA',
        latitude: '42.57505540',
        longitude: '-8.13385580',
        type: null,
      },
      {
        id: 1171,
        name: 'La Rioja',
        state_code: 'RI',
        latitude: '42.28707330',
        longitude: '-2.53960300',
        type: null,
      },
      {
        id: 1200,
        name: 'Léon',
        state_code: 'LE',
        latitude: '42.59870410',
        longitude: '-5.56708390',
        type: null,
      },
      {
        id: 1158,
        name: 'Madrid',
        state_code: 'MD',
        latitude: '40.41675150',
        longitude: '-3.70383220',
        type: null,
      },
      {
        id: 1159,
        name: 'Melilla',
        state_code: 'ML',
        latitude: '35.29227750',
        longitude: '-2.93809730',
        type: null,
      },
      {
        id: 1176,
        name: 'Murcia',
        state_code: 'MC',
        latitude: '38.13981410',
        longitude: '-1.36621600',
        type: null,
      },
      {
        id: 1204,
        name: 'Navarra',
        state_code: 'NC',
        latitude: '42.69539090',
        longitude: '-1.67606910',
        type: null,
      },
      {
        id: 1157,
        name: 'Palencia Province',
        state_code: 'P',
        latitude: '42.00968320',
        longitude: '-4.52879490',
        type: null,
      },
      {
        id: 1147,
        name: 'Salamanca Province',
        state_code: 'SA',
        latitude: '40.95152630',
        longitude: '-6.23759470',
        type: null,
      },
      {
        id: 1192,
        name: 'Segovia Province',
        state_code: 'SG',
        latitude: '40.94292960',
        longitude: '-4.10889420',
        type: null,
      },
      {
        id: 1208,
        name: 'Soria Province',
        state_code: 'SO',
        latitude: '41.76654640',
        longitude: '-2.47903060',
        type: null,
      },
      {
        id: 1175,
        name: 'Valencia',
        state_code: 'VC',
        latitude: '39.48401080',
        longitude: '-0.75328090',
        type: null,
      },
      {
        id: 1183,
        name: 'Valladolid Province',
        state_code: 'VA',
        latitude: '41.65173750',
        longitude: '-4.72449500',
        type: null,
      },
      {
        id: 1161,
        name: 'Zamora Province',
        state_code: 'ZA',
        latitude: '41.60957440',
        longitude: '-5.89871390',
        type: null,
      },
    ],
  },
  Turkey: {
    name: 'Turkey',
    iso3: 'TUR',
    iso2: 'TR',
    numeric_code: '792',
    phone_code: '90',
    capital: 'Ankara',
    currency: 'TRY',
    currency_name: 'Turkish lira',
    currency_symbol: '₺',
    tld: '.tr',
    native: 'Türkiye',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Europe/Istanbul',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      kr: '터키',
      br: 'Turquia',
      pt: 'Turquia',
      nl: 'Turkije',
      hr: 'Turska',
      fa: 'ترکیه',
      de: 'Türkei',
      es: 'Turquía',
      fr: 'Turquie',
      ja: 'トルコ',
      it: 'Turchia',
      cn: '土耳其',
    },
    latitude: '39.00000000',
    longitude: '35.00000000',
    emoji: '🇹🇷',
    emojiU: 'U+1F1F9 U+1F1F7',
    states: [
      {
        id: 2212,
        name: 'Adana',
        state_code: '01',
        latitude: '37.26123150',
        longitude: '35.39050460',
        type: 'province',
      },
      {
        id: 2155,
        name: 'Adıyaman',
        state_code: '02',
        latitude: '37.90782910',
        longitude: '38.48499230',
        type: 'province',
      },
      {
        id: 2179,
        name: 'Afyonkarahisar',
        state_code: '03',
        latitude: '38.73910990',
        longitude: '30.71200230',
        type: 'province',
      },
      {
        id: 2193,
        name: 'Ağrı',
        state_code: '04',
        latitude: '39.62692180',
        longitude: '43.02159650',
        type: 'province',
      },
      {
        id: 2210,
        name: 'Aksaray',
        state_code: '68',
        latitude: '38.33520430',
        longitude: '33.97500180',
        type: 'province',
      },
      {
        id: 2161,
        name: 'Amasya',
        state_code: '05',
        latitude: '40.65166080',
        longitude: '35.90379660',
        type: 'province',
      },
      {
        id: 2217,
        name: 'Ankara',
        state_code: '06',
        latitude: '39.78052450',
        longitude: '32.71813750',
        type: 'province',
      },
      {
        id: 2169,
        name: 'Antalya',
        state_code: '07',
        latitude: '37.09516720',
        longitude: '31.07937050',
        type: 'province',
      },
      {
        id: 2185,
        name: 'Ardahan',
        state_code: '75',
        latitude: '41.11129640',
        longitude: '42.78316740',
        type: 'province',
      },
      {
        id: 2191,
        name: 'Artvin',
        state_code: '08',
        latitude: '41.07866400',
        longitude: '41.76282230',
        type: 'province',
      },
      {
        id: 2187,
        name: 'Aydın',
        state_code: '09',
        latitude: '37.81170330',
        longitude: '28.48639630',
        type: 'province',
      },
      {
        id: 2175,
        name: 'Balıkesir',
        state_code: '10',
        latitude: '39.76167820',
        longitude: '28.11226790',
        type: 'province',
      },
      {
        id: 2148,
        name: 'Bartın',
        state_code: '74',
        latitude: '41.58105090',
        longitude: '32.46097940',
        type: 'province',
      },
      {
        id: 2194,
        name: 'Batman',
        state_code: '72',
        latitude: '37.83624960',
        longitude: '41.36057390',
        type: 'province',
      },
      {
        id: 2177,
        name: 'Bayburt',
        state_code: '69',
        latitude: '40.26032000',
        longitude: '40.22804800',
        type: 'province',
      },
      {
        id: 2221,
        name: 'Bilecik',
        state_code: '11',
        latitude: '40.05665550',
        longitude: '30.06652360',
        type: 'province',
      },
      {
        id: 2153,
        name: 'Bingöl',
        state_code: '12',
        latitude: '39.06263540',
        longitude: '40.76960950',
        type: 'province',
      },
      {
        id: 2215,
        name: 'Bitlis',
        state_code: '13',
        latitude: '38.65231330',
        longitude: '42.42020280',
        type: 'province',
      },
      {
        id: 2172,
        name: 'Bolu',
        state_code: '14',
        latitude: '40.57597660',
        longitude: '31.57880860',
        type: 'province',
      },
      {
        id: 2209,
        name: 'Burdur',
        state_code: '15',
        latitude: '37.46126690',
        longitude: '30.06652360',
        type: 'province',
      },
      {
        id: 2163,
        name: 'Bursa',
        state_code: '16',
        latitude: '40.06554590',
        longitude: '29.23207840',
        type: 'province',
      },
      {
        id: 2216,
        name: 'Çanakkale',
        state_code: '17',
        latitude: '40.05101040',
        longitude: '26.98524220',
        type: 'province',
      },
      {
        id: 2168,
        name: 'Çankırı',
        state_code: '18',
        latitude: '40.53690730',
        longitude: '33.58838930',
        type: 'province',
      },
      {
        id: 2173,
        name: 'Çorum',
        state_code: '19',
        latitude: '40.49982110',
        longitude: '34.59862630',
        type: 'province',
      },
      {
        id: 2157,
        name: 'Denizli',
        state_code: '20',
        latitude: '37.61283950',
        longitude: '29.23207840',
        type: 'province',
      },
      {
        id: 2226,
        name: 'Diyarbakır',
        state_code: '21',
        latitude: '38.10663720',
        longitude: '40.54268960',
        type: 'province',
      },
      {
        id: 2202,
        name: 'Düzce',
        state_code: '81',
        latitude: '40.87705310',
        longitude: '31.31927130',
        type: 'province',
      },
      {
        id: 2151,
        name: 'Edirne',
        state_code: '22',
        latitude: '41.15172220',
        longitude: '26.51379640',
        type: 'province',
      },
      {
        id: 2159,
        name: 'Elazığ',
        state_code: '23',
        latitude: '38.49648040',
        longitude: '39.21990290',
        type: 'province',
      },
      {
        id: 2160,
        name: 'Erzincan',
        state_code: '24',
        latitude: '39.76819140',
        longitude: '39.05013060',
        type: 'province',
      },
      {
        id: 2165,
        name: 'Erzurum',
        state_code: '25',
        latitude: '40.07467990',
        longitude: '41.66945620',
        type: 'province',
      },
      {
        id: 2164,
        name: 'Eskişehir',
        state_code: '26',
        latitude: '39.63296570',
        longitude: '31.26263660',
        type: 'province',
      },
      {
        id: 2203,
        name: 'Gaziantep',
        state_code: '27',
        latitude: '37.07638820',
        longitude: '37.38272340',
        type: 'province',
      },
      {
        id: 2186,
        name: 'Giresun',
        state_code: '28',
        latitude: '40.64616720',
        longitude: '38.59355110',
        type: 'province',
      },
      {
        id: 2204,
        name: 'Gümüşhane',
        state_code: '29',
        latitude: '40.28036730',
        longitude: '39.31432530',
        type: 'province',
      },
      {
        id: 2190,
        name: 'Hakkâri',
        state_code: '30',
        latitude: '37.44593190',
        longitude: '43.74498410',
        type: 'province',
      },
      {
        id: 2211,
        name: 'Hatay',
        state_code: '31',
        latitude: '36.40184880',
        longitude: '36.34980970',
        type: 'province',
      },
      {
        id: 2166,
        name: 'Iğdır',
        state_code: '76',
        latitude: '39.88798410',
        longitude: '44.00483650',
        type: 'province',
      },
      {
        id: 2222,
        name: 'Isparta',
        state_code: '32',
        latitude: '38.02114640',
        longitude: '31.07937050',
        type: 'province',
      },
      {
        id: 2170,
        name: 'Istanbul',
        state_code: '34',
        latitude: '41.16343020',
        longitude: '28.76644080',
        type: 'province',
      },
      {
        id: 2205,
        name: 'İzmir',
        state_code: '35',
        latitude: '38.35916930',
        longitude: '27.26761160',
        type: 'province',
      },
      {
        id: 2227,
        name: 'Kahramanmaraş',
        state_code: '46',
        latitude: '37.75030360',
        longitude: '36.95410700',
        type: 'province',
      },
      {
        id: 2223,
        name: 'Karabük',
        state_code: '78',
        latitude: '41.18748900',
        longitude: '32.74174190',
        type: 'province',
      },
      {
        id: 2184,
        name: 'Karaman',
        state_code: '70',
        latitude: '37.24363360',
        longitude: '33.61757700',
        type: 'province',
      },
      {
        id: 2208,
        name: 'Kars',
        state_code: '36',
        latitude: '40.28076360',
        longitude: '42.99195270',
        type: 'province',
      },
      {
        id: 2197,
        name: 'Kastamonu',
        state_code: '37',
        latitude: '41.41038630',
        longitude: '33.69983340',
        type: 'province',
      },
      {
        id: 2200,
        name: 'Kayseri',
        state_code: '38',
        latitude: '38.62568540',
        longitude: '35.74068820',
        type: 'province',
      },
      {
        id: 2154,
        name: 'Kilis',
        state_code: '79',
        latitude: '36.82047750',
        longitude: '37.16873390',
        type: 'province',
      },
      {
        id: 2178,
        name: 'Kırıkkale',
        state_code: '71',
        latitude: '39.88768780',
        longitude: '33.75552480',
        type: 'province',
      },
      {
        id: 2176,
        name: 'Kırklareli',
        state_code: '39',
        latitude: '41.72597950',
        longitude: '27.48383900',
        type: 'province',
      },
      {
        id: 2180,
        name: 'Kırşehir',
        state_code: '40',
        latitude: '39.22689050',
        longitude: '33.97500180',
        type: 'province',
      },
      {
        id: 2195,
        name: 'Kocaeli',
        state_code: '41',
        latitude: '40.85327040',
        longitude: '29.88152030',
        type: 'province',
      },
      {
        id: 2171,
        name: 'Konya',
        state_code: '42',
        latitude: '37.98381340',
        longitude: '32.71813750',
        type: 'province',
      },
      {
        id: 2149,
        name: 'Kütahya',
        state_code: '43',
        latitude: '39.35813700',
        longitude: '29.60354950',
        type: 'province',
      },
      {
        id: 2158,
        name: 'Malatya',
        state_code: '44',
        latitude: '38.40150570',
        longitude: '37.95362980',
        type: 'province',
      },
      {
        id: 2198,
        name: 'Manisa',
        state_code: '45',
        latitude: '38.84193730',
        longitude: '28.11226790',
        type: 'province',
      },
      {
        id: 2224,
        name: 'Mardin',
        state_code: '47',
        latitude: '37.34429290',
        longitude: '40.61964870',
        type: 'province',
      },
      {
        id: 2156,
        name: 'Mersin',
        state_code: '33',
        latitude: '36.81208580',
        longitude: '34.64147500',
        type: 'province',
      },
      {
        id: 2182,
        name: 'Muğla',
        state_code: '48',
        latitude: '37.18358190',
        longitude: '28.48639630',
        type: 'province',
      },
      {
        id: 2162,
        name: 'Muş',
        state_code: '49',
        latitude: '38.94618880',
        longitude: '41.75389310',
        type: 'province',
      },
      {
        id: 2196,
        name: 'Nevşehir',
        state_code: '50',
        latitude: '38.69393990',
        longitude: '34.68565090',
        type: 'province',
      },
      {
        id: 2189,
        name: 'Niğde',
        state_code: '51',
        latitude: '38.09930860',
        longitude: '34.68565090',
        type: 'province',
      },
      {
        id: 2174,
        name: 'Ordu',
        state_code: '52',
        latitude: '40.79905800',
        longitude: '37.38990050',
        type: 'province',
      },
      {
        id: 2214,
        name: 'Osmaniye',
        state_code: '80',
        latitude: '37.21302580',
        longitude: '36.17626150',
        type: 'province',
      },
      {
        id: 2219,
        name: 'Rize',
        state_code: '53',
        latitude: '40.95814970',
        longitude: '40.92269850',
        type: 'province',
      },
      {
        id: 2150,
        name: 'Sakarya',
        state_code: '54',
        latitude: '40.78885500',
        longitude: '30.40595400',
        type: 'province',
      },
      {
        id: 2220,
        name: 'Samsun',
        state_code: '55',
        latitude: '41.18648590',
        longitude: '36.13226780',
        type: 'province',
      },
      {
        id: 2183,
        name: 'Şanlıurfa',
        state_code: '63',
        latitude: '37.35691020',
        longitude: '39.15436770',
        type: 'province',
      },
      {
        id: 2207,
        name: 'Siirt',
        state_code: '56',
        latitude: '37.86588620',
        longitude: '42.14945230',
        type: 'province',
      },
      {
        id: 4854,
        name: 'Sinop',
        state_code: '57',
        latitude: '41.55947490',
        longitude: '34.85805320',
        type: 'province',
      },
      {
        id: 2181,
        name: 'Sivas',
        state_code: '58',
        latitude: '39.44880390',
        longitude: '37.12944970',
        type: 'province',
      },
      {
        id: 2225,
        name: 'Şırnak',
        state_code: '73',
        latitude: '37.41874810',
        longitude: '42.49183380',
        type: 'province',
      },
      {
        id: 2167,
        name: 'Tekirdağ',
        state_code: '59',
        latitude: '41.11212270',
        longitude: '27.26761160',
        type: 'province',
      },
      {
        id: 2199,
        name: 'Tokat',
        state_code: '60',
        latitude: '40.39027130',
        longitude: '36.62518630',
        type: 'province',
      },
      {
        id: 2206,
        name: 'Trabzon',
        state_code: '61',
        latitude: '40.79924100',
        longitude: '39.58479440',
        type: 'province',
      },
      {
        id: 2192,
        name: 'Tunceli',
        state_code: '62',
        latitude: '39.30735540',
        longitude: '39.43877780',
        type: 'province',
      },
      {
        id: 2201,
        name: 'Uşak',
        state_code: '64',
        latitude: '38.54313190',
        longitude: '29.23207840',
        type: 'province',
      },
      {
        id: 2152,
        name: 'Van',
        state_code: '65',
        latitude: '38.36794170',
        longitude: '43.71827870',
        type: 'province',
      },
      {
        id: 2218,
        name: 'Yalova',
        state_code: '77',
        latitude: '40.57759860',
        longitude: '29.20883030',
        type: 'province',
      },
      {
        id: 2188,
        name: 'Yozgat',
        state_code: '66',
        latitude: '39.72719790',
        longitude: '35.10778580',
        type: 'province',
      },
      {
        id: 2213,
        name: 'Zonguldak',
        state_code: '67',
        latitude: '41.31249170',
        longitude: '31.85982510',
        type: 'province',
      },
    ],
  },
  'United Arab Emirates': {
    name: 'United Arab Emirates',
    iso3: 'ARE',
    iso2: 'AE',
    numeric_code: '784',
    phone_code: '971',
    capital: 'Abu Dhabi',
    currency: 'AED',
    currency_name: 'United Arab Emirates dirham',
    currency_symbol: 'إ.د',
    tld: '.ae',
    native: 'دولة الإمارات العربية المتحدة',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Dubai',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'GST',
        tzName: 'Gulf Standard Time',
      },
    ],
    translations: {
      kr: '아랍에미리트',
      br: 'Emirados árabes Unidos',
      pt: 'Emirados árabes Unidos',
      nl: 'Verenigde Arabische Emiraten',
      hr: 'Ujedinjeni Arapski Emirati',
      fa: 'امارات متحده عربی',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      fr: 'Émirats arabes unis',
      ja: 'アラブ首長国連邦',
      it: 'Emirati Arabi Uniti',
      cn: '阿拉伯联合酋长国',
    },
    latitude: '24.00000000',
    longitude: '54.00000000',
    emoji: '🇦🇪',
    emojiU: 'U+1F1E6 U+1F1EA',
    states: [
      {
        id: 3396,
        name: 'Abu Dhabi Emirate',
        state_code: 'AZ',
        latitude: '24.45388400',
        longitude: '54.37734380',
        type: null,
      },
      {
        id: 3395,
        name: 'Ajman Emirate',
        state_code: 'AJ',
        latitude: '25.40521650',
        longitude: '55.51364330',
        type: null,
      },
      {
        id: 3391,
        name: 'Dubai',
        state_code: 'DU',
        latitude: '25.20484930',
        longitude: '55.27078280',
        type: null,
      },
      {
        id: 3393,
        name: 'Fujairah',
        state_code: 'FU',
        latitude: '25.12880990',
        longitude: '56.32648490',
        type: null,
      },
      {
        id: 3394,
        name: 'Ras al-Khaimah',
        state_code: 'RK',
        latitude: '25.67413430',
        longitude: '55.98041730',
        type: null,
      },
      {
        id: 3390,
        name: 'Sharjah Emirate',
        state_code: 'SH',
        latitude: '25.07539740',
        longitude: '55.75784030',
        type: null,
      },
      {
        id: 3392,
        name: 'Umm al-Quwain',
        state_code: 'UQ',
        latitude: '25.54263240',
        longitude: '55.54753480',
        type: null,
      },
    ],
  },
  'United Kingdom': {
    name: 'United Kingdom',
    iso3: 'GBR',
    iso2: 'GB',
    numeric_code: '826',
    phone_code: '44',
    capital: 'London',
    currency: 'GBP',
    currency_name: 'British pound',
    currency_symbol: '£',
    tld: '.uk',
    native: 'United Kingdom',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/London',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      kr: '영국',
      br: 'Reino Unido',
      pt: 'Reino Unido',
      nl: 'Verenigd Koninkrijk',
      hr: 'Ujedinjeno Kraljevstvo',
      fa: 'بریتانیای کبیر و ایرلند شمالی',
      de: 'Vereinigtes Königreich',
      es: 'Reino Unido',
      fr: 'Royaume-Uni',
      ja: 'イギリス',
      it: 'Regno Unito',
      cn: '英国',
    },
    latitude: '54.00000000',
    longitude: '-2.00000000',
    emoji: '🇬🇧',
    emojiU: 'U+1F1EC U+1F1E7',
    states: [
      {
        id: 2463,
        name: 'Aberdeen',
        state_code: 'ABE',
        latitude: '57.14971700',
        longitude: '-2.09427800',
        type: null,
      },
      {
        id: 2401,
        name: 'Aberdeenshire',
        state_code: 'ABD',
        latitude: '57.28687230',
        longitude: '-2.38156840',
        type: null,
      },
      {
        id: 2387,
        name: 'Angus',
        state_code: 'ANS',
        latitude: '37.27578860',
        longitude: '-95.65010330',
        type: null,
      },
      {
        id: 2533,
        name: 'Antrim',
        state_code: 'ANT',
        latitude: '54.71953380',
        longitude: '-6.20724980',
        type: null,
      },
      {
        id: 2412,
        name: 'Antrim and Newtownabbey',
        state_code: 'ANN',
        latitude: '54.69568870',
        longitude: '-5.94810690',
        type: null,
      },
      {
        id: 2498,
        name: 'Ards',
        state_code: 'ARD',
        latitude: '42.13918510',
        longitude: '-87.86149720',
        type: null,
      },
      {
        id: 2523,
        name: 'Ards and North Down',
        state_code: 'AND',
        latitude: '54.58996450',
        longitude: '-5.59849720',
        type: null,
      },
      {
        id: 2392,
        name: 'Argyll and Bute',
        state_code: 'AGB',
        latitude: '56.40062140',
        longitude: '-5.48074800',
        type: null,
      },
      {
        id: 2331,
        name: 'Armagh City and District Council',
        state_code: 'ARM',
        latitude: '54.39325920',
        longitude: '-6.45634010',
        type: null,
      },
      {
        id: 2324,
        name: 'Armagh, Banbridge and Craigavon',
        state_code: 'ABC',
        latitude: '54.39325920',
        longitude: '-6.45634010',
        type: null,
      },
      {
        id: 2378,
        name: 'Ascension Island',
        state_code: 'SH-AC',
        latitude: '-7.94671660',
        longitude: '-14.35591580',
        type: null,
      },
      {
        id: 2363,
        name: 'Ballymena Borough',
        state_code: 'BLA',
        latitude: '54.86426000',
        longitude: '-6.27910740',
        type: null,
      },
      {
        id: 2361,
        name: 'Ballymoney',
        state_code: 'BLY',
        latitude: '55.07048880',
        longitude: '-6.51737080',
        type: null,
      },
      {
        id: 2315,
        name: 'Banbridge',
        state_code: 'BNB',
        latitude: '54.34872900',
        longitude: '-6.27048030',
        type: null,
      },
      {
        id: 2499,
        name: 'Barnsley',
        state_code: 'BNS',
        latitude: '34.29949560',
        longitude: '-84.98458090',
        type: null,
      },
      {
        id: 2339,
        name: 'Bath and North East Somerset',
        state_code: 'BAS',
        latitude: '51.32501020',
        longitude: '-2.47662410',
        type: null,
      },
      {
        id: 2507,
        name: 'Bedford',
        state_code: 'BDF',
        latitude: '32.84401700',
        longitude: '-97.14306710',
        type: null,
      },
      {
        id: 2311,
        name: 'Belfast district',
        state_code: 'BFS',
        latitude: '54.61703660',
        longitude: '-5.95318610',
        type: null,
      },
      {
        id: 2425,
        name: 'Birmingham',
        state_code: 'BIR',
        latitude: '33.51858920',
        longitude: '-86.81035670',
        type: null,
      },
      {
        id: 2329,
        name: 'Blackburn with Darwen',
        state_code: 'BBD',
        latitude: '53.69575220',
        longitude: '-2.46829850',
        type: null,
      },
      {
        id: 2451,
        name: 'Blackpool',
        state_code: 'BPL',
        latitude: '53.81750530',
        longitude: '-3.03567480',
        type: null,
      },
      {
        id: 2530,
        name: 'Blaenau Gwent County Borough',
        state_code: 'BGW',
        latitude: '51.78757790',
        longitude: '-3.20439310',
        type: null,
      },
      {
        id: 2504,
        name: 'Bolton',
        state_code: 'BOL',
        latitude: '44.37264760',
        longitude: '-72.87876250',
        type: null,
      },
      {
        id: 2342,
        name: 'Bournemouth',
        state_code: 'BMH',
        latitude: '50.71916400',
        longitude: '-1.88076900',
        type: null,
      },
      {
        id: 2470,
        name: 'Bracknell Forest',
        state_code: 'BRC',
        latitude: '51.41538280',
        longitude: '-0.75364950',
        type: null,
      },
      {
        id: 2529,
        name: 'Bradford',
        state_code: 'BRD',
        latitude: '53.79598400',
        longitude: '-1.75939800',
        type: null,
      },
      {
        id: 2452,
        name: 'Bridgend County Borough',
        state_code: 'BGE',
        latitude: '51.50831990',
        longitude: '-3.58120750',
        type: null,
      },
      {
        id: 2395,
        name: 'Brighton and Hove',
        state_code: 'BNH',
        latitude: '50.82262880',
        longitude: '-0.13704700',
        type: null,
      },
      {
        id: 2405,
        name: 'Buckinghamshire',
        state_code: 'BKM',
        latitude: '51.80722040',
        longitude: '-0.81276640',
        type: null,
      },
      {
        id: 2459,
        name: 'Bury',
        state_code: 'BUR',
        latitude: '53.59334980',
        longitude: '-2.29660540',
        type: null,
      },
      {
        id: 2298,
        name: 'Caerphilly County Borough',
        state_code: 'CAY',
        latitude: '51.66044650',
        longitude: '-3.21787240',
        type: null,
      },
      {
        id: 2517,
        name: 'Calderdale',
        state_code: 'CLD',
        latitude: '53.72478450',
        longitude: '-1.86583570',
        type: null,
      },
      {
        id: 2423,
        name: 'Cambridgeshire',
        state_code: 'CAM',
        latitude: '52.20529730',
        longitude: '0.12181950',
        type: null,
      },
      {
        id: 2484,
        name: 'Carmarthenshire',
        state_code: 'CMN',
        latitude: '51.85723090',
        longitude: '-4.31159590',
        type: null,
      },
      {
        id: 2439,
        name: 'Carrickfergus Borough Council',
        state_code: 'CKF',
        latitude: '54.72568430',
        longitude: '-5.80937190',
        type: null,
      },
      {
        id: 2525,
        name: 'Castlereagh',
        state_code: 'CSR',
        latitude: '54.57567900',
        longitude: '-5.88840280',
        type: null,
      },
      {
        id: 2316,
        name: 'Causeway Coast and Glens',
        state_code: 'CCG',
        latitude: '55.04318300',
        longitude: '-6.67412880',
        type: null,
      },
      {
        id: 2303,
        name: 'Central Bedfordshire',
        state_code: 'CBF',
        latitude: '52.00297440',
        longitude: '-0.46513890',
        type: null,
      },
      {
        id: 2509,
        name: 'Ceredigion',
        state_code: 'CGN',
        latitude: '52.21914290',
        longitude: '-3.93212560',
        type: null,
      },
      {
        id: 2444,
        name: 'Cheshire East',
        state_code: 'CHE',
        latitude: '53.16104460',
        longitude: '-2.21859320',
        type: null,
      },
      {
        id: 2442,
        name: 'Cheshire West and Chester',
        state_code: 'CHW',
        latitude: '53.23029740',
        longitude: '-2.71511170',
        type: null,
      },
      {
        id: 2528,
        name: 'City and County of Cardiff',
        state_code: 'CRF',
        latitude: '51.48158100',
        longitude: '-3.17909000',
        type: null,
      },
      {
        id: 2433,
        name: 'City and County of Swansea',
        state_code: 'SWA',
        latitude: '51.62144000',
        longitude: '-3.94364600',
        type: null,
      },
      {
        id: 2413,
        name: 'City of Bristol',
        state_code: 'BST',
        latitude: '41.67352200',
        longitude: '-72.94653750',
        type: null,
      },
      {
        id: 2485,
        name: 'City of Derby',
        state_code: 'DER',
        latitude: '37.54837550',
        longitude: '-97.24851910',
        type: null,
      },
      {
        id: 2475,
        name: 'City of Kingston upon Hull',
        state_code: 'KHL',
        latitude: '53.76762360',
        longitude: '-0.32741980',
        type: null,
      },
      {
        id: 2318,
        name: 'City of Leicester',
        state_code: 'LCE',
        latitude: '52.63687780',
        longitude: '-1.13975920',
        type: null,
      },
      {
        id: 2424,
        name: 'City of London',
        state_code: 'LND',
        latitude: '51.51234430',
        longitude: '-0.09098520',
        type: null,
      },
      {
        id: 2359,
        name: 'City of Nottingham',
        state_code: 'NGM',
        latitude: '52.95478320',
        longitude: '-1.15810860',
        type: null,
      },
      {
        id: 2297,
        name: 'City of Peterborough',
        state_code: 'PTE',
        latitude: '44.30936360',
        longitude: '-78.32015300',
        type: null,
      },
      {
        id: 2514,
        name: 'City of Plymouth',
        state_code: 'PLY',
        latitude: '42.37089410',
        longitude: '-83.46971410',
        type: null,
      },
      {
        id: 2305,
        name: 'City of Portsmouth',
        state_code: 'POR',
        latitude: '36.83291500',
        longitude: '-76.29755490',
        type: null,
      },
      {
        id: 2294,
        name: 'City of Southampton',
        state_code: 'STH',
        latitude: '50.90970040',
        longitude: '-1.40435090',
        type: null,
      },
      {
        id: 2506,
        name: 'City of Stoke-on-Trent',
        state_code: 'STE',
        latitude: '53.00266800',
        longitude: '-2.17940400',
        type: null,
      },
      {
        id: 2372,
        name: 'City of Sunderland',
        state_code: 'SND',
        latitude: '54.88614890',
        longitude: '-1.47857970',
        type: null,
      },
      {
        id: 2357,
        name: 'City of Westminster',
        state_code: 'WSM',
        latitude: '39.57659770',
        longitude: '-76.99721260',
        type: null,
      },
      {
        id: 2489,
        name: 'City of Wolverhampton',
        state_code: 'WLV',
        latitude: '52.58891200',
        longitude: '-2.15646300',
        type: null,
      },
      {
        id: 2426,
        name: 'City of York',
        state_code: 'YOR',
        latitude: '53.95996510',
        longitude: '-1.08729790',
        type: null,
      },
      {
        id: 2450,
        name: 'Clackmannanshire',
        state_code: 'CLK',
        latitude: '56.10753510',
        longitude: '-3.75294090',
        type: null,
      },
      {
        id: 2461,
        name: 'Coleraine Borough Council',
        state_code: 'CLR',
        latitude: '55.14515700',
        longitude: '-6.67598140',
        type: null,
      },
      {
        id: 2352,
        name: 'Conwy County Borough',
        state_code: 'CWY',
        latitude: '53.29350130',
        longitude: '-3.72651610',
        type: null,
      },
      {
        id: 2445,
        name: 'Cookstown District Council',
        state_code: 'CKT',
        latitude: '54.64181580',
        longitude: '-6.74438950',
        type: null,
      },
      {
        id: 2312,
        name: 'Cornwall',
        state_code: 'CON',
        latitude: '50.26604710',
        longitude: '-5.05271250',
        type: null,
      },
      {
        id: 2406,
        name: 'County Durham',
        state_code: 'DUR',
        latitude: '54.72940990',
        longitude: '-1.88115980',
        type: null,
      },
      {
        id: 2438,
        name: 'Coventry',
        state_code: 'COV',
        latitude: '52.40682200',
        longitude: '-1.51969300',
        type: null,
      },
      {
        id: 2449,
        name: 'Craigavon Borough Council',
        state_code: 'CGV',
        latitude: '54.39325920',
        longitude: '-6.45634010',
        type: null,
      },
      {
        id: 2334,
        name: 'Cumbria',
        state_code: 'CMA',
        latitude: '54.57723230',
        longitude: '-2.79748350',
        type: null,
      },
      {
        id: 2389,
        name: 'Darlington',
        state_code: 'DAL',
        latitude: '34.29987620',
        longitude: '-79.87617410',
        type: null,
      },
      {
        id: 2497,
        name: 'Denbighshire',
        state_code: 'DEN',
        latitude: '53.18422880',
        longitude: '-3.42249850',
        type: null,
      },
      {
        id: 2403,
        name: 'Derbyshire',
        state_code: 'DBY',
        latitude: '53.10467820',
        longitude: '-1.56238850',
        type: null,
      },
      {
        id: 2446,
        name: 'Derry City and Strabane',
        state_code: 'DRS',
        latitude: '55.00474430',
        longitude: '-7.32092220',
        type: null,
      },
      {
        id: 2417,
        name: 'Derry City Council',
        state_code: 'DRY',
        latitude: '54.96907780',
        longitude: '-7.19583510',
        type: null,
      },
      {
        id: 2491,
        name: 'Devon',
        state_code: 'DEV',
        latitude: '50.71555910',
        longitude: '-3.53087500',
        type: null,
      },
      {
        id: 2364,
        name: 'Doncaster',
        state_code: 'DNC',
        latitude: '53.52282000',
        longitude: '-1.12846200',
        type: null,
      },
      {
        id: 2345,
        name: 'Dorset',
        state_code: 'DOR',
        latitude: '50.74876350',
        longitude: '-2.34447860',
        type: null,
      },
      {
        id: 2304,
        name: 'Down District Council',
        state_code: 'DOW',
        latitude: '54.24342870',
        longitude: '-5.95779590',
        type: null,
      },
      {
        id: 2457,
        name: 'Dudley',
        state_code: 'DUD',
        latitude: '42.04336610',
        longitude: '-71.92760330',
        type: null,
      },
      {
        id: 2415,
        name: 'Dumfries and Galloway',
        state_code: 'DGY',
        latitude: '55.07010730',
        longitude: '-3.60525810',
        type: null,
      },
      {
        id: 2511,
        name: 'Dundee',
        state_code: 'DND',
        latitude: '56.46201800',
        longitude: '-2.97072100',
        type: null,
      },
      {
        id: 2508,
        name: 'Dungannon and South Tyrone Borough Council',
        state_code: 'DGN',
        latitude: '54.50826840',
        longitude: '-6.76658910',
        type: null,
      },
      {
        id: 2374,
        name: 'East Ayrshire',
        state_code: 'EAY',
        latitude: '55.45184960',
        longitude: '-4.26444780',
        type: null,
      },
      {
        id: 2454,
        name: 'East Dunbartonshire',
        state_code: 'EDU',
        latitude: '55.97431620',
        longitude: '-4.20229800',
        type: null,
      },
      {
        id: 2462,
        name: 'East Lothian',
        state_code: 'ELN',
        latitude: '55.94933830',
        longitude: '-2.77044640',
        type: null,
      },
      {
        id: 2333,
        name: 'East Renfrewshire',
        state_code: 'ERW',
        latitude: '55.77047350',
        longitude: '-4.33598210',
        type: null,
      },
      {
        id: 2370,
        name: 'East Riding of Yorkshire',
        state_code: 'ERY',
        latitude: '53.84161680',
        longitude: '-0.43441060',
        type: null,
      },
      {
        id: 2414,
        name: 'East Sussex',
        state_code: 'ESX',
        latitude: '50.90859550',
        longitude: '0.24941660',
        type: null,
      },
      {
        id: 2428,
        name: 'Edinburgh',
        state_code: 'EDH',
        latitude: '55.95325200',
        longitude: '-3.18826700',
        type: null,
      },
      {
        id: 2336,
        name: 'England',
        state_code: 'ENG',
        latitude: '52.35551770',
        longitude: '-1.17431970',
        type: null,
      },
      {
        id: 2410,
        name: 'Essex',
        state_code: 'ESS',
        latitude: '51.57424470',
        longitude: '0.48567810',
        type: null,
      },
      {
        id: 2344,
        name: 'Falkirk',
        state_code: 'FAL',
        latitude: '56.00187750',
        longitude: '-3.78391310',
        type: null,
      },
      {
        id: 2366,
        name: 'Fermanagh and Omagh',
        state_code: 'FMO',
        latitude: '54.45135240',
        longitude: '-7.71250180',
        type: null,
      },
      {
        id: 2531,
        name: 'Fermanagh District Council',
        state_code: 'FER',
        latitude: '54.34479780',
        longitude: '-7.63842180',
        type: null,
      },
      {
        id: 2479,
        name: 'Fife',
        state_code: 'FIF',
        latitude: '56.20820780',
        longitude: '-3.14951750',
        type: null,
      },
      {
        id: 2437,
        name: 'Flintshire',
        state_code: 'FLN',
        latitude: '53.16686580',
        longitude: '-3.14189080',
        type: null,
      },
      {
        id: 2431,
        name: 'Gateshead',
        state_code: 'GAT',
        latitude: '54.95268000',
        longitude: '-1.60341100',
        type: null,
      },
      {
        id: 2404,
        name: 'Glasgow',
        state_code: 'GLG',
        latitude: '55.86423700',
        longitude: '-4.25180600',
        type: null,
      },
      {
        id: 2373,
        name: 'Gloucestershire',
        state_code: 'GLS',
        latitude: '51.86421120',
        longitude: '-2.23803350',
        type: null,
      },
      {
        id: 2379,
        name: 'Gwynedd',
        state_code: 'GWN',
        latitude: '52.92772660',
        longitude: '-4.13348360',
        type: null,
      },
      {
        id: 2466,
        name: 'Halton',
        state_code: 'HAL',
        latitude: '43.53253720',
        longitude: '-79.87448360',
        type: null,
      },
      {
        id: 2435,
        name: 'Hampshire',
        state_code: 'HAM',
        latitude: '51.05769480',
        longitude: '-1.30806290',
        type: null,
      },
      {
        id: 2309,
        name: 'Hartlepool',
        state_code: 'HPL',
        latitude: '54.69174500',
        longitude: '-1.21292600',
        type: null,
      },
      {
        id: 2500,
        name: 'Herefordshire',
        state_code: 'HEF',
        latitude: '52.07651640',
        longitude: '-2.65441820',
        type: null,
      },
      {
        id: 2369,
        name: 'Hertfordshire',
        state_code: 'HRT',
        latitude: '51.80978230',
        longitude: '-0.23767440',
        type: null,
      },
      {
        id: 2383,
        name: 'Highland',
        state_code: 'HLD',
        latitude: '36.29675080',
        longitude: '-95.83803660',
        type: null,
      },
      {
        id: 2388,
        name: 'Inverclyde',
        state_code: 'IVC',
        latitude: '55.93165690',
        longitude: '-4.68001580',
        type: null,
      },
      {
        id: 2289,
        name: 'Isle of Wight',
        state_code: 'IOW',
        latitude: '50.69384790',
        longitude: '-1.30473400',
        type: null,
      },
      {
        id: 2343,
        name: 'Isles of Scilly',
        state_code: 'IOS',
        latitude: '49.92772610',
        longitude: '-6.32749660',
        type: null,
      },
      {
        id: 2464,
        name: 'Kent',
        state_code: 'KEN',
        latitude: '41.15366740',
        longitude: '-81.35788590',
        type: null,
      },
      {
        id: 2371,
        name: 'Kirklees',
        state_code: 'KIR',
        latitude: '53.59334320',
        longitude: '-1.80095090',
        type: null,
      },
      {
        id: 2330,
        name: 'Knowsley',
        state_code: 'KWL',
        latitude: '53.45459400',
        longitude: '-2.85290700',
        type: null,
      },
      {
        id: 2495,
        name: 'Lancashire',
        state_code: 'LAN',
        latitude: '53.76322540',
        longitude: '-2.70440520',
        type: null,
      },
      {
        id: 2515,
        name: 'Larne Borough Council',
        state_code: 'LRN',
        latitude: '54.85780030',
        longitude: '-5.82362240',
        type: null,
      },
      {
        id: 2503,
        name: 'Leeds',
        state_code: 'LDS',
        latitude: '53.80075540',
        longitude: '-1.54907740',
        type: null,
      },
      {
        id: 2516,
        name: 'Leicestershire',
        state_code: 'LEC',
        latitude: '52.77257100',
        longitude: '-1.20521260',
        type: null,
      },
      {
        id: 2382,
        name: 'Limavady Borough Council',
        state_code: 'LMV',
        latitude: '55.05168200',
        longitude: '-6.94919440',
        type: null,
      },
      {
        id: 2355,
        name: 'Lincolnshire',
        state_code: 'LIN',
        latitude: '52.94518890',
        longitude: '-0.16012460',
        type: null,
      },
      {
        id: 2460,
        name: 'Lisburn and Castlereagh',
        state_code: 'LBC',
        latitude: '54.49815840',
        longitude: '-6.13067910',
        type: null,
      },
      {
        id: 2494,
        name: 'Lisburn City Council',
        state_code: 'LSB',
        latitude: '54.49815840',
        longitude: '-6.13067910',
        type: null,
      },
      {
        id: 2340,
        name: 'Liverpool',
        state_code: 'LIV',
        latitude: '32.65649810',
        longitude: '-115.47632410',
        type: null,
      },
      {
        id: 2356,
        name: 'London Borough of Barking and Dagenham',
        state_code: 'BDG',
        latitude: '51.55406660',
        longitude: '0.13401700',
        type: null,
      },
      {
        id: 2520,
        name: 'London Borough of Barnet',
        state_code: 'BNE',
        latitude: '51.60496730',
        longitude: '-0.20762950',
        type: null,
      },
      {
        id: 2307,
        name: 'London Borough of Bexley',
        state_code: 'BEX',
        latitude: '51.45190210',
        longitude: '0.11717860',
        type: null,
      },
      {
        id: 2291,
        name: 'London Borough of Brent',
        state_code: 'BEN',
        latitude: '51.56728080',
        longitude: '-0.27105680',
        type: null,
      },
      {
        id: 2490,
        name: 'London Borough of Bromley',
        state_code: 'BRY',
        latitude: '51.36797050',
        longitude: '0.07006200',
        type: null,
      },
      {
        id: 2349,
        name: 'London Borough of Camden',
        state_code: 'CMD',
        latitude: '51.54547360',
        longitude: '-0.16279020',
        type: null,
      },
      {
        id: 2512,
        name: 'London Borough of Croydon',
        state_code: 'CRY',
        latitude: '51.38274460',
        longitude: '-0.09851630',
        type: null,
      },
      {
        id: 2532,
        name: 'London Borough of Ealing',
        state_code: 'EAL',
        latitude: '51.52503660',
        longitude: '-0.34139650',
        type: null,
      },
      {
        id: 2476,
        name: 'London Borough of Enfield',
        state_code: 'ENF',
        latitude: '51.66229090',
        longitude: '-0.11806510',
        type: null,
      },
      {
        id: 2411,
        name: 'London Borough of Hackney',
        state_code: 'HCK',
        latitude: '51.57344500',
        longitude: '-0.07243760',
        type: null,
      },
      {
        id: 2448,
        name: 'London Borough of Hammersmith and Fulham',
        state_code: 'HMF',
        latitude: '51.49901560',
        longitude: '-0.22915000',
        type: null,
      },
      {
        id: 2306,
        name: 'London Borough of Haringey',
        state_code: 'HRY',
        latitude: '51.59061130',
        longitude: '-0.11097090',
        type: null,
      },
      {
        id: 2385,
        name: 'London Borough of Harrow',
        state_code: 'HRW',
        latitude: '51.58816270',
        longitude: '-0.34228510',
        type: null,
      },
      {
        id: 2347,
        name: 'London Borough of Havering',
        state_code: 'HAV',
        latitude: '51.57792400',
        longitude: '0.21208290',
        type: null,
      },
      {
        id: 2376,
        name: 'London Borough of Hillingdon',
        state_code: 'HIL',
        latitude: '51.53518320',
        longitude: '-0.44813780',
        type: null,
      },
      {
        id: 2380,
        name: 'London Borough of Hounslow',
        state_code: 'HNS',
        latitude: '51.48283580',
        longitude: '-0.38820620',
        type: null,
      },
      {
        id: 2319,
        name: 'London Borough of Islington',
        state_code: 'ISL',
        latitude: '51.54650630',
        longitude: '-0.10580580',
        type: null,
      },
      {
        id: 2396,
        name: 'London Borough of Lambeth',
        state_code: 'LBH',
        latitude: '51.45714770',
        longitude: '-0.12306810',
        type: null,
      },
      {
        id: 2358,
        name: 'London Borough of Lewisham',
        state_code: 'LEW',
        latitude: '51.44145790',
        longitude: '-0.01170060',
        type: null,
      },
      {
        id: 2483,
        name: 'London Borough of Merton',
        state_code: 'MRT',
        latitude: '51.40977420',
        longitude: '-0.21080840',
        type: null,
      },
      {
        id: 2418,
        name: 'London Borough of Newham',
        state_code: 'NWM',
        latitude: '51.52551620',
        longitude: '0.03521630',
        type: null,
      },
      {
        id: 2397,
        name: 'London Borough of Redbridge',
        state_code: 'RDB',
        latitude: '51.58861210',
        longitude: '0.08239820',
        type: null,
      },
      {
        id: 2501,
        name: 'London Borough of Richmond upon Thames',
        state_code: 'RIC',
        latitude: '51.46130540',
        longitude: '-0.30377090',
        type: null,
      },
      {
        id: 2432,
        name: 'London Borough of Southwark',
        state_code: 'SWK',
        latitude: '51.48805720',
        longitude: '-0.07628380',
        type: null,
      },
      {
        id: 2313,
        name: 'London Borough of Sutton',
        state_code: 'STN',
        latitude: '51.35737620',
        longitude: '-0.17527960',
        type: null,
      },
      {
        id: 2390,
        name: 'London Borough of Tower Hamlets',
        state_code: 'TWH',
        latitude: '51.52026070',
        longitude: '-0.02933960',
        type: null,
      },
      {
        id: 2326,
        name: 'London Borough of Waltham Forest',
        state_code: 'WFT',
        latitude: '51.58863830',
        longitude: '-0.01176250',
        type: null,
      },
      {
        id: 2434,
        name: 'London Borough of Wandsworth',
        state_code: 'WND',
        latitude: '51.45682740',
        longitude: '-0.18966380',
        type: null,
      },
      {
        id: 2322,
        name: 'Magherafelt District Council',
        state_code: 'MFT',
        latitude: '54.75532790',
        longitude: '-6.60774870',
        type: null,
      },
      {
        id: 2398,
        name: 'Manchester',
        state_code: 'MAN',
        latitude: '53.48075930',
        longitude: '-2.24263050',
        type: null,
      },
      {
        id: 2381,
        name: 'Medway',
        state_code: 'MDW',
        latitude: '42.14176410',
        longitude: '-71.39672560',
        type: null,
      },
      {
        id: 2328,
        name: 'Merthyr Tydfil County Borough',
        state_code: 'MTY',
        latitude: '51.74674740',
        longitude: '-3.38132750',
        type: null,
      },
      {
        id: 2320,
        name: 'Metropolitan Borough of Wigan',
        state_code: 'WGN',
        latitude: '53.51348120',
        longitude: '-2.61069990',
        type: null,
      },
      {
        id: 2429,
        name: 'Mid and East Antrim',
        state_code: 'MEA',
        latitude: '54.93993410',
        longitude: '-6.11374230',
        type: null,
      },
      {
        id: 2399,
        name: 'Mid Ulster',
        state_code: 'MUL',
        latitude: '54.64113010',
        longitude: '-6.75225490',
        type: null,
      },
      {
        id: 2332,
        name: 'Middlesbrough',
        state_code: 'MDB',
        latitude: '54.57422700',
        longitude: '-1.23495600',
        type: null,
      },
      {
        id: 2519,
        name: 'Midlothian',
        state_code: 'MLN',
        latitude: '32.47533500',
        longitude: '-97.01031810',
        type: null,
      },
      {
        id: 2416,
        name: 'Milton Keynes',
        state_code: 'MIK',
        latitude: '52.08520380',
        longitude: '-0.73331330',
        type: null,
      },
      {
        id: 2402,
        name: 'Monmouthshire',
        state_code: 'MON',
        latitude: '51.81161000',
        longitude: '-2.71634170',
        type: null,
      },
      {
        id: 2360,
        name: 'Moray',
        state_code: 'MRY',
        latitude: '57.64984760',
        longitude: '-3.31680390',
        type: null,
      },
      {
        id: 2348,
        name: 'Moyle District Council',
        state_code: 'MYL',
        latitude: '55.20473270',
        longitude: '-6.25317400',
        type: null,
      },
      {
        id: 2351,
        name: 'Neath Port Talbot County Borough',
        state_code: 'NTL',
        latitude: '51.59785190',
        longitude: '-3.78396680',
        type: null,
      },
      {
        id: 2458,
        name: 'Newcastle upon Tyne',
        state_code: 'NET',
        latitude: '54.97825200',
        longitude: '-1.61778000',
        type: null,
      },
      {
        id: 2524,
        name: 'Newport',
        state_code: 'NWP',
        latitude: '37.52782340',
        longitude: '-94.10438760',
        type: null,
      },
      {
        id: 2350,
        name: 'Newry and Mourne District Council',
        state_code: 'NYM',
        latitude: '54.17425050',
        longitude: '-6.33919920',
        type: null,
      },
      {
        id: 2534,
        name: 'Newry, Mourne and Down',
        state_code: 'NMD',
        latitude: '54.24342870',
        longitude: '-5.95779590',
        type: null,
      },
      {
        id: 2317,
        name: 'Newtownabbey Borough Council',
        state_code: 'NTA',
        latitude: '54.67924220',
        longitude: '-5.95911020',
        type: null,
      },
      {
        id: 2473,
        name: 'Norfolk',
        state_code: 'NFK',
        latitude: '36.85076890',
        longitude: '-76.28587260',
        type: null,
      },
      {
        id: 2535,
        name: 'North Ayrshire',
        state_code: 'NAY',
        latitude: '55.64167310',
        longitude: '-4.75946000',
        type: null,
      },
      {
        id: 2513,
        name: 'North Down Borough Council',
        state_code: 'NDN',
        latitude: '54.65362970',
        longitude: '-5.67249250',
        type: null,
      },
      {
        id: 2384,
        name: 'North East Lincolnshire',
        state_code: 'NEL',
        latitude: '53.56682010',
        longitude: '-0.08150660',
        type: null,
      },
      {
        id: 2487,
        name: 'North Lanarkshire',
        state_code: 'NLK',
        latitude: '55.86624320',
        longitude: '-3.96131440',
        type: null,
      },
      {
        id: 2453,
        name: 'North Lincolnshire',
        state_code: 'NLN',
        latitude: '53.60555920',
        longitude: '-0.55965820',
        type: null,
      },
      {
        id: 2430,
        name: 'North Somerset',
        state_code: 'NSM',
        latitude: '51.38790280',
        longitude: '-2.77810910',
        type: null,
      },
      {
        id: 2521,
        name: 'North Tyneside',
        state_code: 'NTY',
        latitude: '55.01823990',
        longitude: '-1.48584360',
        type: null,
      },
      {
        id: 2522,
        name: 'North Yorkshire',
        state_code: 'NYK',
        latitude: '53.99150280',
        longitude: '-1.54120150',
        type: null,
      },
      {
        id: 2480,
        name: 'Northamptonshire',
        state_code: 'NTH',
        latitude: '52.27299440',
        longitude: '-0.87555150',
        type: null,
      },
      {
        id: 2337,
        name: 'Northern Ireland',
        state_code: 'NIR',
        latitude: '54.78771490',
        longitude: '-6.49231450',
        type: null,
      },
      {
        id: 2365,
        name: 'Northumberland',
        state_code: 'NBL',
        latitude: '55.20825420',
        longitude: '-2.07841380',
        type: null,
      },
      {
        id: 2456,
        name: 'Nottinghamshire',
        state_code: 'NTT',
        latitude: '53.10031900',
        longitude: '-0.99363060',
        type: null,
      },
      {
        id: 2477,
        name: 'Oldham',
        state_code: 'OLD',
        latitude: '42.20405980',
        longitude: '-71.20481190',
        type: null,
      },
      {
        id: 2314,
        name: 'Omagh District Council',
        state_code: 'OMH',
        latitude: '54.45135240',
        longitude: '-7.71250180',
        type: null,
      },
      {
        id: 2474,
        name: 'Orkney Islands',
        state_code: 'ORK',
        latitude: '58.98094010',
        longitude: '-2.96052060',
        type: null,
      },
      {
        id: 2353,
        name: 'Outer Hebrides',
        state_code: 'ELS',
        latitude: '57.75989180',
        longitude: '-7.01940340',
        type: null,
      },
      {
        id: 2321,
        name: 'Oxfordshire',
        state_code: 'OXF',
        latitude: '51.76120560',
        longitude: '-1.24646740',
        type: null,
      },
      {
        id: 2486,
        name: 'Pembrokeshire',
        state_code: 'PEM',
        latitude: '51.67407800',
        longitude: '-4.90887850',
        type: null,
      },
      {
        id: 2325,
        name: 'Perth and Kinross',
        state_code: 'PKN',
        latitude: '56.39538170',
        longitude: '-3.42835470',
        type: null,
      },
      {
        id: 2302,
        name: 'Poole',
        state_code: 'POL',
        latitude: '50.71505000',
        longitude: '-1.98724800',
        type: null,
      },
      {
        id: 2441,
        name: 'Powys',
        state_code: 'POW',
        latitude: '52.64642490',
        longitude: '-3.32609040',
        type: null,
      },
      {
        id: 2455,
        name: 'Reading',
        state_code: 'RDG',
        latitude: '36.14866590',
        longitude: '-95.98400120',
        type: null,
      },
      {
        id: 2527,
        name: 'Redcar and Cleveland',
        state_code: 'RCC',
        latitude: '54.59713440',
        longitude: '-1.07759970',
        type: null,
      },
      {
        id: 2443,
        name: 'Renfrewshire',
        state_code: 'RFW',
        latitude: '55.84665400',
        longitude: '-4.53312590',
        type: null,
      },
      {
        id: 2301,
        name: 'Rhondda Cynon Taf',
        state_code: 'RCT',
        latitude: '51.64902070',
        longitude: '-3.42886920',
        type: null,
      },
      {
        id: 2327,
        name: 'Rochdale',
        state_code: 'RCH',
        latitude: '53.60971360',
        longitude: '-2.15610000',
        type: null,
      },
      {
        id: 2308,
        name: 'Rotherham',
        state_code: 'ROT',
        latitude: '53.43260350',
        longitude: '-1.36350090',
        type: null,
      },
      {
        id: 2492,
        name: 'Royal Borough of Greenwich',
        state_code: 'GRE',
        latitude: '51.48346270',
        longitude: '0.05862020',
        type: null,
      },
      {
        id: 2368,
        name: 'Royal Borough of Kensington and Chelsea',
        state_code: 'KEC',
        latitude: '51.49908050',
        longitude: '-0.19382530',
        type: null,
      },
      {
        id: 2481,
        name: 'Royal Borough of Kingston upon Thames',
        state_code: 'KTT',
        latitude: '51.37811700',
        longitude: '-0.29270900',
        type: null,
      },
      {
        id: 2472,
        name: 'Rutland',
        state_code: 'RUT',
        latitude: '43.61062370',
        longitude: '-72.97260650',
        type: null,
      },
      {
        id: 2502,
        name: 'Saint Helena',
        state_code: 'SH-HL',
        latitude: '-15.96501040',
        longitude: '-5.70892410',
        type: null,
      },
      {
        id: 2493,
        name: 'Salford',
        state_code: 'SLF',
        latitude: '53.48752350',
        longitude: '-2.29012640',
        type: null,
      },
      {
        id: 2341,
        name: 'Sandwell',
        state_code: 'SAW',
        latitude: '52.53616740',
        longitude: '-2.01079300',
        type: null,
      },
      {
        id: 2335,
        name: 'Scotland',
        state_code: 'SCT',
        latitude: '56.49067120',
        longitude: '-4.20264580',
        type: null,
      },
      {
        id: 2346,
        name: 'Scottish Borders',
        state_code: 'SCB',
        latitude: '55.54856970',
        longitude: '-2.78613880',
        type: null,
      },
      {
        id: 2518,
        name: 'Sefton',
        state_code: 'SFT',
        latitude: '53.50344490',
        longitude: '-2.97035900',
        type: null,
      },
      {
        id: 2295,
        name: 'Sheffield',
        state_code: 'SHF',
        latitude: '36.09507430',
        longitude: '-80.27884660',
        type: null,
      },
      {
        id: 2300,
        name: 'Shetland Islands',
        state_code: 'ZET',
        latitude: '60.52965070',
        longitude: '-1.26594090',
        type: null,
      },
      {
        id: 2407,
        name: 'Shropshire',
        state_code: 'SHR',
        latitude: '52.70636570',
        longitude: '-2.74178490',
        type: null,
      },
      {
        id: 2427,
        name: 'Slough',
        state_code: 'SLG',
        latitude: '51.51053840',
        longitude: '-0.59504060',
        type: null,
      },
      {
        id: 2469,
        name: 'Solihull',
        state_code: 'SOL',
        latitude: '52.41181100',
        longitude: '-1.77761000',
        type: null,
      },
      {
        id: 2386,
        name: 'Somerset',
        state_code: 'SOM',
        latitude: '51.10509700',
        longitude: '-2.92623070',
        type: null,
      },
      {
        id: 2377,
        name: 'South Ayrshire',
        state_code: 'SAY',
        latitude: '55.45889880',
        longitude: '-4.62919940',
        type: null,
      },
      {
        id: 2400,
        name: 'South Gloucestershire',
        state_code: 'SGC',
        latitude: '51.52643610',
        longitude: '-2.47284870',
        type: null,
      },
      {
        id: 2362,
        name: 'South Lanarkshire',
        state_code: 'SLK',
        latitude: '55.67359090',
        longitude: '-3.78196610',
        type: null,
      },
      {
        id: 2409,
        name: 'South Tyneside',
        state_code: 'STY',
        latitude: '54.96366930',
        longitude: '-1.44186340',
        type: null,
      },
      {
        id: 2323,
        name: 'Southend-on-Sea',
        state_code: 'SOS',
        latitude: '51.54592690',
        longitude: '0.70771230',
        type: null,
      },
      {
        id: 2290,
        name: 'St Helens',
        state_code: 'SHN',
        latitude: '45.85896100',
        longitude: '-122.82123560',
        type: null,
      },
      {
        id: 2447,
        name: 'Staffordshire',
        state_code: 'STS',
        latitude: '52.87927450',
        longitude: '-2.05718680',
        type: null,
      },
      {
        id: 2488,
        name: 'Stirling',
        state_code: 'STG',
        latitude: '56.11652270',
        longitude: '-3.93690290',
        type: null,
      },
      {
        id: 2394,
        name: 'Stockport',
        state_code: 'SKP',
        latitude: '53.41063160',
        longitude: '-2.15753320',
        type: null,
      },
      {
        id: 2421,
        name: 'Stockton-on-Tees',
        state_code: 'STT',
        latitude: '54.57045510',
        longitude: '-1.32898210',
        type: null,
      },
      {
        id: 2393,
        name: 'Strabane District Council',
        state_code: 'STB',
        latitude: '54.82738650',
        longitude: '-7.46331030',
        type: null,
      },
      {
        id: 2467,
        name: 'Suffolk',
        state_code: 'SFK',
        latitude: '52.18724720',
        longitude: '0.97078010',
        type: null,
      },
      {
        id: 2526,
        name: 'Surrey',
        state_code: 'SRY',
        latitude: '51.31475930',
        longitude: '-0.55995010',
        type: null,
      },
      {
        id: 2422,
        name: 'Swindon',
        state_code: 'SWD',
        latitude: '51.55577390',
        longitude: '-1.77971760',
        type: null,
      },
      {
        id: 2367,
        name: 'Tameside',
        state_code: 'TAM',
        latitude: '53.48058280',
        longitude: '-2.08098910',
        type: null,
      },
      {
        id: 2310,
        name: 'Telford and Wrekin',
        state_code: 'TFW',
        latitude: '52.74099160',
        longitude: '-2.48685860',
        type: null,
      },
      {
        id: 2468,
        name: 'Thurrock',
        state_code: 'THR',
        latitude: '51.49345570',
        longitude: '0.35291970',
        type: null,
      },
      {
        id: 2478,
        name: 'Torbay',
        state_code: 'TOB',
        latitude: '50.43923290',
        longitude: '-3.53698990',
        type: null,
      },
      {
        id: 2496,
        name: 'Torfaen',
        state_code: 'TOF',
        latitude: '51.70022530',
        longitude: '-3.04460150',
        type: null,
      },
      {
        id: 2293,
        name: 'Trafford',
        state_code: 'TRF',
        latitude: '40.38562460',
        longitude: '-79.75893470',
        type: null,
      },
      {
        id: 2375,
        name: 'United Kingdom',
        state_code: 'UKM',
        latitude: '55.37805100',
        longitude: '-3.43597300',
        type: null,
      },
      {
        id: 2299,
        name: 'Vale of Glamorgan',
        state_code: 'VGL',
        latitude: '51.40959580',
        longitude: '-3.48481670',
        type: null,
      },
      {
        id: 2465,
        name: 'Wakefield',
        state_code: 'WKF',
        latitude: '42.50393950',
        longitude: '-71.07233910',
        type: null,
      },
      {
        id: 2338,
        name: 'Wales',
        state_code: 'WLS',
        latitude: '52.13066070',
        longitude: '-3.78371170',
        type: null,
      },
      {
        id: 2292,
        name: 'Walsall',
        state_code: 'WLL',
        latitude: '52.58621400',
        longitude: '-1.98291900',
        type: null,
      },
      {
        id: 2420,
        name: 'Warrington',
        state_code: 'WRT',
        latitude: '40.24927410',
        longitude: '-75.13406040',
        type: null,
      },
      {
        id: 2505,
        name: 'Warwickshire',
        state_code: 'WAR',
        latitude: '52.26713530',
        longitude: '-1.46752160',
        type: null,
      },
      {
        id: 2471,
        name: 'West Berkshire',
        state_code: 'WBK',
        latitude: '51.43082550',
        longitude: '-1.14449270',
        type: null,
      },
      {
        id: 2440,
        name: 'West Dunbartonshire',
        state_code: 'WDU',
        latitude: '55.94509250',
        longitude: '-4.56462590',
        type: null,
      },
      {
        id: 2354,
        name: 'West Lothian',
        state_code: 'WLN',
        latitude: '55.90701980',
        longitude: '-3.55171670',
        type: null,
      },
      {
        id: 2296,
        name: 'West Sussex',
        state_code: 'WSX',
        latitude: '50.92801430',
        longitude: '-0.46170750',
        type: null,
      },
      {
        id: 2391,
        name: 'Wiltshire',
        state_code: 'WIL',
        latitude: '51.34919960',
        longitude: '-1.99271050',
        type: null,
      },
      {
        id: 2482,
        name: 'Windsor and Maidenhead',
        state_code: 'WNM',
        latitude: '51.47997120',
        longitude: '-0.62425650',
        type: null,
      },
      {
        id: 2408,
        name: 'Wirral',
        state_code: 'WRL',
        latitude: '53.37271810',
        longitude: '-3.07375400',
        type: null,
      },
      {
        id: 2419,
        name: 'Wokingham',
        state_code: 'WOK',
        latitude: '51.41045700',
        longitude: '-0.83386100',
        type: null,
      },
      {
        id: 2510,
        name: 'Worcestershire',
        state_code: 'WOR',
        latitude: '52.25452250',
        longitude: '-2.26683820',
        type: null,
      },
      {
        id: 2436,
        name: 'Wrexham County Borough',
        state_code: 'WRX',
        latitude: '53.03013780',
        longitude: '-3.02614870',
        type: null,
      },
    ],
  },
};
