import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Wrapper from '../assets/wrappers/ImageCarousel';

const ImageCarousel = ({ gallery, sale_price, rent_price }) => {
  return (
    <Wrapper>
      <Carousel fade>
        {gallery &&
          gallery.map((gallery) => (
            <Carousel.Item key={gallery.url}>
              <img
                className='d-block w-100 '
                src={gallery.url}
                alt='First slide'
              />
              <Carousel.Caption>
                <div className='prices'>
                  {sale_price && <h3> Sale Price ${sale_price}</h3>}
                  {rent_price && <h3> Rent Price ${rent_price}</h3>}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    </Wrapper>
  );
};

export default ImageCarousel;
