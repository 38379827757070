import styled from 'styled-components';

const Wrapper = styled.div`
  .modalHeader {
    border: none !important;
    align-items: flex-end;
  }

  .modalFooter {
    border: none !important;
    color: #50821d;
  }
  .gallery {
    text-align: center;
  }

  h2 {
    text-align: center;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 1rem;
  }
  .rooms {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .paymentLocationGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export default Wrapper;
