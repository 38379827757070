import {
  db, collection, getDocs, onSnapshot, updateDoc, arrayUnion, arrayRemove, doc, addDoc, deleteDoc, deleteObject, ref, storage, uploadBytesResumable
  , getDownloadURL
} from '../../utils/firebase';
export const getAllNewsThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'news');
    const data = [];

    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });

    //reshape createdAt  because they are non-serializable
    // data.forEach((user) => {
    //   user.createdAt = new Date(
    //     user.createdAt.seconds * 1000
    //   ).toLocaleDateString('en-US');
    // });
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const getAllUpdateThunk = async (users, thunkAPI) => {
  try {
    const docRef = doc(db, 'news', users.id);
    const data = [];

    const querySnapshot = await updateDoc(docRef, users);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });


    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const addNavThunk = async (nav, thunkAPI) => {
  console.log('test',nav)
  try {
    const colRef = collection(db, 'news');

    await addDoc(colRef, nav)
      .then(() => {
        console.log("success")
      })
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const Usersupdate = (users) => {


  try {
    const docRef = doc(db, 'news', users.id);

    updateDoc(docRef, users)
      .then(() => {

        // })
      })

  } catch (err) {
    console.log(err)
  }


};

export const deleteNavThunk = async ({ docId }, thunkAPI) => {
  try {

    const docRef = doc(db, 'news', docId);
    deleteDoc(docRef)
      .then(() => {
        // Delete the file
        
          // File deleted successfully
          console.log('file deleted successfully');
        

      })



  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
