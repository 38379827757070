import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import { Col, Row, Form, Input, FormFeedback, Label } from 'reactstrap';

//galery
import { FileUploader } from "react-drag-drop-files"
import { deleteObject, ref } from "firebase/storage"
import { db, storage } from "../utils/firebase"
import {  uploadBytes, getDownloadURL } from "firebase/storage"


import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllUpdate, createProperty, uploadFiles, deleteStorageFile, handleRemoveImg,
  handleUpload,
} from '../features/allProperties/allPropertiesSlice';
import WorldMap from '../utils/world_js';
import { AllWorldMap } from '../utils/constData';
import Select from 'react-select'
import PhotoWidget from '../components/shared/PhotoWidget';
import { Loading } from '../components';

export default function PropertiesModalEdit({ show, setShow, data, title, action,name }) {
  const { user } = useSelector((store) => store.user);
  const { isAddModalOpen, isEditModalOpen, propertyTypes, subcategories, uploads, uploadEditImages, deletedImages, isLoading } = useSelector((store) => store.allProperties);
  const { amenities } = useSelector((store) => store.propertySettings);
  const [propertyTypesSelected, setPropertyTypesSelected] = useState([])
  const [propertyCatSelected, setPropertyCatSelected] = useState(data.type_subcategory)

  const handleClose = () => {
    setShow(false);
  };
  const [categories, setCategories] = useState([{ label: '', value: '' }]);
  const [country, setCountry] = useState(data ? data.country : { label: '', value: '' });
  const [city, setCity] = useState(data.caza)
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [amentiesSelected, setAmentiesSelected] = useState([])

//galery
  const fileTypes = ["JPG", "PNG", "GIF"]
  const [featuredImage, setFeaturedImage] = useState(null)
  const [featuredImagePath, setFeaturedImagePath] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([])
  const deleteThumbnail = () => {
    if (featuredImage.name) {
      setFeaturedImage(null)
      setFeaturedImagePath("")
    } else {
      deleteObject(ref(storage, featuredImagePath)).then(() => {
        setFeaturedImage(null)
        setFeaturedImagePath("")
      })
    }
  }
  function randomName() {
    var text = ""
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }
  const uploadThumbnailHandler = file => {
    setFeaturedImage(file)
    const random = randomName()
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`)
  }
  const uploadThumbnailProcess = () => {
    return new Promise(resolve => {
      if (featuredImage.name) {
        const reference = ref(storage, featuredImagePath)
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then(url => {
            resolve({ url: url, reference: featuredImagePath })
          })
        })
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath })
      }
    })
  }
  const deleteGalleryFile = index => {
    if (galleryImages[index].name) {
      const fileArray = [...galleryImages]
      const pathArray = [...galleryImagesPaths]
      fileArray.splice(index, 1)
      pathArray.splice(index, 1)
      setGalleryImages(fileArray)
      setGalleryImagesPaths(pathArray)
    } else {
      deleteObject(ref(storage, galleryImagesPaths[index])).then(() => {
        const fileArray = [...galleryImages]
        const pathArray = [...galleryImagesPaths]
        fileArray.splice(index, 1)
        pathArray.splice(index, 1)
        setGalleryImages(fileArray)
        setGalleryImagesPaths(pathArray)
      })
    }
  }
  const uploadGalleryHandler = files => {
    let processed = 0
    if (files.length + galleryImages.length > 5) {
      alert("You can only insert 5 images")
      return
    }
    const oldFiles = [...galleryImages]
    const oldPaths = [...galleryImagesPaths]
    const newFiles = [...files]

    newFiles.forEach(file => {
      oldFiles.push(file)
      const random = randomName()
      oldPaths.push(`images/property/gallery/${random}${file.name}`)
      processed++
      if (processed === files.length) {
        setGalleryImages(oldFiles)
        setGalleryImagesPaths(oldPaths)
      }
    })
  }
  const uploadGalleryProcess = async () => {
    return new Promise(resolve => {
      const gallery = [...galleryImages]
      let processed = 0
      const arrayToBeUploaded = []
      if (gallery.length === 0) {
        resolve([])
      }
      gallery.forEach((image, index) => {
        if (image.name) {
          const reference = ref(storage, galleryImagesPaths[index])
          uploadBytes(reference, image).then(() => {
            getDownloadURL(reference).then(url => {
              arrayToBeUploaded.push({
                url: url,
                reference: galleryImagesPaths[index],
              })
              processed++
              if (processed === gallery.length) {
                resolve(arrayToBeUploaded)
              }
            })
          })
        } else {
          arrayToBeUploaded.push({
            url: galleryImages[index],
            reference: galleryImagesPaths[index],
          })
          processed++
          if (processed === gallery.length) {
            resolve(arrayToBeUploaded)
          }
        }
      })
    })
  }


  const dispatch = useDispatch();

  const options = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ]

  const fixCategories = val => {
    let allKeys = [];
    subcategories.forEach(tp => {
      let key = Object.keys(tp)[0];
      allKeys.push(key);
    });

    allKeys.forEach((key, index) => {
      if (key === val) {
        setCategories(subcategories[index][val]);
      }
    });
  };
  if (isLoading) {
    return <Loading />;
  }
  useEffect(() => {
    if (data.type)
      fixCategories(data.type.value)
  }, [show]);

  const validation = useFormik({

    initialValues: {
      type: (data && data.type.value) || '',
      type_subcategory: (data && data.type_subcategory.value) || '',
      country: (data && data.country.value) || '',
      caza: (data && data.caza.value) || '',
      sale_price: (data && data.sale_price) || '',
      insurance: (data && data.insurance.value) || '',
      rent_price: (data && data.rent_price) || '',
      checkedAmenities: (data && data.checkedAmenities.value) || '',
      after_repair_value: (data && data.after_repair_value) || '',
      apartments_per_floor: (data && data.apartments_per_floor) || '',
      property_management: (data && data.property_management.value) || '',
      area: (data && data.area) || '',
      built_tax: (data && data.built_tax) || '',
      cap_rate: (data && data.cap_rate) || '',
      description: (data && data.description) || '',
      isDraft: (data && data.isDraft) || '',
      latitude: (data && data.latitude) || '',
      legal_status: (data && data.legal_status) || '',
      num_balcony: (data && data.num_balcony) || '',
      num_bathrooms: (data && data.num_bathrooms) || '',
      num_bedrooms: (data && data.num_bedrooms) || '',
      num_dining_rooms: (data && data.num_dining_rooms) || '',
      num_floors: (data && data.num_floors) || '',
      num_living_room: (data && data.num_living_room) || '',
      num_salons: (data && data.num_salons) || '',
      title: (data && data.title) || '',
      town: (data && data.town) || '',
      street_name: (data && data.street_name) || '',
      featured_image: (data && data.featured_image) || '',
      gallery: (data && data.gallery) || '',
      description:(data && data.description) || ''
    },

    validationSchema: Yup.object({
      type: Yup.string().required('The Type Name is required'),
      type_subcategory: Yup.string().required('The type subcategory Name is required'),
      country: Yup.string().required('The Type Name is required'),
      caza: Yup.string().required('The Type Name is required'),

      insurance: Yup.string().required('The Type Name is required'),

      description:Yup.string().required('The Type Name is required')
      // featured_image: Yup.string().required('The image is required'),
      // checkedAmenities: Yup.string().required('The Type Name is required'),
      // after_repair_value: Yup.string().required('The Type Name is required'),
      // apartments_per_floor: Yup.string().required('The Type Name is required'),
      // property_management: Yup.string().required('The Type Name is required'),
      // area: Yup.string().required('The Type Name is required'),
      // built_tax: Yup.string().required('The Type Name is required'),
      // cap_rate: Yup.string().required('The Type Name is required'),
      // description: Yup.string().required('The Type Name is required'),
      // isDraft: Yup.string().required('The Type Name is required'),
      // legal_status: Yup.string().required('The Type Name is required'),
      // num_balcony: Yup.string().required('The Type Name is required'),
      // num_bathrooms: Yup.string().required('The Type Name is required'),
      // num_bedrooms: Yup.string().required('The Type Name is required'),
      // num_dining_rooms: Yup.string().required('The Type Name is required'),
      // num_floors: Yup.string().required('The Type Name is required'),
      // num_living_room: Yup.string().required('The Type Name is required'),
      // num_salons: Yup.string().required('The Type Name is required'),
      // title: Yup.string().required('The Type Name is required'),
      // town: Yup.string().required('The Type Name is required'),
      // street_name: Yup.string().required('The Type Name is required'),
    }),

    onSubmit: async (values) => {
      if (isAddModalOpen) {
        const imgUrls = await Promise.all(

          [...uploads].map((image, index) => {
            if (index === 0) {
              return dispatch(uploadFiles({ image: uploads[0], urlStorage: 'images/property/thumbnail/' }));
            } else
              return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        var result = imgUrls.map(({ payload }) => payload)
      }


      if (isEditModalOpen && deletedImages) {
        if (uploads.length >= 2) {
          deletedImages.map((image) => {
            dispatch(deleteStorageFile(image))
          })
          result = uploads
        }

      }

      if (isEditModalOpen && uploadEditImages) {
        const imgUrls = await Promise.all(

          [...uploadEditImages].map((image, index) => {

            return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        result = imgUrls.map(({ payload }) => payload)
        result = [...uploads, ...result]

      }

      let thumbnailUpload, galleryUpload
      uploadThumbnailProcess().then(value => {
        thumbnailUpload = value
        uploadGalleryProcess().then(value => {
          galleryUpload = value
          console.log('values',values)
        const properties = {
          sale_price: values.sale_price,
          rent_price: values.rent_price,
          type: { label: values.type, value: values.type },
          country: { label: values.country, value: values.country },
          type_subcategory: { label: values.type_subcategory, value: values.type_subcategory },
          caza: values.caza,
          checkedAmenities: amentiesSelected,
          insurance: { label: values.insurance, value: values.insurance },
          latitude: lat,
          longitude: long,
          id: data ? data.id : 0,
          after_repair_value: values.after_repair_value,
          apartments_per_floor: values.apartments_per_floor,
          description:value.description,
          area: values.area,
          built_tax: values.built_tax,
          cap_rate: values.cap_rate,
          description: values.description,
          isDraft: values.isDraft,

          legal_status: values.legal_status,
          num_balcony: ''+ values.num_balcony,
          num_bathrooms:''+ values.num_bathrooms,
          num_bedrooms: ''+ values.num_bedrooms,
          num_dining_rooms:''+ values.num_dining_room,
          num_floors: ''+values.num_floors,
          num_living_room: ''+values.num_living_room,
          num_salons: ''+values.num_salons,
          title: values.title,
          town: values.town,
          street_name: values.street_name,
          addedAmenities: amentiesSelected,
          added_by: 'Eu1C48QIm4QW6m6xQ5oA4LgFOVt2',
          featured_image: thumbnailUpload,
          gallery: galleryUpload,
          property_management: {},
        };
      console.log("values", values)
      console.log("prop", properties)
      if(name==='add'){
        console.log('properties',properties)
        dispatch(action(properties));
      }
      else{
        dispatch(action(properties));
      }
    })
  })


      setShow(false);
    },
  
  });
  




  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>{title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row form='true'>
              <Col className='col-12'>


                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Type</Label>
                      <Select
                        options={propertyTypes}
                        placeholder="Select Type"
                        name="type"
                        defaultValue={data.type}
                        onChange={e => {
                          // Call default Formik handleChange()
                          validation.handleChange("type")(e.value);
                          setPropertyTypesSelected(e.value)
                          setPropertyCatSelected(null)
                          fixCategories(e.value)
                        }}
                      />
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Category</Label>
                      <Select
                        placeholder="Select Category"
                        options={categories}
                        name='type_subcategory'
                        value={propertyCatSelected}
                        defaultValue={propertyCatSelected}
                        onChange={e => {
                          // Call default Formik handleChange()
                          validation.handleChange("type_subcategory")(e.value);

                          setPropertyCatSelected(e)
                        }}

                      />

                      {validation.touched.type_subcategory &&
                        validation.errors.type_subcategory ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Country</Label>
                      <Select
                        options={WorldMap.countries}
                        placeholder="Select Country"
                        name="country"
                        defaultValue={data.country}
                        onChange={item => {

                          if (item.label !== country.label) {
                            setCountry({ label: item.label, value: item.value });
                            setLong(item.longitude)
                            setLat(item.latitude)
                            setCity(null);
                            validation.handleChange("country")(item.value);
                          }
                        }}
                      />
                      {validation.touched.country &&
                        validation.errors.country ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>City</Label>

                      <Select
                        placeholder="Select City"
                        name="caza"
                        options={
                          country.label === '' ? [] : AllWorldMap[country.label].states.map(item => ({ label: item.name, value: item.name }))
                        }
                        // defaultValue={data.caza ? data.caza : ''}
                        value={city}
                        defaultValue={city}
                        onChange={item => {
                          validation.handleChange("caza")(item.value);
                          setCity(item);
                        }}
                      />
                      {validation.touched.caza &&
                        validation.errors.caza ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.caza}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Sale price</Label>
                      <Input
                        name='sale_price'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sale_price}
                        invalid={
                          validation.touched.sale_price &&
                            validation.errors.sale_price
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sale_price &&
                        validation.errors.sale_price ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.sale_price}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>rent_price</Label>
                      <Input
                        name='rent_price'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rent_price || ""}
                        invalid={
                          validation.touched.rent_price &&
                            validation.errors.rent_price
                            ? true
                            : false
                        }
                      />

                      {validation.touched.rent_price &&
                        validation.errors.rent_price ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.rent_price}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  {/* <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Currency</Label>
                      <Input
                        name='currency_price'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.currency_price}
                        invalid={
                          validation.touched.currency_price &&
                            validation.errors.currency_price
                            ? true
                            : false
                        }
                      />
                      {validation.touched.currency_price &&
                        validation.errors.currency_price ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.currency_price}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col> */}

                </Row>
                <Row>
                <Col className='col-12'>
                    <div className='mb-3'>
                      <Label className='form-label'>description</Label>
                      <Input
                        name='description'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description}
                        invalid={
                          validation.touched.description &&
                            validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  
                </Row>

                <Row>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Insurance</Label>
                      <Select
                        name="insurance"
                        options={options}
                        defaultValue={data.insurance ? data.insurance : ''}
                        onChange={e => {
                          validation.handleChange("insurance")(e.value);

                        }}
                      />
                      {validation.touched.insurance &&
                        validation.errors.insurance ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>checked Amenities</Label>
                      <Select
                        name="checkedAmenities"
                        options={
                          amenities.map(item => ({ title: item.title, value: item.value }))
                        }
                        isMulti
                        defaultValue={data.checkedAmenities ? data.checkedAmenities : ''}
                        onChange={e => {
                          setAmentiesSelected(e)
                          console.log(e)
                          // validation.handleChange("checkedAmenities")(e.value);
                        }}
                      />
                      {validation.touched.checkedAmenities &&
                        validation.errors.checkedAmenities ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>

                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>After Repair Value</Label>
                      <Input
                        name='after_repair_value'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.after_repair_value || ""}
                        invalid={
                          validation.touched.after_repair_value &&
                            validation.errors.after_repair_value
                            ? true
                            : false
                        }
                      />

                      {validation.touched.after_repair_value &&
                        validation.errors.after_repair_value ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.after_repair_value}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>area</Label>
                      <Input
                        name='area'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.area || ""}
                        invalid={
                          validation.touched.area && validation.errors.area
                            ? true
                            : false
                        }
                      />

                      {validation.touched.area && validation.errors.area ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.area}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>town</Label>
                      <Input
                        name='town'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.town}
                        invalid={
                          validation.touched.town && validation.errors.town
                            ? true
                            : false
                        }
                      />

                      {validation.touched.town && validation.errors.town ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.town}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Street Name</Label>
                      <Input
                        name='street_name'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.street_name}
                        invalid={
                          validation.touched.street_name &&
                            validation.errors.street_name
                            ? true
                            : false
                        }
                      />

                      {validation.touched.street_name &&
                        validation.errors.street_name ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.street_name}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className='font'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Balcony</Label>
                      <Input
                        name='num_balcony'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_balcony || ""}
                        invalid={
                          validation.touched.num_balcony &&
                            validation.errors.num_balcony
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_balcony &&
                        validation.errors.num_balcony ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_balcony}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>

                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Bathrooms</Label>
                      <Input
                        name='num_bathrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bathrooms || ""}
                        invalid={
                          validation.touched.num_bathrooms &&
                            validation.errors.num_bathrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.after_repair_value &&
                        validation.errors.after_repair_value ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.after_repair_value}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Bedrooms</Label>
                      <Input
                        name='num_bedrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bedrooms || ""}
                        invalid={
                          validation.touched.num_bedrooms &&
                            validation.errors.num_bedrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_bedrooms &&
                        validation.errors.num_bedrooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_bedrooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Floors</Label>
                      <Input
                        name='num_floors'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_floors}
                        invalid={
                          validation.touched.num_floors &&
                            validation.errors.num_floors
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_floors &&
                        validation.errors.num_floors ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_floors}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>

                </Row>

                <Row className='font'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Dining Rooms</Label>
                      <Input
                        name='num_dining_rooms'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_dining_rooms}
                        invalid={
                          validation.touched.num_dining_rooms &&
                            validation.errors.num_dining_rooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_dining_rooms &&
                        validation.errors.num_dining_rooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Living Room</Label>
                      <Input
                        name='num_living_room'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_living_room}
                        invalid={
                          validation.touched.num_living_room &&
                            validation.errors.num_living_room
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_living_room &&
                        validation.errors.num_living_room ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Salons</Label>

                      <Input
                        name='num_salons'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_salons}
                        invalid={
                          validation.touched.num_salons &&
                            validation.errors.num_salons
                            ? true
                            : false
                        }
                      />
                      {validation.touched.num_salons &&
                        validation.errors.num_salons ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_salons}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='bg-blue mb-4'>
                      <label className='form-label'>Featured and Gallery Images</label>
                      <div>

                    <FileUploader
                      classes="dropzone"
                      handleChange={uploadThumbnailHandler}
                      name="file"
                      types={fileTypes}
                    />
                        
                    {featuredImage && (
                      <div
                        className="thumbnail-container"
                        onClick={deleteThumbnail}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={
                            featuredImage.name
                              ? URL.createObjectURL(featuredImage)
                              : featuredImage
                          }
                        />
                      </div>
                    )}
                  
                      </div>

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
              
            <FileUploader
                multiple={true}
                classes="dropzone"
                handleChange={uploadGalleryHandler}
                name="file"
                types={fileTypes}
              />
              {
                <div galleryImages={galleryImages}>
                  {galleryImages.map((image, index) => {
                    return (
                      <div
                        className="thumbnail-container"
                        key={index}
                        onClick={() => {
                          deleteGalleryFile(index)
                        }}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={image.name ? URL.createObjectURL(image) : image}
                        />
                      </div>
                    )
                  })}
                </div>
              }
                </Col>
              </Row>
            <Row>
              <Col>
                <div className='text-end pb-4'>
                  <button type='submit' className='btn btn-success  btn-save'>
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
