import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDocs,
  query,
  where,

} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getStorage,
} from 'firebase/storage';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,

} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB3evkZwcjf3J0fT5biJiJ9AMUAfh6fLO0',
  authDomain: 'opddev-51cfb.firebaseapp.com',
  projectId: 'opddev-51cfb',
  storageBucket: 'opddev-51cfb.appspot.com',
  messagingSenderId: '373784165590',
  appId: '1:373784165590:web:d661b0f08e90a4fe15dd77',
  measurementId: 'G-7MDHWJN4KV',
};

// Initialize Firebase
initializeApp(firebaseConfig);

// init services
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();
export {
  auth,
  db,
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  onAuthStateChanged,
  getDocs,
  query,
  where,
  storage,
};
