import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllNavThunk, getAllUpdateThunk, addNavThunk, getCatPropertyThunk, getTypePropertyThunk, deleteNavThunk, uploadFilesThunk, RemoveFileThunk, removeFromArrayThunk } from './allNavThunk';
// import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  isViewPropertyModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  properties: [],
  propertyTypes: [],
  subcategories: [],
  type: [],
  cat: [],
  uploads: [],
  deletedImages: [],
  uploadEditImages: [],
  currentProperty: null,
  reload: "",
};
export const getAllNav = createAsyncThunk(
  'allProperties/getProperties',
  getAllNavThunk
);
// export const getTypeProperty = createAsyncThunk(
//   'allProperties/getTypeProperty',
//   getTypePropertyThunk
// );
// export const getCatProperty = createAsyncThunk(
//   'allProperties/getCatProperty',
//   getCatPropertyThunk
// );
export const getAllUpdate = createAsyncThunk(
  'allProperties/getAllUpdate',
  getAllUpdateThunk
);
export const createNav = createAsyncThunk(
  'allProperties/createProperty',
  addNavThunk
);
export const uploadFiles = createAsyncThunk(
  'allProperties/uploadFiles',
  uploadFilesThunk
);
export const deleteNav = createAsyncThunk(
  'allProperties/deleteProperty',
  deleteNavThunk
);
// export const deleteStorageFile = createAsyncThunk(
//   'allProperties/deleteStorageFile',
//   RemoveFileThunk
// );

// export const removeFromArray = createAsyncThunk(
//   'allProperties/removeFromArray',
//   removeFromArrayThunk
// );

const allNavSlice = createSlice({
  name: 'allProperties',
  initialState,
  reducers: {
    handleAddNav: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentProperty = '';
    },
    handleViewNav: (state, { payload }) => {
      state.isViewPropertyModalOpen = !state.isViewPropertyModalOpen;

      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleEditNav: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      console.log('test teste',state.isEditModalOpen);
      if (payload) {
        state.currentProperty = payload;
        // state.uploads = [payload.featured_image, ...payload.gallery]
      }
    },
    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleUpload: (state, { payload }) => {
      if (payload) {
        if (state.isAddModalOpen) {
          state.uploads = payload;

        }

        if (state.isEditModalOpen) {

          state.uploadEditImages = payload;

        }
      }


    },
    handleRemoveImg: (state, { payload }) => {
      if (payload) {

        const index = state.uploads.findIndex(object => {
          return object.url === payload.url;
        });

        state.uploads.splice(index, 1);


        state.deletedImages = [...state.deletedImages, payload]

      }

    },
  },

  extraReducers: {
    [getAllNav.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllNav.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.properties = payload;
    },
    [getAllNav.rejected]: (state, { payload }) => {
      state.isLoading = false;

    },
    // [getTypeProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getTypeProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.propertyTypes = payload;
    // },
    // [getTypeProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    // [getCatProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getCatProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.subcategories = payload;
    // },
    // [getCatProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    [getAllUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
      state.uploads = []
      state.deletedImages = [];
      state.uploadEditImages = [];
    },
    [getAllUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createNav.pending]: (state) => {
      state.isLoading = true;
    },
    [createNav.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
    },
    [createNav.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [uploadFiles.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadFiles.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
    },
    [uploadFiles.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deleteNav.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteNav.fulfilled]: (state, { payload }) => {

      state.isLoading = false;
    },
    [deleteNav.rejected]: (state, { payload }) => {

      state.isLoading = false;
    },
    // [deleteStorageFile.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [deleteStorageFile.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [deleteStorageFile.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [removeFromArray.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
  },
});

export const { clearFilters, handleChange, handleEditNav, handleViewNav, handleViewThumbnail, handleDelete, handleUpload, handleAddNav, handleRemoveImg } = allNavSlice.actions;
export default allNavSlice.reducer;