import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllUsersThunk, getAllUpdateThunk,deleteUserThunk } from './allUsersThunk';
const initialState = {
  isLoading: true,
  users: [],
  isViewUserModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalUserOpen: false,
  isDeleteModalOpen: false,
  currentUser: null,
  EdiUserClicked: [],
  deleteuser: [],
  Thumbnail: null,
};
export const getAllUsers = createAsyncThunk(
  'allUsers/getUsers',
  getAllUsersThunk
);
export const getAllUpdate = createAsyncThunk(
  'allusers/getAllUpdate',
  getAllUpdateThunk
);
export const deleteUser= createAsyncThunk(
  'allusers/deleteUser',
  deleteUserThunk
);

const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {

    showLoading: (state) => {
      state.isLoading = true;
    },


    clearAllUsersState: (state) => initialState,


    handleViewUser: (state, { payload }) => {
      state.isViewUserModalOpen = !state.isViewUserModalOpen;
      if (payload) {
        state.currentUser = payload;
      }
    },

    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.Thumbnail = payload;
      }
    },

    handleEdit: (state, { payload }) => {
      state.isEditModalUserOpen = !state.isEditModalUserOpen;
      if (payload) {
        state.EdiUserClicked = payload
      }
    },

    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      console.log('test 1',payload)
      if (payload) {
        state.deleteuser = payload;
      }
    },

  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      const users = payload;

      state.isLoading = false;
      state.users = users;
    },
    [getAllUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getAllUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.reload = payload;
    },
    [getAllUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteUser.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.reload = payload;
      console.log('test',payload)
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
      console.log('error',payload);
    },
  },
});

export const { showLoading, clearAllUsersState, handleViewUser, handleViewThumbnail, handleEdit, handleDelete } =
  allUsersSlice.actions;

export default allUsersSlice.reducer;
