import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addEventThunk, getAllEventsThunk, updateEventThunk,deleteEventThunk } from './allEventsThunk';
const initialState = {
  isLoading: true,
  events: [],
  isViewModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  uploads: [],
  deletedImages: [],
  uploadEditImages: [],
  currentEvent: null,
  dataClicked: [],
  deleteEvent: [],
  Thumbnail: null,
};
export const getAllEvents = createAsyncThunk(
  'allEvents/getEvents',
  getAllEventsThunk
);
export const updateEvent = createAsyncThunk(
  'allEvents/updateEvent',
  updateEventThunk
);
export const addEvent = createAsyncThunk(
  'allEvents/addEvent',
  addEventThunk
);
export const deleteProperty = createAsyncThunk(
  'allEvents/deleteProperty',
  deleteEventThunk
);

const allEventsSlice = createSlice({
  name: 'allEvents',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    handleAddEvent: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentEvent = '';
    },
    handleEditEvent: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      if (payload) {
        state.currentEvent = payload
      }
    },
    handleViewModal: (state, { payload }) => {
      state.isViewModalOpen = !state.isViewModalOpen;
      if (payload) {
        state.currentEvent = payload;
      }
    },
    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.Thumbnail = payload;
      }
    },
    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      if (payload) {
        state.currentEvent = payload;
      }
    },
    handleUpload: (state, { payload }) => {
      if (payload) {
        if (state.isAddModalOpen) {
          state.uploads = payload;

        }

        if (state.isEditModalOpen) {

          state.uploadEditImages = payload;

        }
      }


    },
    handleRemoveImg: (state, { payload }) => {
      if (payload) {

        const index = state.uploads.findIndex(object => {
          return object.url === payload.url;
        });

        state.uploads.splice(index, 1);


        state.deletedImages = [...state.deletedImages, payload]

      }

    },

  },
  extraReducers: {
    [getAllEvents.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllEvents.fulfilled]: (state, { payload }) => {
      if (payload) {
        console.log("events", payload)
        const events = payload;
        state.events = events;
      }
      state.isLoading = false;

    },
    [getAllEvents.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [updateEvent.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEvent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.reload = payload;
    },
    [updateEvent.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [addEvent.pending]: (state) => {
      state.isLoading = true;
    },
    [addEvent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.reload = payload;
    },
    [addEvent.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteProperty.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteProperty.fulfilled]: (state, { payload }) => {

      state.isLoading = false;
    },
    [deleteProperty.rejected]: (state, { payload }) => {

      state.isLoading = false;
    },
  },
});

export const { showLoading, handleViewModal, handleViewThumbnail, handleEditEvent, handleAddEvent, handleDelete, handleRemoveImg, handleUpload } =
  allEventsSlice.actions;

export default allEventsSlice.reducer;
