import styled from 'styled-components';

const Wrapper = styled.div`
  h3 {
    color: white;
    margin-bottom: 0.2rem;
  }

  .carousel-caption {
    position: absolute;
    left: 3%;
    right: 3%;
    bottom: -0.75rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: start;
  }
  .prices {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export default Wrapper;
