import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading, MySearch, MyExportCSV } from "../../components";
import EditEvents from "../../modals/EditEvents";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import Wrapper from "../../assets/wrappers/AllProperties";
import ViewMoreModal from "../../modals/ViewMoreModal";
import ConfirmModalNav from "../../modals/ConfirmModalNav";
import icon from "../../assets/images/edit-big.svg";
import PreviewThumbnailModal from "../../modals/PreviewThumbnailModal";
import logo from "../../assets/images/logo.png";
import {
  addEvent,
  getAllEvents,
  handleViewModal,
  handleEditEvent,
  handleAddEvent,
  handleDelete,
  handleViewThumbnail,
  updateEvent,
  deleteProperty,
} from "../../features/allEvents/allEventsSlice";
import Faceboook from "../../assets/images/facebook-share.svg";
import Twitter from "../../assets/images/twitter-share.svg";
import Linkedin from "../../assets/images/linkedin-share.svg";
import Whatsapp from "../../assets/images/whatsapp-icon.svg";
import telegram from "../../assets/images/telegram_logo_circle_icon.svg";
import email from "../../assets/images/email-envelop.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from "../../utils/Table";
import ViewEventsModal from "../../modals/ViewEventsModal";

const Events = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    events,
    isViewModalOpen,
    isDeleteModalOpen,
    isEditModalOpen,
    isAddModalOpen,
    isViewThumbnailModalOpen,
    currentEvent,
    deleteEvent,
    Thumbnail,
  } = useSelector((store) => store.allEvents);

  useEffect(() => {
    dispatch(getAllEvents(events));
  }, [isEditModalOpen]);

  const columns = [
    {
      dataField: "thumbnail.url",
      text: " ",
      formatter: (cellContent, event) => (
        <div className="thumbnail-container">
          <img
            className="thumbnail"
            src={cellContent || logo}
            onClick={() => dispatch(handleViewThumbnail(cellContent))}
            alt="home"
          />
        </div>
      ),
    },
    {
      dataField: "added_by",
      text: "Added By",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "country.value",
      text: "Country",
    },

    {
      dataField: "topic",
      text: "Topic",
    },
    {
      dataField: "share",
      isDummyField: true,
      text: "share",
      formatter: (cellContent, row) => {
        console.log("row", row);
        return (
          <div className="d-flex gap-3">
            <FacebookShareButton
              url={"news-detail/" + row.id}
              title="Facebook"
              hashtag="#property"
            >
              <img
                src={Faceboook}
                alt="facebook icon"
                style={{ width: "20px" }}
              />
            </FacebookShareButton>
            <TwitterShareButton url={"news-detail/" + row.id} title="Twitter">
              <img src={Twitter} alt="twitter icon" style={{ width: "20px" }} />
            </TwitterShareButton>
            <LinkedinShareButton url={"news-detail/" + row.id} title="Linkedin">
              <img
                src={Linkedin}
                alt="linkedin icon"
                style={{ width: "20px" }}
              />
            </LinkedinShareButton>
            <WhatsappShareButton url={"news-detail/" + row.id} title="Whatsapp">
              <img
                src={Whatsapp}
                alt="whatsapp icon"
                style={{ width: "20px" }}
              />
            </WhatsappShareButton>
            <TelegramShareButton url={"news-detail/" + row.id} title="telegram">
              <img
                src={telegram}
                alt="whatsapp icon"
                style={{ width: "20px" }}
              />
            </TelegramShareButton>
            <EmailShareButton
              url={"news-detail/" + row.id}
              subject="share page"
              body={"this is link shared"}
            >
              <img src={email} alt="whatsapp icon" style={{ width: "20px" }} />
            </EmailShareButton>
          </div>
        );
      },
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, event) => (
        <>
          <div className="d-flex gap-3">
            {event.name !== "test" ? (
              <>
                <MdEdit
                  onClick={() => {
                    dispatch(handleEditEvent(event));
                  }}
                />
                <AiFillEye onClick={() => dispatch(handleViewModal(event))} />
                <BsFillTrashFill
                  onClick={() => dispatch(handleDelete(event))}
                />
              </>
            ) : (
              <div></div>
            )}
          </div>
        </>
      ),
    },
  ];

  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: events.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: "first_name",
      order: "asc",
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (events.length === 0) {
    return <h2>No events to display...</h2>;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" data={events} columns={columns} search>
            {(props) => (
              <>
                <div className="d-flex justify-content-between">
                  <h5>
                    {events.length} EVENT{events.length > 1 && "S"} FOUND
                  </h5>
                  <div className="d-flex">
                    <MySearch {...props.searchProps} />

                    <button
                      className="add-btn ms-2 me-2"
                      onClick={() => dispatch(handleAddEvent())}
                    >
                      Add Event
                    </button>
                    <MyExportCSV {...props.csvProps} />
                  </div>
                </div>
                <BootstrapTable
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className="row">
                  <div className="col pagination pagination-rounded justify-content-end ">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {isEditModalOpen && (
        <EditEvents
          setShow={() => dispatch(handleEditEvent())}
          show={isEditModalOpen}
          data={currentEvent}
          title="edit event"
          name="edit"
          action={updateEvent}
        />
      )}

      {isAddModalOpen && (
        <EditEvents
          setShow={() => dispatch(handleAddEvent())}
          show={isAddModalOpen}
          data={currentEvent}
          title="Add Event"
          name="add"
          action={addEvent}
        />
      )}

      {isDeleteModalOpen && (
        <ConfirmModalNav
          setShow={() => dispatch(handleDelete())}
          show={isDeleteModalOpen}
          title="Delete Event"
          bodyText="Are you sure you want to delete this Event?"
          icon={icon}
          color="danger"
          handleAction={deleteProperty}
          id={currentEvent.id}
        />
      )}
      {isViewModalOpen && (
        <ViewEventsModal
          setShow={() => dispatch(handleViewModal())}
          show={isViewModalOpen}
          data={currentEvent}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={Thumbnail}
        />
      )}
    </Wrapper>
  );
};
export default Events;
