import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Wrapper from '../assets/wrappers/ViewUserModal';
import profile from '../assets/images/profile.jpg';

const ViewEventsModal = ({ show, setShow, data }) => {
  const { added_by, added_on, attended_by, city, country, date, name, thumbnail, topic, viewed_by } =
    data;
  const handleClose = () => {
    setShow(false);
  };
  // const adjustedDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='lg'
      dialogClassName='View More Modal'
      // aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Wrapper>
        <Modal.Header className='modalHeader' closeButton>
          {thumbnail ? (
            <img className='profile' src={thumbnail.url} alt='profile' />
          ) : (
              <img className='profile' src={profile} alt='profile' />
            )}
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <h5 className='name text-center'>
              <b>{name}</b>  &nbsp; {new Date(date.seconds * 1000 + date.nanoseconds / 1000000).toLocaleDateString()}
            </h5>
            <div className='mb-3'>
              <h4 className='mb-0'>Topic</h4>
              <span>{topic}</span>
            </div>
            <div className='mb-3'>
              <h4 className='mb-0'>Place</h4>
              <span>{city}, {country.label}</span>
            </div>
            <div className='mb-3'>
              <span>
                <h4 className='mb-0'>Added on</h4>
                <span>{new Date(added_on.seconds * 1000 + added_on.nanoseconds / 1000000).toLocaleDateString()}</span>
              </span>
            </div>
            {/* <div className='mb-3'>
              <h4 className='mb-0'>Attended by</h4>
              <span>{added_by}</span>
            </div> */}
            <div className='mb-3'>
              <h4 className='mb-0'>Added by</h4>
              <span>{added_by}</span>
            </div>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default ViewEventsModal;
