import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, MyExportCSV } from '.';
import { getPropertyTypes, handleAddProperty, handleDeleteProperty, handleEditProperty, handleViewProperty, handleViewPropertyThumbnail } from '../features/PropertySettings/PropertySettingsSlice';
import { MdEdit } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from '../utils/Table';
import Wrapper from '../assets/wrappers/PropertyType';
import ConfirmModal from '../modals/ConfirmModal';
import PreviewThumbnailModal from '../modals/PreviewThumbnailModal';
import icon from '../assets/images/edit-big.svg';
import logo from '../assets/images/logo.png';
import ViewPropertyTypesModal from '../modals/ViewPropertyTypesModal';
import EditPropertyType from '../modals/EditPropertyType';


const PropertyType = () => {
  const dispatch = useDispatch();
  const { isLoading, property_types, isViewPropertyModalOpen, isDeleteModalOpen, isAddModalOpen, isEditModalOpen, isViewThumbnailModalOpen, currentPropertyType } = useSelector(
    (store) => store.propertySettings
  );

  useEffect(() => {
    dispatch(getPropertyTypes());
  }, []);

  const columns = [
    {
      dataField: 'image',
      text: ' ',
      formatter: (cellContent, event) => (
        <div className='thumbnail-container'>
          <img
            className='thumbnail'
            src={cellContent ? cellContent : logo}
            onClick={() => dispatch(handleViewPropertyThumbnail(cellContent))}
            alt='home'
          />
        </div>
      ),
    },
    {
      dataField: 'title',
      text: 'title',
      sort: true,
    },

    {
      dataField: 'subcategories',
      text: 'Subcategories',
      formatter: (cellContent, event) => (
        <div className='d-flex gap-3'>
          {cellContent.map((subcategory, index) => (
            <span key={index} className='text-success'>
              {subcategory.label}
            </span>
          ))}
        </div>
      ),
    },

    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      formatter: (cellContent, row) => (
        <>
          <div className='d-flex gap-3'>
            <MdEdit onClick={() => dispatch(handleEditProperty(row))} />
            <AiFillEye onClick={() => dispatch(handleViewProperty(row))} />
            <BsFillTrashFill onClick={() => dispatch(handleDeleteProperty(row))} />
          </div>
        </>
      ),
    },
  ];


  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: property_types.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: 'title',
      order: 'asc',
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='id'
            data={property_types}
            columns={columns}
          >
            {(props) => (
              <>
                <div className='d-flex justify-content-end'>
                  <MyExportCSV {...props.csvProps} />
                  <button className='add-btn ms-2'
                    onClick={() => dispatch(handleAddProperty())}
                  >Add a Property Type</button>
                </div>
                <BootstrapTable
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className='row'>
                  <div className='col pagination pagination-rounded justify-content-end '>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {isDeleteModalOpen && (
        <ConfirmModal
          setShow={() => dispatch(handleDeleteProperty())}
          show={isDeleteModalOpen}
          title='Delete property Type'
          bodyText='Are you sure you want to delete this property Type ?'
          icon={icon}
          color='danger'
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewPropertyThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={currentPropertyType}
        />
      )}
      {isViewPropertyModalOpen && (
        <ViewPropertyTypesModal
          setShow={() => dispatch(handleViewProperty())}
          show={isViewPropertyModalOpen}
          data={currentPropertyType}
        />
      )}
      {isEditModalOpen && (
        <EditPropertyType
          setShow={() => dispatch(handleEditProperty())}
          show={isEditModalOpen}
          data={currentPropertyType}
          title="Edit Property Type"
        />
      )}
      {isAddModalOpen && (
        <EditPropertyType
          setShow={() => dispatch(handleAddProperty())}
          show={isAddModalOpen}
          data={currentPropertyType}
          title="Add Property Type"
        />
      )}
    </Wrapper>
  );
};

export default PropertyType;
