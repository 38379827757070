import {
  db,
  collection,
  getDocs,
  auth,
  firestore,
  updateDoc,
  doc,
} from "../../utils/firebase";
export const getAllUsersThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, "users");
    const data = [];

    // add real time listener
    // onSnapshot(colRef, (querySnapshot) => {
    //  querySnapshot.forEach((doc) => {
    //  data.push(doc.data().name);
    //   });
    // });

    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });

    //remove createdAt and updatedAt because they are non-serializable
    data.forEach((user) => {
      delete user.createdAt;
      delete user.updatedAt;
    });
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const getAllUpdateThunk = async (users, thunkAPI) => {
  try {
    //  await auth()
    // .currentUser.updateProfile(users)
    // .then(() => {
    //   let id = auth().currentUser.uid;
    //   firestore().collection(users).doc(id).update(users)
    // })
    // const docRef = doc(db,'users',users.id);
    // const querySnapshot =await  updateDoc(docRef,users);
    // querySnapshot.forEach((doc) => {
    //   data.push({ ...doc.data(), id: doc.id });
    // });
    //   return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const deleteUserThunk = async (users, thunkAPI) => {
  try {
    console.log("delet", users);
    const docRef = doc(db, "users", users.id);
    const data = [];

    await updateDoc(docRef, { flag: true });
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
