import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Wrapper from '../assets/wrappers/PreviewThumbnailModal';
import logo from '../assets/images/logo.png';
const PreviewThumbnailModal = ({ show, setShow, thumbnail }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='md'
      dialogClassName='thumbnail Modal'
      centered
    >
      <Wrapper>
        <Modal.Header closeButton />
        <Modal.Body>
          <div>

            <img src={thumbnail ? thumbnail : logo} alt='thumbnail' />

          </div>
        </Modal.Body>
        <Modal.Footer className='modalFooter'>
          <Button variant='danger' className='modalbtn' onClick={handleClose}>
            Remove Thumbnail
          </Button>
          <Button
            variant='secondary'
            className='modalbtn'
            onClick={handleClose}
          >
            close
          </Button>
        </Modal.Footer>
      </Wrapper>
    </Modal>
  );
};

export default PreviewThumbnailModal;
