import styled from 'styled-components';

const Wrapper = styled.div`
  width: 200px !important;
  margin: 0 3px;
  .form-control:focus {
    border-color: #50821d;
    box-shadow: none !important;
  }
`;
export default Wrapper;
