import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading, MySearch, MyExportCSV } from "../../components";
import ConfirmModalNav from "../../modals/ConfirmModalNav";
import WidgetsModal from "../../modals/WidgetsModal";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import icon from "../../assets/images/edit-big.svg";
import ViewWidgetModal from "../../modals/ViewWidgetModal";
import PreviewThumbnailModal from "../../modals/PreviewThumbnailModal";
import logo from "../../assets/images/logo.png";
import Wrapper from "../../assets/wrappers/AllProperties";
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from "../../utils/Table";
import {
  getAllUpdate,
  deleteWidget,
  getAllWidgets,
  handleAddWidget,
  handleDelete,
  handleEditWidgets,
  handleViewWidget,
  handleViewThumbnail,
  createWidget,
} from "../../features/allWidgets/allWidgetsSlice";

const AllProperties = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    properties,
    isViewPropertyModalOpen,
    isDeleteModalOpen,
    isEditModalOpen,
    isAddModalOpen,
    isViewThumbnailModalOpen,
    currentProperty,
  } = useSelector((store) => store.allWidgets);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // setCount(1);
    }, 3000);

    dispatch(getAllWidgets());
    console.log("testing widgets");
  }, [isEditModalOpen, isAddModalOpen, isDeleteModalOpen]);

  useEffect(() => {}, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "title",
      text: "title",
    },
    {
      dataField: "description",
      text: "description",
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <MdEdit onClick={() => dispatch(handleEditWidgets(row))} />
          <AiFillEye onClick={() => dispatch(handleViewWidget(row))} />
          {/* <BsFillTrashFill onClick={() => dispatch(handleDelete(row))} /> */}
        </div>
      ),
    },
  ];

  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: properties.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (properties.length === 0) {
    return <h2>No Widgets to display...</h2>;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={properties}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="d-flex justify-content-between">
                  <h5>
                    {properties.length} WIDGET
                    {properties.length > 1 ? "S" : ""} FOUND
                  </h5>
                  <div className="d-flex">
                    <MySearch {...props.searchProps} />

                    {/* <button className='add-btn ms-2 me-2'
                      onClick={() => dispatch(handleAddWidget())}
                    >Add Widget</button> */}
                    <MyExportCSV {...props.csvProps} />
                  </div>
                </div>
                <BootstrapTable
                  key="id"
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className="row">
                  <div className="col pagination pagination-rounded justify-content-end ">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {isEditModalOpen && (
        <WidgetsModal
          setShow={() => dispatch(handleEditWidgets())}
          show={isEditModalOpen}
          data={currentProperty}
          action={getAllUpdate}
          name="edit"
          title="Edit Widgets"
        />
      )}
      {isAddModalOpen && (
        <WidgetsModal
          setShow={() => dispatch(handleAddWidget())}
          show={isAddModalOpen}
          data={currentProperty}
          action={createWidget}
          title="Add Widgets"
          name="add"
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmModalNav
          setShow={() => dispatch(handleDelete())}
          show={isDeleteModalOpen}
          title="Delete Nav"
          bodyText="Are you sure you want to delete this Nav?"
          icon={icon}
          color="danger"
          handleAction={deleteWidget}
          id={currentProperty.id}
        />
      )}

      {isViewPropertyModalOpen && (
        <ViewWidgetModal
          setShow={() => dispatch(handleViewWidget())}
          show={isViewPropertyModalOpen}
          data={{ ...currentProperty, isLoading }}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={currentProperty}
        />
      )}
    </Wrapper>
  );
};

export default AllProperties;
