import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Error, Register, ProtectedRoute } from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SharedLayout,
  AllUser,
  PropertySettings,
  AllProperties,
} from "./pages/dashboard";
import News from "./pages/dashboard/News";
import Events from "./pages/dashboard/Events";

//Anthony
import Widgets from "./pages/dashboard/Widgets";
import Nav from "./pages/dashboard/Nav";
import Pages from "./pages/dashboard/Pages";
import PageMobile from "./pages/dashboard/PageMobile";

import PropertyType from "./pages/dashboard/PropertyType";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index path="/" element={<AllUser />} />
          <Route path="settings" element={<PropertySettings />} />
          <Route path="all-properties" element={<AllProperties />} />
          <Route path="all-news" element={<News />} />
          <Route path="all-events" element={<Events />} />
          <Route path="all-widgets" element={<Widgets />} />
          <Route path="all-nav" element={<Nav />} />
          <Route path="all-pages" element={<Pages />} />
          <Route path="all-pages-mobile" element={<PageMobile />} />
          <Route path="all-property-type" element={<PropertyType />} />
        </Route>
        <Route path="register" element={<Register />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
