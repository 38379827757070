import styled from 'styled-components';

const ViewUserModal = styled.div`
  .modalHeader {
    border: none !important;
    align-items: baseline;
  }
  .profile {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .btn-close {
    margin: 0 !important;
  }
  .info {
    text-align: center;
  }
  .name {
    text-transform: uppercase;
  }
  .bio {
    padding: 1.5rem 0;
    font-style: italic;
    margin: auto;
  }
`;

export default ViewUserModal;
