import { ImHome, ImNewspaper, ImProfile } from "react-icons/im";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineDateRange } from "react-icons/md";

const links = [
  { id: 1, text: "Users", path: "/", icon: <ImProfile /> },

  { id: 2, text: "Properties", path: "all-properties", icon: <ImHome /> },

  {
    id: 3,
    text: "Property Settings",
    path: "settings",
    icon: <GiSettingsKnobs />,
  },
  {
    id: 4,
    text: " News",
    path: "all-news",
    icon: <ImNewspaper />,
  },
  {
    id: 5,
    text: " Events",
    path: "all-events",
    icon: <MdOutlineDateRange />,
  },
  {
    id: 6,
    text: " Web Widgets",
    path: "all-widgets",
    icon: <MdOutlineDateRange />,
  },
  {
    id: 7,
    text: " Web Nav",
    path: "all-nav",
    icon: <MdOutlineDateRange />,
  },
  {
    id: 8,
    text: " Web Pages",
    path: "all-pages",
    icon: <MdOutlineDateRange />,
  },
  {
    id: 9,
    text: " Web Property Type",
    path: "all-property-type",
    icon: <MdOutlineDateRange />,
  },
  {
    id: 10,
    text: " Mobile Pages",
    path: "all-pages-mobile",
    icon: <MdOutlineDateRange />,
  },
];
export default links;
