import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Wrapper from '../assets/wrappers/ViewPropertyModal';
import ImageCarousel from '../components/ImageCarousel';
import { Loading } from '../components';
import {
  BiMessageRoundedError,
  BiBed,
  BiBuilding,
  BiArea,
} from 'react-icons/bi';
import { GoLocation } from 'react-icons/go';
import { MdBalcony, MdBathtub, MdLocalDining } from 'react-icons/md';
import { GiSofa } from 'react-icons/gi';

const ViewPropertyModal = ({ show, setShow, data }) => {
  const {
    added_by,
    isLoading,
    gallery,
    rent_price,
    sale_price,
    type,
    type_subcategory,
    country,
    longitude,
    num_balcony,
    num_bathrooms,
    num_bedrooms,
    num_floors,
    num_salons,
    num_dining_rooms,
    payment_method,
    createdAt,
    checkedAmenities,
  } = data;

  const handleClose = () => {
    setShow(false);
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size='lg'
      dialogClassName='View Property Modal'
      centered
    >
      <Wrapper>
        <Modal.Header className='modalHeader' closeButton></Modal.Header>
        <Modal.Body>
          <div className='gallery'>
            {gallery.length > 0 ? (
              <ImageCarousel
                gallery={gallery}
                rent_price={rent_price}
                sale_price={sale_price}
              />
            ) : (
                <div className='text-warning'>
                  <BiMessageRoundedError /> No Images Available
                </div>
              )}
          </div>
          <h2>
            {type.label} | {type_subcategory.label}
          </h2>

          <div className='rooms'>
            <p>
              <MdBalcony /> &nbsp;
              {num_balcony} <br /> balcony
            </p>
            <p>
              <MdBathtub /> &nbsp; {num_bathrooms} <br /> bathroom
            </p>
            <p>
              <BiBed /> &nbsp;
              {num_bedrooms} <br /> bedroom
            </p>
            <p>
              <BiBuilding /> &nbsp; {num_floors} <br /> floor
            </p>
            <p>
              <GiSofa /> &nbsp; {num_salons} <br /> salon
            </p>
            <p>
              <MdLocalDining /> &nbsp; {num_dining_rooms} <br /> dining room
            </p>
            <p>
              <BiArea /> &nbsp; {longitude} <br /> MS
            </p>
          </div>

          {checkedAmenities.length > 0 && (
            <>
              <h3>Amenities</h3>
              <ul>
                {checkedAmenities.map((amenity, index) => (
                  <li key={index}>{amenity.title}</li>
                ))}
              </ul>
            </>
          )}
          <div className='paymentLocationGrid'>
            <div>
              <h3>Payment</h3>
              <p>{payment_method?.label}</p>
              {/* If no gallery prices will be displayed here */}
              <p className='muted'>
                {gallery.length == 0
                  ? `
                ${sale_price ? `Sale Price:$ ${sale_price}` : ''}
               ${rent_price ? ` Rent Price:$ ${rent_price}` : ''}
            
            `
                  : ``}
              </p>
            </div>
            <div>
              <h3>Location</h3>
              <address>
                <GoLocation />
                {country.label}
              </address>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modalFooter'>
          <p>
            Posted by:{added_by} On:{createdAt}
          </p>
        </Modal.Footer>
      </Wrapper>
    </Modal>
  );
};

export default ViewPropertyModal;
