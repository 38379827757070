import styled from 'styled-components';

const Wrapper = styled.div`
  .modalHeader {
    border: none !important;
    padding-block-end: 0 !important;
    padding-inline-start: 1.75rem !important;
  }
  .text {
    margin: auto;
    margin-inline-start: 0;
  }
  .modalFooter {
    border: none !important;
    padding-block-start: 0 !important;
  }
  .icon {
    margin: 0.75rem;
  }

  .modalbtn {
    width: 6rem;
  }
  .danger {
    color: var(--white);
    background: var(--red);
    border: transparent;
    text-transform: capitalize;
  }
  .danger:hover {
    background: var(--red-dark);
    box-shadow: var(--shadow-3);
  }
  .confirm {
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    text-transform: capitalize;
  }
  .confirm:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
  }
`;
export default Wrapper;
