import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import { Col, Row, Form, Input, FormFeedback, Label } from 'reactstrap';

import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllUpdate, createProperty, uploadFiles, deleteStorageFile, handleRemoveImg,
  handleUpload,
} from '../features/allProperties/allPropertiesSlice';
import WorldMap from '../utils/world_js';
import { AllWorldMap } from '../utils/constData';
import Select from 'react-select'
import PhotoWidget from '../components/shared/PhotoWidget';
import { Loading } from '../components';

export default function NavModal({ show, setShow, data, title, action,name }) {
  const { user } = useSelector((store) => store.user);
  const { isAddModalOpen, isEditModalOpen, propertyTypes, subcategories, uploads, uploadEditImages, deletedImages, isLoading } = useSelector((store) => store.allProperties);
  const { amenities } = useSelector((store) => store.propertySettings);
  const [propertyTypesSelected, setPropertyTypesSelected] = useState([])
  const [propertyCatSelected, setPropertyCatSelected] = useState(data.type_subcategory)
  const handleClose = () => {
    setShow(false);
  };
  const [categories, setCategories] = useState([{ label: '', value: '' }]);
  const [country, setCountry] = useState(data ? data.country : { label: '', value: '' });
  const [city, setCity] = useState(data.caza)
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [amentiesSelected, setAmentiesSelected] = useState([])
  const dispatch = useDispatch();

  const optionsType = [
    { value: 'top', label: 'Top' },
    { value: 'ourservices', label: 'our services' },
    {value:'browse', label:'browse'},
    {value:'explore', label:'explore'},
    {value:'followus', label:'followus'},
    {value:'topcategories', label:'top categories'},

  ]
  const optionslevel = [
    { value: 0, label: '0' },
    { value:1 , label: '1' },
    {value:2, label:'2'},
    {value:3, label:'3'},
  ]

  const optionsPosition = [
    { value: 0, label: '0' },
    { value:1 , label: '1' },
    {value:2, label:'2'},
    {value:3, label:'3'},
    { value: 4, label: '4' },
    { value:5 , label: '5' },
    {value:6, label:'6'},
    {value:7, label:'7'},
    { value: 8, label: '8' },
    { value:9 , label: '9' },
    {value:10, label:'10'},
    {value:11, label:'11'},
    { value: 12, label: '12' },
    { value:13 , label: '13' },
    {value:14, label:'14'},
    {value:15, label:'15'},
  ]

  const fixCategories = val => {
    let allKeys = [];
    subcategories.forEach(tp => {
      let key = Object.keys(tp)[0];
      allKeys.push(key);
    });

    allKeys.forEach((key, index) => {
      if (key === val) {
        setCategories(subcategories[index][val]);
      }
    });
  };
  if (isLoading) {
    return <Loading />;
  }


  const validation = useFormik({
    
    initialValues: {
      type: (data && data.type) || '',
      classname: (data && data.classname) || '',
      isexternal: (data && data.isexternal) || '',
      label: (data && data.label) || '',
      label_ar:(data && data.label_ar),
      level: (data && data.level) || 0,
      link: (data && data.link) || '',
      position: (data && data.position) || '',
      type: (data && data.type) || '',

    },

    validationSchema: Yup.object({
      type: Yup.string().required('The Type is required'),
      classname: Yup.string().required('The classname is required'),
      label: Yup.string().required('The label is required'),
      level: Yup.number()
        .typeError('The level must be a number')
        .required('The level Field is required'),
      link: Yup.string().required('The link is required'),
      label_ar:Yup.string().required('The link is required'),
      position: Yup.number()
        .typeError('The position must be a number')
        .required('The position field is required'),

    }),

    onSubmit: async (values) => {
      if (isAddModalOpen) {
        const imgUrls = await Promise.all(

          [...uploads].map((image, index) => {
            if (index === 0) {
              return dispatch(uploadFiles({ image: uploads[0], urlStorage: 'images/property/thumbnail/' }));
            } else
              return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        var result = imgUrls.map(({ payload }) => payload)
      }


      if (isEditModalOpen && deletedImages) {
        if (uploads.length >= 2) {
          deletedImages.map((image) => {
            dispatch(deleteStorageFile(image))
          })
          result = uploads
        }

      }

      if (isEditModalOpen && uploadEditImages) {
        const imgUrls = await Promise.all(

          [...uploadEditImages].map((image, index) => {

            return dispatch(uploadFiles({ image: image, urlStorage: 'images/property/gallery/' }));
          })
        ).catch(() => {
          console.log("error image")
          return
        })
        result = imgUrls.map(({ payload }) => payload)
        result = [...uploads, ...result]

      }
      const docId=data.id
      const nav = {
        id: data ? data.id : 0,
        classname: values.classname,
        isexternal: false,
        label: values.label,
        label_ar:values.label_ar,
        level: values.level,
        link: values.link,
        position: values.position,
        type: values.type
      };
      console.log("values", values)
      console.log("prop", nav)
      if(name==='add'){
        dispatch(action(nav));
      }
      else{
        dispatch(action(nav));
      }

      setShow(false);
    },
  });




  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>{title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row form='true'>
              <Col className='col-12'>


                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Type</Label>
                      <Select
                        options={optionsType}
                        name='type'
                        defaultValue={data.type ? data.type : ''}
                        onChange={e => {
                          validation.handleChange("type")(e.value);

                        }}
                        
                        />
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>classname</Label>
                      <Input
                        name='classname'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.classname}
                        invalid={
                          validation.touched.classname &&
                            validation.errors.classname
                            ? true
                            : false
                        }
                        />
                      {validation.touched.classname && validation.errors.classname ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.classname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>label</Label>
                      <Input
                        name='label'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.label}
                        invalid={
                          validation.touched.label &&
                          validation.errors.label
                            ? true
                            : false
                        }
                        />
                      {validation.touched.label && validation.errors.label ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.label}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>level</Label>
                      <Input
                        name='level'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.level}
                        invalid={
                          validation.touched.level &&
                            validation.errors.level
                            ? true
                            : false
                        }
                        />
                      {validation.touched.level && validation.errors.level ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.level}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form='true'>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>link</Label>
                      <Input
                        name='link'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link}
                        invalid={
                          validation.touched.link &&
                            validation.errors.link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link &&
                        validation.errors.link ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.link}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>position</Label>
                      <Input
                        name='position'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.position || ""}
                        invalid={
                          validation.touched.position &&
                            validation.errors.position
                            ? true
                            : false
                        }
                      />

                      {validation.touched.position &&
                        validation.errors.position ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.position}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col className='col-6'>
                    <div className='mb-3'>
                    <Label className='form-label'>label arabic</Label>
                      <Input
                        name='label_ar'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.label_ar}
                        invalid={
                          validation.touched.label_ar &&
                          validation.errors.label_ar
                            ? true
                            : false
                        }
                        />
                      {validation.touched.label_ar && validation.errors.label_ar ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.label_ar}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className='font d-none'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      

                    </div>
                  </Col>

                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Input
                        name='num_bathrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bathrooms || ""}
                        invalid={
                          validation.touched.num_bathrooms &&
                            validation.errors.num_bathrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.after_repair_value &&
                        validation.errors.after_repair_value ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.after_repair_value}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Label className='form-label'>Bedrooms</Label>
                      <Input
                        name='num_bedrooms'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_bedrooms || ""}
                        invalid={
                          validation.touched.num_bedrooms &&
                            validation.errors.num_bedrooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_bedrooms &&
                        validation.errors.num_bedrooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_bedrooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3 invisible'>
                    <div className='mb-3'>
                      <Label className='form-label'>Floors</Label>
                      <Input
                        name='num_floors'
                        type='number'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_floors}
                        invalid={
                          validation.touched.num_floors &&
                            validation.errors.num_floors
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_floors &&
                        validation.errors.num_floors ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_floors}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>

                </Row>

                <Row className='font d-none'>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Dining Rooms</Label>
                      <Input
                        name='num_dining_rooms'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_dining_rooms}
                        invalid={
                          validation.touched.num_dining_rooms &&
                            validation.errors.num_dining_rooms
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_dining_rooms &&
                        validation.errors.num_dining_rooms ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Living Room</Label>
                      <Input
                        name='num_living_room'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_living_room}
                        invalid={
                          validation.touched.num_living_room &&
                            validation.errors.num_living_room
                            ? true
                            : false
                        }
                      />

                      {validation.touched.num_living_room &&
                        validation.errors.num_living_room ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_dining_rooms}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                  <Col className='col-3'>
                    <div className='mb-3'>
                      <Label className='form-label'>Salons</Label>

                      <Input
                        name='num_salons'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form='true'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.num_salons}
                        invalid={
                          validation.touched.num_salons &&
                            validation.errors.num_salons
                            ? true
                            : false
                        }
                      />
                      {validation.touched.num_salons &&
                        validation.errors.num_salons ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.num_salons}
                          </FormFeedback>
                        ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/* <Col>
                    <div className='bg-blue mb-4'>
                      <label className='form-label'>Featured and Gallery Images</label>
                      <div>
                        <PhotoWidget
                          id='featured_image'
                          value={validation.values.featured_image}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          validation={validation}
                          validationValue={validation.values.featured_image}
                          handleRemoveImg={handleRemoveImg}
                          handleUpload={handleUpload}
                          uploads={uploads}
                        />
                      </div>

                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-end pb-4'>
                  <button type='submit' className='btn btn-success  btn-save'>
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
