import React from "react";
import Wrapper from "../assets/wrappers/Search";
const MySearch = (props) => {
  let input;
  return (
    <Wrapper className=" my-search">
      <div className="input-group-sm mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          aria-label="search Users"
          ref={(n) => (input = n)}
          onChange={() => props.onSearch(input.value)}
        />
      </div>
    </Wrapper>
  );
};

export default MySearch;
