import { db, collection, getDocs } from '../../utils/firebase';
export const getPropertyTypesThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'property_type');
    const data = [];
    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};

export const getAmenitiesThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'amenity');
    const AmenitiesData = [];
    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      AmenitiesData.push(doc.data());
    });

    return AmenitiesData;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
export const getPaymentMethodsThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'property_constants');
    const PaymentMethodsData = [];
    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      PaymentMethodsData.push(doc.data());
    });
    return PaymentMethodsData;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
