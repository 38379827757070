import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Wrapper from "../assets/wrappers/EditUserModal";
import profile from "../assets/images/profile.png";
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap";

//galery
import { FileUploader } from "react-drag-drop-files";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../utils/firebase";
import { uploadBytes, getDownloadURL } from "firebase/storage";

import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEvent,
  handleRemoveImg,
  handleUpload,
} from "../features/allEvents/allEventsSlice";
import {
  deleteStorageFile,
  uploadFiles,
} from "../features/allProperties/allPropertiesSlice";
import PhotoWidget from "../components/shared/PhotoWidget";
import { auth } from "../utils/firebase";
export default function EditEvents({
  setShow,
  show,
  data,
  title,
  name,
  action,
}) {
  console.log("Date", Date.now());
  //galery
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [featuredImage, setFeaturedImage] = useState(null);
  const [featuredImagePath, setFeaturedImagePath] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([]);
  const deleteThumbnail = () => {
    if (featuredImage.name) {
      setFeaturedImage(null);
      setFeaturedImagePath("");
    } else {
      deleteObject(ref(storage, featuredImagePath)).then(() => {
        setFeaturedImage(null);
        setFeaturedImagePath("");
      });
    }
  };
  function randomName() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  const uploadThumbnailHandler = (file) => {
    setFeaturedImage(file);
    const random = randomName();
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`);
  };
  const uploadThumbnailProcess = () => {
    return new Promise((resolve) => {
      if (featuredImage.name) {
        const reference = ref(storage, featuredImagePath);
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then((url) => {
            resolve({ url: url, reference: featuredImagePath });
          });
        });
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath });
      }
    });
  };
  const deleteGalleryFile = (index) => {
    if (galleryImages[index].name) {
      const fileArray = [...galleryImages];
      const pathArray = [...galleryImagesPaths];
      fileArray.splice(index, 1);
      pathArray.splice(index, 1);
      setGalleryImages(fileArray);
      setGalleryImagesPaths(pathArray);
    } else {
      deleteObject(ref(storage, galleryImagesPaths[index])).then(() => {
        const fileArray = [...galleryImages];
        const pathArray = [...galleryImagesPaths];
        fileArray.splice(index, 1);
        pathArray.splice(index, 1);
        setGalleryImages(fileArray);
        setGalleryImagesPaths(pathArray);
      });
    }
  };
  const uploadGalleryHandler = (files) => {
    let processed = 0;
    if (files.length + galleryImages.length > 5) {
      alert("You can only insert 5 images");
      return;
    }
    const oldFiles = [...galleryImages];
    const oldPaths = [...galleryImagesPaths];
    const newFiles = [...files];

    newFiles.forEach((file) => {
      oldFiles.push(file);
      const random = randomName();
      oldPaths.push(`images/property/gallery/${random}${file.name}`);
      processed++;
      if (processed === files.length) {
        setGalleryImages(oldFiles);
        setGalleryImagesPaths(oldPaths);
      }
    });
  };
  const uploadGalleryProcess = async () => {
    return new Promise((resolve) => {
      const gallery = [...galleryImages];
      let processed = 0;
      const arrayToBeUploaded = [];
      if (gallery.length === 0) {
        resolve([]);
      }
      gallery.forEach((image, index) => {
        if (image.name) {
          const reference = ref(storage, galleryImagesPaths[index]);
          uploadBytes(reference, image).then(() => {
            getDownloadURL(reference).then((url) => {
              arrayToBeUploaded.push({
                url: url,
                reference: galleryImagesPaths[index],
              });
              processed++;
              if (processed === gallery.length) {
                resolve(arrayToBeUploaded);
              }
            });
          });
        } else {
          arrayToBeUploaded.push({
            url: galleryImages[index],
            reference: galleryImagesPaths[index],
          });
          processed++;
          if (processed === gallery.length) {
            resolve(arrayToBeUploaded);
          }
        }
      });
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const {
    isAddModalOpen,
    isEditModalOpen,
    uploadEditImages,
    deletedImages,
    uploads,
  } = useSelector((store) => store.allEvents);
  const dispatch = useDispatch();
  const validation = useFormik({
    // enableReinitialize: true,

    initialValues: {
      thumbnail: (data && data.thumbnail.url) || "",
      name: (data && data.name) || "",
      address: (data && data.address) || "",
      city: (data && data.city) || "",
      country: (data && data.country.value) || "",
      topic: (data && data.topic) || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("The Tile  of Event  is required"),
      address: Yup.string().required("The Address is required"),
      city: Yup.string().required("The City  is required"),
      country: Yup.string().required("The country is required"),
      topic: Yup.string().required("The country is required"),
    }),

    onSubmit: async (values) => {
      if (isAddModalOpen) {
        const imgUrls = await Promise.all(
          [...uploads].map((image, index) => {
            return dispatch(
              uploadFiles({
                image: uploads[0],
                urlStorage: "images/event/thumbnail/",
              })
            );
          })
        ).catch(() => {
          console.log("error image");
          return;
        });
        var result = imgUrls.map(({ payload }) => payload);
      }

      if (isEditModalOpen && deletedImages) {
        if (uploads.length >= 2) {
          deletedImages.map((image) => {
            dispatch(deleteStorageFile(image));
          });
          result = uploads;
        }
      }

      if (isEditModalOpen && uploadEditImages) {
        const imgUrls = await Promise.all(
          [...uploadEditImages].map((image, index) => {
            return dispatch(
              uploadFiles({
                image: image,
                urlStorage: "images/event/thumbnail/",
              })
            );
          })
        ).catch(() => {
          console.log("error image");
          return;
        });
        result = imgUrls.map(({ payload }) => payload);
        result = [...uploads, ...result];
      }
      let thumbnailUpload, galleryUpload;
      uploadThumbnailProcess().then((value) => {
        thumbnailUpload = value;
        console.log("data", data);
        const event = {
          id: 0,
          added_on: new Date(Date.now()),
          name: values.name,
          address: values.address,
          city: values.city,
          country: values.country,
          date: new Date(Date.now()),
          thumbnail: thumbnailUpload,
          added_by: "gf7tijqaSuUMouOcsauquuTSuo02",
          topic: values.topic,
        };
        console.log("event add and edit", event);

        if (name === "add") {
          dispatch(action(event));
        } else {
          dispatch(action(event));
        }
      });
      setShow(false);
    },
  });

  useEffect(() => {}, [show]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Wrapper>
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title>Edit News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row form="true">
              <Col className="col-12">
                <div className="profile">
                  {/* <PhotoWidget
                    id='featured_image'
                    value={validation.values.thumbnail}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    validation={validation}
                    validationValue={validation.values.thumbnail}
                    handleRemoveImg={handleRemoveImg}
                    handleUpload={handleUpload}
                    uploads={uploads}
                  /> */}
                </div>
                <Row form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">Event Name</Label>
                      <Input
                        name="name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />

                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label"> address</Label>
                      <Input
                        name="address"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address &&
                      validation.errors.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.topic}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form="true">
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">country</Label>
                      <Input
                        name="country"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.country}
                        invalid={
                          validation.touched.country &&
                          validation.errors.country
                            ? true
                            : false
                        }
                      />
                      {validation.touched.topic_title &&
                      validation.errors.topic_title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.topic_title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">city</Label>
                      <Input
                        name="city"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city}
                        invalid={
                          validation.touched.city && validation.errors.city
                            ? true
                            : false
                        }
                      />
                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid">
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">topic</Label>
                      <Input
                        name="topic"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.topic}
                        invalid={
                          validation.touched.topic && validation.errors.topic
                            ? true
                            : false
                        }
                      />
                      {validation.touched.topic && validation.errors.topic ? (
                        <FormFeedback type="invalid">
                          {validation.errors.topic}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Row>
                <Col col="12">
                <div className='mb-3'>
                      <Label className='form-label'>overview</Label>
                      <textarea
                       
                        name='overview'
                        type='text'
                      
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.overview}
                    
                      />
                      {validation.touched.overview && validation.errors.overview ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.overview}
                        </FormFeedback>
                      ) : null}
                    </div>
                </Col>
              </Row> */}
            </Row>
            <Row>
              <Col>
                <div className="bg-blue mb-4">
                  <label className="form-label">
                    Featured and Gallery Images
                  </label>
                  <div>
                    <FileUploader
                      classes="dropzone"
                      handleChange={uploadThumbnailHandler}
                      name="file"
                      types={fileTypes}
                    />

                    {featuredImage && (
                      <div
                        className="thumbnail-container"
                        onClick={deleteThumbnail}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={
                            featuredImage.name
                              ? URL.createObjectURL(featuredImage)
                              : featuredImage
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end pb-4">
                  <button type="submit" className="btn btn-success  btn-save">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
