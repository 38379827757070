import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllPropertyTypeThunk, getAllUpdateThunk, addPropertyTypeThunk, getCatPropertyThunk, getTypePropertyThunk, deletePropertyTypeThunk, uploadFilesThunk, RemoveFileThunk, removeFromArrayThunk } from './allPropertyTypeThunk';
// import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  isViewPropertyModalOpen: false,
  isViewThumbnailModalOpen: false,
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  properties: [],
  propertyTypes: [],
  subcategories: [],
  type: [],
  cat: [],
  uploads: [],
  deletedImages: [],
  uploadEditImages: [],
  currentProperty: null,
  reload: "",
};
export const getAllPropertyType = createAsyncThunk(
  'allProperties/getProperties',
  getAllPropertyTypeThunk
);
// export const getTypeProperty = createAsyncThunk(
//   'allProperties/getTypeProperty',
//   getTypePropertyThunk
// );
// export const getCatProperty = createAsyncThunk(
//   'allProperties/getCatProperty',
//   getCatPropertyThunk
// );
export const getAllUpdate = createAsyncThunk(
  'allProperties/getAllUpdate',
  getAllUpdateThunk
);
export const createPropertyType = createAsyncThunk(
  'allProperties/createProperty',
  addPropertyTypeThunk
);
export const uploadFiles = createAsyncThunk(
  'allProperties/uploadFiles',
  uploadFilesThunk
);
export const deletePropertyType = createAsyncThunk(
  'allProperties/deleteProperty',
  deletePropertyTypeThunk
);
// export const deleteStorageFile = createAsyncThunk(
//   'allProperties/deleteStorageFile',
//   RemoveFileThunk
// );

// export const removeFromArray = createAsyncThunk(
//   'allProperties/removeFromArray',
//   removeFromArrayThunk
// );

const allPropertyTypeSlice = createSlice({
  name: 'allProperties',
  initialState,
  reducers: {
    handleAddPropertyType: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentProperty = '';
    },
    handleViewPropertyType: (state, { payload }) => {
      state.isViewPropertyModalOpen = !state.isViewPropertyModalOpen;

      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleEdit: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;

      if (payload) {
        state.EditNewsClicked = payload
      }
    },
    handleEditPropertyType: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      console.log('test teste',state.isEditModalOpen);
      if (payload) {
        state.currentProperty = payload;
        // state.uploads = [payload.featured_image, ...payload.gallery]
      }
    },
    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleUpload: (state, { payload }) => {
      if (payload) {
        if (state.isAddModalOpen) {
          state.uploads = payload;

        }

        if (state.isEditModalOpen) {

          state.uploadEditImages = payload;

        }
      }


    },
    handleRemoveImg: (state, { payload }) => {
      if (payload) {

        const index = state.uploads.findIndex(object => {
          return object.url === payload.url;
        });

        state.uploads.splice(index, 1);


        state.deletedImages = [...state.deletedImages, payload]

      }

    },
  },

  extraReducers: {
    [getAllPropertyType.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPropertyType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.properties = payload;
    },
    [getAllPropertyType.rejected]: (state, { payload }) => {
      state.isLoading = false;

    },
    // [getTypeProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getTypeProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.propertyTypes = payload;
    // },
    // [getTypeProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    // [getCatProperty.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getCatProperty.fulfilled]: (state, { payload }) => {

    //   state.isLoading = false;
    //   state.subcategories = payload;
    // },
    // [getCatProperty.rejected]: (state, { payload }) => {
    //   state.isLoading = false;

    // },
    [getAllUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
      state.uploads = []
      state.deletedImages = [];
      state.uploadEditImages = [];
    },
    [getAllUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createPropertyType.pending]: (state) => {
      state.isLoading = true;
    },
    [createPropertyType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
    },
    [createPropertyType.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [uploadFiles.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadFiles.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
    },
    [uploadFiles.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deletePropertyType.pending]: (state) => {
      state.isLoading = true;
    },
    [deletePropertyType.fulfilled]: (state, { payload }) => {

      state.isLoading = false;
    },
    [deletePropertyType.rejected]: (state, { payload }) => {

      state.isLoading = false;
    },
    // [deleteStorageFile.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [deleteStorageFile.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [deleteStorageFile.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [removeFromArray.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
    // [removeFromArray.rejected]: (state, { payload }) => {
    //   state.isLoading = false;
    // },
  },
});

export const { clearFilters, handleChange, handleEditPropertyType, handleViewPropertyType, handleViewThumbnail, handleDelete, handleUpload, handleAddPropertyType, handleRemoveImg } = allPropertyTypeSlice.actions;
export default allPropertyTypeSlice.reducer;