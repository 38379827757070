import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllNewsThunk,
  Usersupdate,
  getAllUpdateThunk,
  addNavThunk,
  deleteNavThunk,
} from "./allNewsThunk";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  news: [],
  isViewUserModalOpen: false,
  isViewThumbnailModalOpen: false,
  isViewPropertyModalOpen: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  currentNews: null,
  EditNewsClicked: [],
  deletenews: [],
  Thumbnail: null,
};
export const getAllNews = createAsyncThunk("allNews/getNews", getAllNewsThunk);
export const getAllUpdate = createAsyncThunk(
  "allNews/getAllNews",
  getAllUpdateThunk
);
export const createNav = createAsyncThunk(
  "allProperties/createProperty",
  addNavThunk
);
export const deleteNav = createAsyncThunk(
  "allProperties/deleteProperty",
  deleteNavThunk
);
const allNewsSlice = createSlice({
  name: "allNews",
  initialState,
  reducers: {
    handleAddNav: (state, { payload }) => {
      state.isAddModalOpen = !state.isAddModalOpen;
      state.currentProperty = "";
    },
    handleView: (state, { payload }) => {
      state.isViewUserModalOpen = !state.isViewUserModalOpen;

      if (payload) {
        state.currentUser = payload;
      }
    },
    handleViewNav: (state, { payload }) => {
      state.isViewPropertyModalOpen = !state.isViewPropertyModalOpen;

      if (payload) {
        state.currentProperty = payload;
      }
    },
    handleViewThumbnail: (state, { payload }) => {
      state.isViewThumbnailModalOpen = !state.isViewThumbnailModalOpen;
      if (payload) {
        state.Thumbnail = payload;
      }
    },
    handleEditNews: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;
      console.log("test teste test", state.isEditModalOpen);
      if (payload) {
        state.currentProperty = payload;
        // state.uploads = [payload.featured_image, ...payload.gallery]
      }
    },
    handleEdit: (state, { payload }) => {
      state.isEditModalOpen = !state.isEditModalOpen;

      if (payload) {
        state.EditNewsClicked = payload;
      }
    },
    handleDelete: (state, { payload }) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
      if (payload) {
        state.currentProperty = payload;
      }
    },
  },
  extraReducers: {
    [getAllNews.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllNews.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.news = payload;
    },
    [getAllNews.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },

    [getAllUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.reload = payload;
    },
    [getAllUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [createNav.pending]: (state) => {
      state.isLoading = true;
    },
    [createNav.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // state.reload = payload;
    },
    [createNav.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deleteNav.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteNav.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deleteNav.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const {
  handleViewNav,
  handleAddNav,
  clearFilters,
  handleChange,
  hideLoading,
  handleView,
  handleViewThumbnail,
  handleEditNews,
  handleDelete,
} = allNewsSlice.actions;
export default allNewsSlice.reducer;
