import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Wrapper from '../assets/wrappers/EditUserModal';
import profile from '../assets/images/profile.png';
import { Col, Row, Form, Input, FormFeedback, Label, CardText } from 'reactstrap';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { getAllUpdate} from '../features/allUsers/allUsersSlice';

export default function ModalEdit({ show, setShow, data }) {
  const handleClose = () => {
    setShow(false);
  };

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: (data && data.first_name) || '',
      last_name: (data && data.last_name) || '',
      phone_number: (data && data.phone_number) || '',
      email: (data && data.email) || '',
      bio: (data && data.bio) || '',
    },

    validationSchema: Yup.object({
      first_name: Yup.string().required('The First Name is required'),
      last_name: Yup.string().required('The Last Name is required'),
      phone_number: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
  
      .required('A phone number is required'),
      email: Yup.string().email('email invalid').required('The Email is required'),
      bio: Yup.string().required('The Bio is required'),
    }),

    onSubmit: (values) => {
      const Users = {
         id: data ? data.id : 0,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        bio:values.bio,
      };
    
      console.log('Users of ',Users );
      dispatch(getAllUpdate(Users));
      setShow(false)
    },
  });
  useEffect(() => {
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Wrapper>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return true
            }}
            
          >
            <Row   form="true">
              <Col className='col-12'>
                <div className='profile mb-4'>
                <img src={profile} alt='user-profile' className='profile' />
                </div>
                <Row   form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>first Name</Label>
                      <Input
                        name='first_name'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        form="true"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name}
                        invalid={
                          validation.touched.first_name &&
                          validation.errors.first_name
                            ? true
                            : false
                        }
                      />
                  
                      {
                      validation.touched.first_name &&
                      validation.errors.first_name ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.first_name}
                        </FormFeedback>
                      ) : null
                      
                      }
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Last Name</Label>
                      <Input
                        name='last_name'
                        type='text'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name}
                        invalid={
                          validation.touched.last_name &&
                          validation.errors.last_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.last_name &&
                      validation.errors.last_name ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.last_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row form="true">
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Phone Number</Label>
                      <Input
                        name='phone_number'
                        type='tel'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone_number}
                        invalid={
                          validation.touched.phone_number &&
                          validation.errors.phone_number
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phone_number &&
                      validation.errors.phone_number ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.phone_number}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className='col-6'>
                    <div className='mb-3'>
                      <Label className='form-label'>Email</Label>
                      <Input
                        name='email'
                        type='email'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type='invalid'>
                          {' '}
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    </Col>
                </Row>
                <Row form="true">
                <Col className='col-12'>
                    <div className='mb-3'>
                      <Label className='form-label'>Email</Label>
                      <textarea
                      name="bio"
                    className='form-control text-area-user'
                    id='exampleFormControlTextarea1'
                    onChange={validation.handleChange}
                    rows='3'
               
                    // onBlur={validation.handleBlur}
                    value={validation.values.bio}
                   
                  />
                  {validation.errors.bio ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.bio}
                    </FormFeedback>
                  ) : null}
                  </div>
                      </Col>
                </Row>

          
                
              </Col>
            </Row>
            <Row>
                                        <Col>
                                          <div className="text-end pb-4">
                                            <button
                                              type="submit"
                                              className="btn btn-success  btn-save"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
          </Form>
        </Modal.Body>
     
      </Wrapper>
    </Modal>
  );
}
