import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, MyExportCSV } from '.';
import { getPaymentMethods, handleDeleteRent } from '../features/PropertySettings/PropertySettingsSlice';
import Wrapper from '../assets/wrappers/Amenities';
import ConfirmModal from '../modals/ConfirmModal';
import icon from '../assets/images/edit-big.svg';
import { MdEdit } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from '../utils/Table';

const RentSale = () => {
  const dispatch = useDispatch();
  const { isLoading, rent_or_sale, isDeleteRentModalOpen, isEditRentModalOpen, isViewRentModalOpen } = useSelector(
    (store) => store.propertySettings
  );
  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  const columns = [
    {
      dataField: 'label',
      text: 'Type Of Payment',
    },

    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      formatter: (cellContent, row) => (
        <>
          <div className='d-flex gap-3'>
            <MdEdit />
            <AiFillEye />
            <BsFillTrashFill onClick={() => dispatch(handleDeleteRent(row))} />
          </div>
        </>
      ),
    },
  ];

  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: rent_or_sale.length,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField='id' data={rent_or_sale} columns={columns}>
            {(props) => (
              <>
                <div className='d-flex justify-content-end'>
                  <MyExportCSV {...props.csvProps} />
                  <button className='add-btn ms-2'>Add an Amenity</button>
                </div>
                <BootstrapTable
                  bordered={false}
                  hover
                  responsive
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className='row'>
                  <div className='col pagination pagination-rounded justify-content-end '>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {isDeleteRentModalOpen && (
        <ConfirmModal
          setShow={() => dispatch(handleDeleteRent())}
          show={isDeleteRentModalOpen}
          title='Delete Option'
          bodyText='Are you sure you want to delete this option ?'
          icon={icon}
          color='danger'
        />
      )}
    </Wrapper>
  );
};

export default RentSale;
