import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../utils/firebase";
import { uploadBytes, getDownloadURL } from "firebase/storage";

export const InputRowPagesMobile = ({
  index,
  item,
  handleChange,
  handleRemove,
  handleAdd,
  values,
  inputFields,
}) => {
  const [v, setV] = useState("");
  //image

  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
  const [featuredImage, setFeaturedImage] = useState(null);
  const [featuredImagePath, setFeaturedImagePath] = useState(null);

  const [random, setRandom] = useState(null);

  const [image, setImage] = useState(item.image);
  const uploadThumbnailProcess = () => {
    return new Promise((resolve) => {
      if (featuredImage && featuredImage.name) {
        const reference = ref(storage, featuredImagePath);
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then((url) => {
            resolve({ url: url, reference: featuredImagePath });

            setImage(url);
            console.log("featuredImage2", featuredImage);
          });
        });
      } else {
        setImage(featuredImage);
        console.log("featuredImage", featuredImage);
        resolve({ url: featuredImage, reference: featuredImagePath });
      }
    });
  };
  const uploadThumbnailHandler = (file) => {
    function randomName() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }
    setFeaturedImage(file);
    setRandom(randomName());
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`);
  };

  useEffect(() => {
    if (item.text && item.text !== "") {
      setV("text");
    }
    if (item.title && item.title_ar !== "") {
      setV("title");
    }
    if (item.image && item.image !== "") {
      setV("image");
    }
  }, [item]);
  useEffect(() => {
    if (image != null) {
      console.log("imageeeeeee");
      handleChange(image, index, "image");
    }

    console.log("Image", image);
  }, [image]);

  useEffect(() => {
    if (featuredImage != null) {
      console.log("featuredImage");
      uploadThumbnailProcess();
    }
  }, [featuredImage]);
  useEffect(() => {
    console.log("v", v);
  }, [v]);

  return (
    <div
      spacing={2}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: "35px",
      }}
    >
      <select
        name="cars"
        id="cars"
        onChange={(e) => {
          e.preventDefault();
          console.log("e", e.target.value);
          setV(e.target.value);
        }}
        defaultValue={v}
      >
        <option value="">choose a widget</option>
        <option value="text">text</option>
        <option value="title">title</option>
        <option value="image">image</option>
      </select>
      {v === "text" ? (
        <>
          <label>text</label>
          <textarea
            name="text"
            required
            fullWidth
            label="text"
            onChange={(event) => {
              setV("text");
              handleChange(event, index);
            }}
            value={item.text}
          />
          <label>text_ar</label>
          <textarea
            name="text_ar"
            required
            fullWidth
            label="text_ar"
            onChange={(event) => handleChange(event, index)}
            value={item.text_ar}
          />
        </>
      ) : (
        <></>
      )}

      {v === "title" ? (
        <>
          <label>title</label>
          <input
            name="title"
            required
            fullWidth
            label="title"
            onChange={(event) => handleChange(event, index)}
            value={item.title}
          />
          <label>title_ar</label>
          <input
            name="title_ar"
            required
            fullWidth
            label="title_ar"
            onChange={(event) => handleChange(event, index)}
            value={item.title_ar}
          />
        </>
      ) : (
        <></>
      )}

      {v === "image" ? (
        <>
          <label>image</label>
          <FileUploader
            classes="dropzone"
            handleChange={uploadThumbnailHandler}
            name="image"
            onChange={(event) => handleChange(event, index)}
            types={fileTypes}
            value={image}
          />
          <input
            name="image"
            required
            fullWidth
            className="d-none"
            label="text"
            type="text"
            onChange={(event) => handleChange(event, index)}
            value={image}
          />
        </>
      ) : (
        <></>
      )}

      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleRemove(index);
          }}
          style={{
            color: "white",
            backgroundColor: "red",
            border: "none",
            borderRadius: "7px",
            marginRight: "4px",
          }}
        >
          remove
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleAdd();
          }}
          style={{
            color: "white",
            backgroundColor: "green",
            border: "none",
            borderRadius: "7px",
            marginRight: "4px",
          }}
        >
          add
        </button>
      </div>
    </div>
  );
};
