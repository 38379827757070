import React from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className='btn btn-success btn-sm' onClick={handleClick}>
        Get Excel file
        <RiFileExcel2Line className='ms-2' />
      </button>
    </div>
  );
};

export default MyExportCSV;
