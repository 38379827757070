import {
  db, collection, getDocs, onSnapshot, updateDoc, arrayUnion, arrayRemove, doc, addDoc, deleteDoc, deleteObject, ref, storage, uploadBytesResumable
  , getDownloadURL
} from '../../utils/firebase';
export const getAllEventsThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'event');
    const data = [];
    // add real time listener
    // onSnapshot(colRef, (querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     data.push(doc.data().name);
    //   });
    // });
    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};

export const updateEventThunk = async (users, thunkAPI) => {
  try {
    const docRef = doc(db, 'event', users.id);
    const data = [];

    const querySnapshot = await updateDoc(docRef, users);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });


    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};

export const addEventThunk = async (event, thunkAPI) => {
  try {
    const colRef = collection(db, 'event');

    await addDoc(colRef, event)
      .then(() => {
        console.log("success")
      })
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};


export const deleteEventThunk = async ({ docId }, thunkAPI) => {
  try {

    const docRef = doc(db, 'event', docId);
    deleteDoc(docRef)
      .then(() => {
        // Delete the file
        
          // File deleted successfully
          console.log('file deleted successfully');
        

      })



  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
};
