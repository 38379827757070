import styled from 'styled-components';

const Wrapper = styled.div`
  .thumbnail-container {
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    width: 50px;
    height: 50px;
    overflow: hidden;
  }
  .thumbnail {
    width: 50px;
    height: 50px;
  }

  .add-btn {
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    text-transform: capitalize;
    border-radius: 0.2rem;
  }
  .add-btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
  }
  .page-item.active .page-link {
    color: #fff;
    background-color: var(--primary-500);
    border-color: var(--primary-500);
  }

  .page-item.active .page-link:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
  }
`;

export default Wrapper;
