import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading, MySearch, MyExportCSV } from "../../components";
import ConfirmModalNav from "../../modals/ConfirmModalNav";
import EditNews from "../../modals/EditNews";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import icon from "../../assets/images/edit-big.svg";
import ViewPropertyModal from "../../modals/ViewPropertyModal";

import PreviewThumbnailModal from "../../modals/PreviewThumbnailModal";
import logo from "../../assets/images/logo.png";
import Wrapper from "../../assets/wrappers/AllProperties";
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from "../../utils/Table";
import Faceboook from "../../assets/images/facebook-share.svg";
import Twitter from "../../assets/images/twitter-share.svg";
import Linkedin from "../../assets/images/linkedin-share.svg";
import Whatsapp from "../../assets/images/whatsapp-icon.svg";
import telegram from "../../assets/images/telegram_logo_circle_icon.svg";
import email from "../../assets/images/email-envelop.svg";
import {
  handleViewNav,
  deleteNav,
  getAllUpdate,
  createNav,
  handleAddNav,
  getAllNews,
  handleView,
  handleEditNews,
  handleDelete,
  handleViewThumbnail,
} from "../../features/allNews/allNewsSlice";
import ViewNewsModal from "../../modals/ViewNewsModal";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
const News = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    news,
    isViewModalOpen,
    isDeleteModalOpen,
    isAddModalOpen,
    isEditModalOpen,
    isViewThumbnailModalOpen,
    isViewPropertyModalOpen,
    currentProperty,
    currentUser,
    EditNewsClicked,
    deleteuser,
    Thumbnail,
  } = useSelector((store) => store.allNews);

  const [ViewProperty, setViewProperty] = useState(false);

  useEffect(() => {
    dispatch(getAllNews(news));
  }, [EditNewsClicked]);

  const columns = [
    {
      dataField: "featured_image",
      text: " ",
      formatter: (cellContent, event) => (
        <div className="thumbnail-container">
          <img
            className="thumbnail"
            src={cellContent || logo}
            onClick={() => dispatch(handleViewThumbnail(cellContent))}
            alt="home"
          />
          {isAddModalOpen && (
            <EditNews
              setShow={() => dispatch(handleAddNav())}
              name="add"
              show={isAddModalOpen}
              data={currentProperty}
              title="Add News"
              action={createNav}
            />
          )}
        </div>
      ),
    },

    {
      dataField: "added_by",
      text: "Added By",
    },
    {
      dataField: "title",
      text: "title",
    },
    {
      dataField: "topic",
      text: "Topic",
    },
    {
      dataField: "topic_title",
      text: "Topic Title",
    },
    {
      dataField: "share",
      isDummyField: true,
      text: "share",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <FacebookShareButton
              url={"news-detail/" + row.id}
              title="Facebook"
              hashtag="#property"
            >
              <img
                src={Faceboook}
                alt="facebook icon"
                style={{ width: "20px" }}
              />
            </FacebookShareButton>
            <TwitterShareButton url={"news-detail/" + row.id} title="Twitter">
              <img src={Twitter} alt="twitter icon" style={{ width: "20px" }} />
            </TwitterShareButton>
            <LinkedinShareButton url={"news-detail/" + row.id} title="Linkedin">
              <img
                src={Linkedin}
                alt="linkedin icon"
                style={{ width: "20px" }}
              />
            </LinkedinShareButton>
            <WhatsappShareButton url={"news-detail/" + row.id} title="Whatsapp">
              <img
                src={Whatsapp}
                alt="whatsapp icon"
                style={{ width: "20px" }}
              />
            </WhatsappShareButton>
            <TelegramShareButton url={"news-detail/" + row.id} title="telegram">
              <img
                src={telegram}
                alt="whatsapp icon"
                style={{ width: "20px" }}
              />
            </TelegramShareButton>
            <EmailShareButton
              url={"news-detail/" + row.id}
              subject="share page"
              body={"this is link shared"}
            >
              <img src={email} alt="whatsapp icon" style={{ width: "20px" }} />
            </EmailShareButton>
          </div>
        );
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          {row.title !== "test" ? (
            <>
              <MdEdit
                onClick={() => {
                  dispatch(handleEditNews(row));
                }}
              />
              <AiFillEye onClick={() => dispatch(handleViewNav(row))} />
              <BsFillTrashFill onClick={() => dispatch(handleDelete(row))} />
            </>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
  ];
  // const HandleViewPropertyModal = (row) => {
  //   setProperty({ ...row, isLoading });
  //   setViewProperty(true);
  // };
  // const handlePreviewThumbenail = (cellContent) => {
  //   setThumbnail(cellContent);
  //   setPreviewThumbnail(true);
  // };

  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: news.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: "added_by",
      order: "asc",
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (news.length === 0) {
    return (
      <div>
        <button
          className="add-btn ms-2 me-2"
          onClick={() => dispatch(handleAddNav())}
        >
          Add News
        </button>
        <h2>No news to display...</h2>
      </div>
    );
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" data={news} columns={columns} search>
            {(props) => (
              <>
                <div className="d-flex justify-content-between">
                  <h5>
                    {news.length} NEW
                    {news.length > 1 ? "S" : ""} FOUND
                  </h5>
                  <div className="d-flex">
                    <button
                      className="add-btn ms-2 me-2"
                      onClick={() => dispatch(handleAddNav())}
                    >
                      Add News
                    </button>
                    <MyExportCSV {...props.csvProps} />
                    <MySearch {...props.searchProps} />
                  </div>
                </div>
                <BootstrapTable
                  key="id"
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className="row">
                  <div className="col pagination pagination-rounded justify-content-end ">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {isEditModalOpen && (
        <EditNews
          setShow={() => dispatch(handleEditNews())}
          show={isEditModalOpen}
          name="edit"
          data={currentProperty}
          title="edit News"
          action={getAllUpdate}
        />
      )}
      {isAddModalOpen && (
        <EditNews
          setShow={() => dispatch(handleAddNav())}
          name="add"
          show={isAddModalOpen}
          data={currentProperty}
          title="Add News"
          action={createNav}
        />
      )}

      {isDeleteModalOpen && (
        <ConfirmModalNav
          setShow={() => dispatch(handleDelete())}
          show={isDeleteModalOpen}
          title="Delete Nav"
          bodyText="Are you sure you want to delete this Nav?"
          icon={icon}
          color="danger"
          handleAction={deleteNav}
          id={currentProperty.id}
        />
      )}

      {isViewPropertyModalOpen && (
        <ViewNewsModal
          setShow={() => dispatch(handleViewNav())}
          show={isViewPropertyModalOpen}
          data={currentProperty}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={{ Thumbnail, isLoading }}
        />
      )}
    </Wrapper>
  );
};

export default News;
