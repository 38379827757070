import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, MySearch, MyExportCSV } from '../../components';
import ConfirmModalNav from '../../modals/ConfirmModalNav';
import NavModal from '../../modals/NavModal';
import { MdEdit } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import icon from '../../assets/images/edit-big.svg';
import ViewNavModal from '../../modals/ViewNavModal';
import PreviewThumbnailModal from '../../modals/PreviewThumbnailModal';
import logo from '../../assets/images/logo.png';
import EditNav from '../../modals/EditNav'
import Wrapper from '../../assets/wrappers/AllProperties';
import {
  BootstrapTable,
  paginationFactory,
  ToolkitProvider,
  PaginationProvider,
  PaginationListStandalone,
} from '../../utils/Table';
import { createNav,deleteNav, getAllNav, handleAddNav, handleDelete, handleEditNav, handleViewNav, handleViewThumbnail,getAllUpdate } from '../../features/allNav/allNavSlice';

const AllProperties = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    properties,
    isViewPropertyModalOpen,
    isDeleteModalOpen,
    isEditModalOpen,
    isAddModalOpen,
    isViewThumbnailModalOpen,
    currentProperty } = useSelector((store) => store.allNav);


  useEffect(() => {
    const timeout = setTimeout(() => {
      // setCount(1);
    }, 3000);

    dispatch(getAllNav());
    console.log('testing widgets',)
  }, [isEditModalOpen, isAddModalOpen, isDeleteModalOpen]);

  useEffect(() => {

  }, []);

  const columns = [

    {
      dataField: 'type',
      text: 'Type',
    },
    {
        dataField: 'label',
        text: 'Label',
      },
    {
      dataField: 'level',
      text: 'Level',
    },
    {
        dataField: 'position',
        text: 'Position',
      },    
      {
        dataField: 'link',
        text: 'Link',
      },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      formatter: (cellContent, row) => (
        <div className='d-flex gap-3'>
          <MdEdit onClick={() => dispatch(handleEditNav(row))} />
          <AiFillEye onClick={() => dispatch(handleViewNav(row))} />
          <BsFillTrashFill onClick={() => dispatch(handleDelete(row))} />
        </div>
      ),
    },
  ];


  //pagination options
  const options = {
    sizePerPage: 10,
    custom: true,
    totalSize: properties.length,
  };
  // sorting option
  const defaultSorted = [
    {
      dataField: 'type',
      order: 'asc',
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (properties.length === 0) {
    return <h2>No Widgets to display...</h2>;
  }

  return (
    <Wrapper>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='id'
            data={properties}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className='d-flex justify-content-between'>
                  <h5>
                    {properties.length} NAV
                    {properties.length > 1 ? 'S' : ''} FOUND
                  </h5>
                  <div className='d-flex'>
                    <MySearch {...props.searchProps} />

                    <button className='add-btn ms-2 me-2'
                      onClick={() => dispatch(handleAddNav())}
                    >Add Nav</button>
                    <MyExportCSV {...props.csvProps} />
                  </div>
                </div>
                <BootstrapTable
                  key='id'
                  bordered={false}
                  hover
                  responsive
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <div className='row'>
                  <div className='col pagination pagination-rounded justify-content-end '>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {isEditModalOpen && (
        <NavModal
          setShow={() => dispatch(handleEditNav())}
          show={isEditModalOpen}
          data={currentProperty}
          title="Edit Property"
          action={getAllUpdate}
          name='delete'
        />
      )}
      {isAddModalOpen && (
        <NavModal
          setShow={() => dispatch(handleAddNav())}
          name='add'
          show={isAddModalOpen}
          data={currentProperty}
          title="Add Nav"
          action={createNav}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmModalNav
          setShow={() => dispatch(handleDelete())}
          show={isDeleteModalOpen}
          title='Delete Nav'
          bodyText='Are you sure you want to delete this Nav?'
          icon={icon}
          color='danger'
          handleAction={deleteNav}
          id={currentProperty.id}


        />
      )}

      {isViewPropertyModalOpen && (
        <ViewNavModal
          setShow={() => dispatch(handleViewNav())}
          show={isViewPropertyModalOpen}
          data={ currentProperty}
        />
      )}
      {isViewThumbnailModalOpen && (
        <PreviewThumbnailModal
          setShow={() => dispatch(handleViewThumbnail())}
          show={isViewThumbnailModalOpen}
          thumbnail={currentProperty}
        />
      )}
    </Wrapper>
  );
};

export default AllProperties;