
import React from 'react'
export const InputRow = ({
  index,
  item,
  handleChange,
  handleRemove,
  handleAdd,
  values,
  inputFields
}) => {
  return (
    <div spacing={2} style={{ alignItems: "center", display: "flex", justifyContent: "space-evenly",    marginBottom: "35px"}}>
      <label>label</label>
      <input
        name="label"
        required
        fullWidth
        label="label"
        onChange={(event) => handleChange(event, index)}
        value={item.label}
      />
      <label>value</label>
      <input
        name="value"
        required
        fullWidth
        label="value"
        onChange={(event) => handleChange(event, index)}
        value={item.value}
      />

      <div>
        <button onClick={handleRemove} style={{    color: "white",backgroundColor: "red",border: "none",borderRadius: "7px",marginRight: "4px"}}>
          remove
        </button>
        <button onClick={handleAdd} style={{    color: "white",backgroundColor: "green",border: "none",borderRadius: "7px",marginRight: "4px"}}>
          add
        </button>
      </div>
    </div>
  )
}
